import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../store";
import * as UserStore from "../store/user";
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import "./Navigation.scss";
import { AccountType } from "../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { Badge } from 'primereact/badge';

type NavigationProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

function Navigation(props: NavigationProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentType, setCurrentType] = useState("0");
  const [currentCounty, setCurrentCounty] = useState("0");
  const [currentUsername, setCurrentUsername] = useState("");
  const [currentUserId, setCurrentUserId] = useState(0);
  const [unassignedParcelCount, setUnassignedParcelCount] = useState(0);

  useEffect(() => {
    console.log("component mounted!");

    if (localStorage.getItem("token")) {
      setCurrentUsername((jwt_decode(localStorage.getItem("token") as string) as any).unique_name);
      setCurrentType((jwt_decode(localStorage.getItem("token") as string) as any).AccountType);
      setCurrentUserId((jwt_decode(localStorage.getItem("token") as string) as any).UserId);
      setCurrentCounty((jwt_decode(localStorage.getItem("token") as string) as any).CountyId);
      props.getUnassignedLegalParcels((jwt_decode(localStorage.getItem("token") as string) as any).CountyId as unknown as number);
    }

  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const tokenUserId = (jwt_decode(localStorage.getItem("token") as string) as any).UserId;
      setCurrentUsername((jwt_decode(localStorage.getItem("token") as string) as any).unique_name);
      setCurrentType((jwt_decode(localStorage.getItem("token") as string) as any).AccountType);
      setCurrentCounty((jwt_decode(localStorage.getItem("token") as string) as any).CountyId);
      setCurrentUserId(tokenUserId);
      props.getUser(tokenUserId);
    }
  }, [props.isLoggedIn]);

  useEffect(() => {
    setUnassignedParcelCount(props.unassignedLegalParcelCount);
  }, [props.unassignedLegalParcelCount]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const getDefaultPath = () => {
    switch (currentType) {
      case "0":
        return "/admin/landing";
        break;
      case "1":
        return `/county/${currentCounty}/landing`;
        break;
      case "2":
        return "/taxpayer/parcels/mine";
        break;
      case "3":
        return `/legal/parcels?countyID=${currentCounty}`;
        break;
      default:
        return "/parcels/list";
        break;
    }
  };

  return (
    <React.Fragment>
      <header className={"fixed-header no-print"}>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 nav" light>
          <Container>
            <NavbarBrand tag={Link} to={getDefaultPath()}>
              <img style={{ width: "330px" }} src="/images/adairgroup.svg" />
            </NavbarBrand>
            <NavbarToggler onClick={toggle} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={isOpen} navbar>
              <ul className="navbar-nav flex-grow">
                {!props.isLoggedIn ? (
                  <div className="navItemList">
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/parcels/list">
                        Lien Payoff
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/about">
                        About
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/faq">
                        FAQ
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/contact">
                        Contact Us
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/login">
                        Login
                      </NavLink>
                    </NavItem>
                  </div>
                ) : currentType === "0" ? (
                  <div className="navItemList">
                    <NavLink tag={Link} className="text-dark" to="/admin/landing">
                      All Liens
                    </NavLink>
                    <NavLink tag={Link} className="text-dark" to="/admin/payments">
                      Payoff View Logs
                    </NavLink>
                    <NavLink tag={Link} className="text-dark" to="/admin/webPaymentLogs">
                      Payment Logs
                    </NavLink>
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/admin/users">
                        Users
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/admin/counties">
                        Counties
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink tag={Link} className="text-dark" to={`/admin/settings`}>
                        Admin Settings
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to={`/users/${currentUserId}`}>
                        Edit Profile
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        className="text-dark"
                        to="/login"
                        onClick={() => {
                          props.clearToken();
                        }}
                      >
                        Logout {currentUsername}
                      </NavLink>
                    </NavItem>
                  </div>
                ) : currentType === "1" ? (
                  <div className="navItemList">
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to={`/county/${currentCounty}/landing`}>
                        Home
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to={`/county/${currentCounty}/parcels`}>
                        All Liens
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to={`/county/${currentCounty}/reports`}>
                        Reports
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to={`/users/${currentUserId}`}>
                        Edit Profile
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        className="text-dark"
                        to="/login"
                        onClick={() => {
                          props.clearToken();
                        }}
                      >
                        Logout {currentUsername}
                      </NavLink>
                    </NavItem>
                  </div>
                ) : currentType === "3" ? (
                  <div className="navItemList">
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to={`/legal/parcels?countyID=${currentCounty}`}>
                        All Parcels
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to={`/legal/parcels?countyID=${currentCounty}&mine=true`}>
                        My Parcels
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to={`/legal/parcels?countyID=${currentCounty}&unassigned=true`}>
                        Unassigned Parcels <Badge value={unassignedParcelCount} severity="info"></Badge>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to={`/users/${currentUserId}`}>
                        Edit Profile
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        className="text-dark"
                        to="/login"
                        onClick={() => {
                          props.clearToken();
                        }}
                      >
                        Logout {currentUsername}
                      </NavLink>
                    </NavItem>
                  </div>
                ) : (
                  <div className="navItemList">
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/parcels/list">
                        Lien Payoff
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/taxpayer/parcels/mine">
                        My Parcels
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/taxpayer/payments/mine">
                        My Payments
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/about">
                        About
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/faq">
                        FAQ
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to="/contact">
                        Contact Us
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag={Link} className="text-dark" to={`/users/${currentUserId}`}>
                        Edit Profile
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        className="text-dark"
                        to="/login"
                        onClick={() => {
                          props.clearToken();
                        }}
                      >
                        Logout {currentUsername}
                      </NavLink>
                    </NavItem>
                  </div>
                )}
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(Navigation as any);
