import react, * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../store";
import * as ParcelStore from "../store/parcel";
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import "./footer.scss";
import { AccountType } from "../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { IServiceTracking } from "../models/IServiceTracking";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

interface ComponentProps {
  serviceTracking: IServiceTracking;
}

type ServiceTrackingItemProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & ComponentProps & RouteComponentProps<{}>;

const ServiceTrackingItem: React.FC<ServiceTrackingItemProps> = (props) => {
  const [selectedServiceTracking, setSelectedServiceTracking] = react.useState(props.serviceTracking);

  const toast: any = useRef(null);

  const notify: any = () => {
    toast.current.show({ severity: "success", summary: "Data Saved", detail: "Service Data Saved Successfully!" });
  };

  return (
    <React.Fragment>
      <Toast ref={toast} position="top-right" />
      <div className="p-grid">
        <div className="p-col-4">
          <InputText style={{ width: "100%" }}
            name="name"
            value={selectedServiceTracking.defendantName}
            onChange={(e) => {
              setSelectedServiceTracking({ ...selectedServiceTracking, defendantName: (e.target as unknown as HTMLTextAreaElement).value } as IServiceTracking);
            }}
            onBlur={(e) => {
              props.updateServiceTracking(selectedServiceTracking as IServiceTracking);
              notify();
            }}
          />
        </div>
        <div className="p-col-7">
          <InputText
            name="type"
            style={{width: "100%"}}
            value={selectedServiceTracking.serviceType}
            onChange={(e) => {
              setSelectedServiceTracking({ ...selectedServiceTracking, serviceType: (e.target as unknown as HTMLTextAreaElement).value } as IServiceTracking);
            }}
            onBlur={(e) => {
              props.updateServiceTracking(selectedServiceTracking as IServiceTracking);
              notify();
            }}
          />
        </div>
        {/* <div className="p-col-3">
          <Calendar
            id="date"
            value={new Date(selectedServiceTracking.serviceDate)}
            showIcon
            onSelect={(e) => {
              setSelectedServiceTracking({ ...selectedServiceTracking, serviceDate: e.value } as IServiceTracking);
              props.updateServiceTracking({ ...selectedServiceTracking, serviceDate: e.value } as IServiceTracking);
              notify();
            }}
          />
        </div> */}
        <div className="p-col-1">
          <Button
            //label={`Add`}
            icon="pi pi-trash"
            className="p-mr-2 p-button-outlined p-button-danger"
            onClick={() => {
              props.deleteServiceTracking(selectedServiceTracking.defendantID);
              props.clearLegalServiceTracking();
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ServiceTrackingItem;
//export default connect((state: ApplicationState) => state.user, ParcelStore.actionCreators)(ServiceTrackingItem as any);
