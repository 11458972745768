import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../../store";
import * as ParcelStore from "../../../../store/parcel";
import * as UserStore from "../../../../store/user";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { IParcel } from "../../../../models/IParcel";
import { InputSwitch } from "primereact/inputswitch";
import { AccountType } from "../../../../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { Toolbar } from "primereact/toolbar";
import { SplitButton } from "primereact/splitbutton";
import { Menu } from "primereact/menu";
import { Dialog } from "primereact/dialog";

type PaymentProcessCancelProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & RouteComponentProps<{}>;

function PaymentProcessCancel(props: PaymentProcessCancelProps) {
  const [selectedParcel, setSelectedParcel] = useState(props.selectedParcel);
  const [selectedParcelId, setSelectedParcelId] = useState(props.selectedParcel?.parcelID);
  const [isFromMyParcels, setIsFromMyParcels] = useState(false);

  useEffect(() => {
    const selectedParcelId = (props.match.params as any).parcelId;
    const query = new URLSearchParams(props.location.search);
    const isFromMyParcelsParam = query.get("fromMyParcels") == "true";
    setIsFromMyParcels(isFromMyParcelsParam);
    setSelectedParcelId(selectedParcelId);
    props.getParcel(selectedParcelId);
  }, []);

  const leftContents = (
    <React.Fragment>
      <Button
        label="Back"
        icon="pi pi-arrow-left"
        className="p-mr-2"
        onClick={() => {
          props.history.push(`/parcels/${selectedParcelId}/payoffrequest`);
        }}
      />
    </React.Fragment>
  );

  const rightContents = <React.Fragment></React.Fragment>;

  return (
    <React.Fragment>
      <div>
        <Toolbar left={leftContents} right={rightContents} />
      </div>
      <div>
        <br />
        <div className="p-message p-component p-message-error p-message-enter-done">
          <div className="p-m-2">
            <br />
            <h4>Payment was canceled.</h4>
            <br />
          </div>
        </div>
      </div>
      <br />
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.parcel, ParcelStore.actionCreators)(PaymentProcessCancel as any);
