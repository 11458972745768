import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as UserStore from "../../../store/user";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useRef, useState } from "react";
import { render } from "react-dom";
import { Card } from "primereact/card";
import { IParcel } from "../../../models/IParcel";
import { ICounty } from "../../../models/ICounty";

type CountyListProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

function CountyList(props: CountyListProps) {
  const [countyList, setCountyList] = useState(props.counties);
  const [selectedApprovedFilterValue, setSelectedApprovedFilterValue] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");
  const [boolText, setBoolText] = useState([
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ]);

  useEffect(() => {
    props.getCounties();
  }, []);

  useEffect(() => {
    setCountyList(props.counties);
  }, [props.counties]);

  const dt = useRef<DataTable<ICounty[]>>(null);



  const formatDate: any = (rowData: any) => {
    let value = new Date(rowData.created);

    return value.toLocaleString();
  };



  const onExport = () => {
    if (dt && dt.current != null) dt.current.exportCSV({ selectionOnly: false });
  };

  const handleRowClick: any = (e: any) => {
    props.history.push(`/admin/counties/${e.data.countyID}`);
  };

  const header = (
    <div style={{ textAlign: "left" }}>
      <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
      <InputText type="search" onInput={(e) => setGlobalFilter((e.target as unknown as HTMLTextAreaElement).value)} placeholder="Filter" size={50} />
      {/* <Button className="float-right" type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={onExport}></Button> */}
    </div>
  );

  const paginatorLeft = <Button icon="pi pi-refresh" />;

  return (
    <React.Fragment>
      <Card title="Counties"></Card>
      <br />
      <DataTable
        filterDisplay="row"
        ref={dt}
        value={props.counties}
        onRowClick={($event) => handleRowClick($event)}
        selectionMode="single"
        header={header}
        globalFilter={globalFilter}
        paginator={true}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rows={20}
        rowsPerPageOptions={[5, 10, 20, 50]}
      >
        <Column field="countyName" header="County" sortable={true} />
        <Column field="stateID" header="State" sortable={true} />
      </DataTable>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(CountyList as any);
