import * as React from "react";
import { connect } from "react-redux";

const Help = () => (
  <div>
    <h1>Help</h1>
  </div>
);

export default connect()(Help);
