import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as UserStore from "../../../store/user";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { AccountType } from "../../../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { ICounty } from "../../../models/ICounty";
import { InputMask } from "primereact/inputmask";
import { Checkbox } from 'primereact/checkbox';
import { ICountySettings } from "../../../models/ICountySettings";

type CountyEditProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

function CountyEdit(props: CountyEditProps) {
    const [selectedCounty, setSelectedCounty] = useState(props.selectedCounty);
    const [selectedCountySettings, setSelectedCountySettings] = useState(props.selectedCountySettings);


    useEffect(() => {
        setSelectedCounty(props.selectedCounty);
    }, [props.selectedCounty]);

    useEffect(() => {
        setSelectedCountySettings(props.selectedCountySettings);
        console.log(selectedCountySettings);
    }, [props.selectedCountySettings]);


    useEffect(() => {
        const selectedCountyId = (props.match.params as any).countyId;
        props.getCounty(selectedCountyId);
        props.getCountySettings(selectedCountyId);
    }, []);

    const notify = () => {
        toast.success("County Settings Saved!");
    };

    return (
        <React.Fragment>
            <div className="p-grid">
                <div className="p-col-4"></div>
                <div className="p-col-3">
                    <Button
                        label="Back to County List"
                        icon="pi pi-arrow-left"
                        className="p-mr-2"
                        onClick={() => {
                            props.history.push(`/admin/counties`);
                        }}
                    /><br /><br />
                    <Card title="Edit County Settings" subTitle={`${selectedCounty?.countyName}, ${selectedCounty?.stateID}`} style={{ width: "500px" }}>

                        <label>Can Request Payoff:</label>&nbsp;&nbsp;
                        <Checkbox onChange={e => setSelectedCountySettings({ ...selectedCountySettings, canRequestPayoff: e.checked } as ICountySettings)} checked={selectedCountySettings?.canRequestPayoff as boolean}></Checkbox>
                        <br />
                        <br />
                        <label>Must Get Payoff From County If Sheriff Sale Date:</label>&nbsp;&nbsp;
                        <Checkbox onChange={e => setSelectedCountySettings({ ...selectedCountySettings, mustGetPayoffFromCountyIfSheriffSaleDate: e.checked } as ICountySettings)} checked={selectedCountySettings?.mustGetPayoffFromCountyIfSheriffSaleDate as boolean}></Checkbox>
                        <br />
                        <br />

                        <label>No Payoff After Legal:</label>&nbsp;&nbsp;
                        <Checkbox onChange={e => setSelectedCountySettings({ ...selectedCountySettings, noPayoffAfterLegal: e.checked } as ICountySettings)} checked={selectedCountySettings?.noPayoffAfterLegal as boolean}></Checkbox>
                        <br />
                        <br />
                        <Button
                            label="Save"
                            onClick={() => {

                                props.updateCountySettings({ ...selectedCountySettings } as ICountySettings);
                                notify();

                            }}
                        />
                    </Card>
                </div>
                <div className="p-col-2"></div>
            </div>
        </React.Fragment>
    );
}

export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(CountyEdit as any);
