import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../store";
import * as UserStore from "../store/user";
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import "./footer.scss";
import { AccountType } from "../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";

type FooterProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

function Footer(props: FooterProps) {
  return (
    <React.Fragment>
      <div className={"fixed-footer no-print"}>
        <div className="p-grid">
          <div className="p-col-4" style={{ textAlign: "center" }}>
            <NavLink tag={Link} className="text-dark" to={`/disclaimer`}>
              Disclaimer
            </NavLink>
          </div>
          <div className="p-col-4" style={{ textAlign: "center", paddingTop: "1rem" }}>
            © US Assets, LLC.
          </div>
          <div className="p-col-4" style={{ textAlign: "center" }}>
            {" "}
            <NavLink tag={Link} className="text-dark" to={`/privacy`}>
              Privacy Policy
            </NavLink>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(Footer as any);
