import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../store";
import * as UserStore from "../../store/user";
import { ICreateUser } from "../../models/ICreateUser";
import { Card } from "primereact/card";
import { Link } from "react-router-dom";

type ConfirmationProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

class ConfirmationTaxPayer extends React.PureComponent<ConfirmationProps, ICreateUser> {
  public render() {
    return (
      <React.Fragment>
        <Card title="Success!" subTitle="">
          <h2>
            User account was created successfully. <br />
            <br />
            Return to the login screen to sign in and get started!
            <br />
            <br />
            <Link to="/login">Log In</Link>
          </h2>
        </Card>
      </React.Fragment>
    );
  }
}

export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(ConfirmationTaxPayer as any);
