import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { ApplicationState } from "../../../store";
import * as ParcelStore from "../../../store/parcel";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

type ExpensesByCertificateReportProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & RouteComponentProps<{}>;

function ExpensesByCertificateReport(props: ExpensesByCertificateReportProps) {
  const [selectedCountyId, setSelectedCountyId] = useState(0);
  const [selectedApprovedFilterValue, setSelectedApprovedFilterValue] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");
  const [boolText, setBoolText] = useState([
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ]);

  useEffect(() => {
    const selectedCountyId = (props.match.params as any).countyId;
    setSelectedCountyId(selectedCountyId);
    props.getExpensesByCertificateReport(selectedCountyId);
  }, []);

  const dt = useRef<DataTable<any[]>>(null);

  const onApprovedFilterValueChange: any = (event: any) => {
    if (dt && dt.current != null) {
      dt.current.filter(event.value, "approved", "equals");
    }
  };

  const accountTypeTemplate: any = (rowData: any) => {
    if (rowData.accountType === 0) {
      return "Admin";
    } else if (rowData.accountType === 1) {
      if (rowData.approved) {
        return (
          <div>
            County <br />
            {/* {rowData.county.countyName} */}
          </div>
        );
      } else {
        return "County - Pending";
      }
    } else if (rowData.accountType === 3) {
      if (rowData.approved) {
        return (
          <div>
            Legal <br />
            {/* {rowData.county.countyName} */}
          </div>
        );
      } else {
        return "Legal - Pending";
      }
    } else if (rowData.accountType === 2) {
      return <div>TaxPayer</div>;
    }
  };

  const isApprovedTemplate: any = (rowData: any) => {
    let value = rowData.approved as boolean;

    if (value) {
      return <span>Yes</span>;
    } else {
      return <span>No</span>;
    }
  };

  const formatDate: any = (rowData: any) => {
    let value = new Date(rowData.created);

    return value.toLocaleString();
  };

  const nameTemplate: any = (rowData: any) => {
    return `${rowData.lastName}, ${rowData.firstName}`;
  };

  const onExport = () => {
    if (dt && dt.current != null) dt.current.exportCSV({ selectionOnly: false });
  };

  const handleRowClick: any = (e: any) => {
    props.history.push(`/users/${e.data.id}`);
  };

  const header = (
    <div style={{ textAlign: "left" }}>
      <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
      <InputText type="search" onInput={(e) => setGlobalFilter((e.target as unknown as HTMLTextAreaElement).value)} placeholder="Filter" size={50} />
      <Button className="float-right" type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={onExport}></Button>
    </div>
  );

  const leftContents = (
    <React.Fragment>
      <Button
        label="Back to Report List"
        icon="pi pi-arrow-left"
        className="p-mr-2"
        onClick={() => {
          props.history.push(`/county/${selectedCountyId}/reports`);
        }}
      />
    </React.Fragment>
  );

  const rightContents = <React.Fragment></React.Fragment>;

  const formatAddress: any = (rowData: any) => {
    return (
      <div>
        {rowData.propertyAddress}
        <br />
        {rowData.propertyCity}, {rowData.propertyState}, {rowData.propertyZip}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div>
        <Toolbar left={leftContents} right={rightContents} />
      </div>
      <br />
      <Card title="Expenses By Certificate Report">
        <DataTable
          filterDisplay="row"
          ref={dt}
          value={props.expensesByCertificateReport}
          //onRowClick={($event) => handleRowClick($event)}
          selectionMode="single"
          header={header}
          globalFilter={globalFilter}
          paginator={true}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rows={20}
          rowsPerPageOptions={[5, 10, 20, 50]}
          style={{ width: "3500px" }}
        >
          <Column field="stateId" header="StateId" sortable={true} filter />
          <Column field="countyName" header="CountyName" sortable={true} filter />
          <Column field="taxSaleNo" header="TaxSaleNo" sortable={true} filter />
          <Column field="parcelNo" header="ParcelNo" sortable={true} filter />
          <Column field="ownerName" header="OwnerName" sortable={true} filter />
          <Column field="propertyAddress" header="PropertyAddress" sortable={true} filter body={formatAddress} />
          <Column field="caseNum" header="CaseNum" sortable={true} filter />
          <Column field="rtRecievedDate" header="RTRecievedDate" sortable={true} filter />
          <Column field="dateRdmd" header="DateRdmd" sortable={true} filter />
          <Column field="status" header="Status" sortable={true} filter />
          <Column field="expenseAmt" header="ExpenseAmt" sortable={true} filter />
          <Column field="expenseDate" header="ExpenseDate" sortable={true} filter />
          <Column field="expenseDescr" header="ExpenseDescr" sortable={true} filter />
        </DataTable>
      </Card>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.parcel, ParcelStore.actionCreators)(ExpensesByCertificateReport as any);
