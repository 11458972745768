import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../../store";
import * as ParcelStore from "../../../../store/parcel";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { Toolbar } from "primereact/toolbar";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { IParcel } from "../../../../models/IParcel";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import { AnyObject } from "chart.js/types/basic";
import ServiceTracking from "../../../../components/ServiceTracking";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import ExpenseTracking from "../../../../components/ExpenseTracking";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import jwt_decode from "jwt-decode";
import EditLegalDates from "../../../../components/legal/editLegalDates";
import PropertyDetails from "../../../../components/legal/propertyDetails";

type LegalParcelDetailsProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & RouteComponentProps<{}>;

function LegalParcelDetails(props: LegalParcelDetailsProps, selectedParcelIdNumber: number) {
  const [selectedParcel, setSelectedParcel] = useState(props.selectedParcel);
  const [relatedTaxCertificateMenuItems, setRelatedTaxCertificateMenuItems] = useState();
  const [attorneyUsers, setAttorneyUsers] = useState([{ label: "Test", value: 0 }]);
  const [selectedParcelId, setSelectedParcelId] = useState(props.selectedParcel?.parcelID);
  const [isFromAdmin, setIsFromAdmin] = useState(false);
  const [isFromLegal, setIsFromLegal] = useState(false);
  const [displayExpenses, setDisplayExpenses] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currentUserAccountType, setVsetCurrentUserAccountTypeisible] = useState(0);
  let items: any;
  const toast: any = useRef(null);


  useEffect(() => {
    var selectedParcelId = (props.match.params as any).parcelId;
    const query = new URLSearchParams(props.location.search);
    const isFromAdminParam = query.get("fromAdmin") == "true";
    setIsFromAdmin(isFromAdminParam);
    const isFromLegalParam = query.get("fromLegal") == "true";
    setIsFromLegal(isFromLegalParam);
    console.log(props.location.search);
    if (selectedParcelId == undefined || selectedParcelId == null || selectedParcelId == "") {
      selectedParcelId = props.selectedParcelId;
      setIsFromLegal(true);
    }


    props.getCountyParcel(selectedParcelId);
    window.scrollTo(0, 0);
    setVsetCurrentUserAccountTypeisible((jwt_decode(localStorage.getItem("token") as string) as any).AccountType as number);
  }, []);

  useEffect(() => {
    setSelectedParcel(props.selectedParcel);
    items = props.selectedParcel?.relatedTaxCertificates.map(function (object, i) {
      return {
        label: object.taxSaleNo,
        icon: "pi pi-reply",
        command: () => {
          setSelectedParcelId(object.parcelID);
          props.getCountyParcel(object.parcelID);
          props.getCountyParcelCorrespondence(object.parcelID);
        },
      };
    });
    setRelatedTaxCertificateMenuItems(items);
    props.getAttorneyUsers(selectedParcel?.ohsAttorneyID ?? 0);
    //console.log(props.selectedParcel);
  }, [props.selectedParcel]);

  useEffect(() => {
    if (props.attorneyUsers) {
      setAttorneyUsers(
        props.attorneyUsers.map(function (item) {
          return { label: `${item.lastName}, ${item.firstName}`, value: item.id };
        })
      );
    }
  }, [props.attorneyUsers]);

  const formatDate: any = (date: any) => {
    if (date == null || date == undefined || date == "") {
      return "";
    }
    let value = new Date(date);
    var dd = String(value.getDate()).padStart(2, "0");
    var mm = String(value.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = value.getFullYear();

    return mm + "/" + dd + "/" + yyyy;

    //return value.toDateString();
  };




  const notify: any = () => {
    toast.current.show({ severity: "success", summary: "Data Saved", detail: "Parcel Data Saved Successfully!" });
  };

  const accept = () => {
    toast.current.show({ severity: "info", summary: "Confirmed", detail: "You have accepted", life: 3000 });
  };

  const reject = () => {
    toast.current.show({ severity: "warn", summary: "Rejected", detail: "You have rejected", life: 3000 });
  };

  const leftContents = (
    <React.Fragment>
      <Button
        label={`Back to Certificate ${selectedParcel?.taxSaleNo}`}
        icon="pi pi-arrow-left"
        className="p-mr-2"
        onClick={() => {
          props.history.push(`/county/${selectedParcel?.countyID}/parcels/${selectedParcel?.parcelID}?fromLegal=${isFromLegal}&fromAdmin=${isFromAdmin}`);
        }}
      />
    </React.Fragment>
  );

  const rightContents = (
    <React.Fragment>
      {currentUserAccountType != 1 && <Button
        label={`Manage Expenses`}
        icon="pi pi-dollar"
        iconPos="left"
        className="p-mr-2"
        onClick={() => {
          setDisplayExpenses(true);
        }}
      />}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Toast ref={toast} position="top-right" />
      {/*<div>
        <Toolbar left={leftContents} right={rightContents} />
      </div>
       <br />
      <PropertyDetails {...props} />
      <br /> */}
      <div style={{ backgroundColor: "#F0F0F0", paddingLeft: "24px" }}>
        <div className="p-grid">
          <div className="p-col">
            <label className="field-label">Case No:</label>
            <br />
            <InputText
              name="caseNum"
              value={selectedParcel?.caseNum as string}
              onChange={(e) => {
                setSelectedParcel({ ...selectedParcel, caseNum: (e.target as unknown as HTMLTextAreaElement).value } as IParcel);
              }}
              onBlur={(e) => {
                props.updateParcel(selectedParcel as IParcel);
                notify();
              }}
            />
          </div>
          <div className="p-col">
            <label className="field-label">Ref No:</label>
            <br />
            <InputText
              name="webRefNum"
              value={selectedParcel?.webRefNum as string}
              onChange={(e) => {
                setSelectedParcel({ ...selectedParcel, webRefNum: (e.target as unknown as HTMLTextAreaElement).value } as IParcel);
              }}
              onBlur={(e) => {
                props.updateParcel(selectedParcel as IParcel);
                notify();
              }}
            />
          </div>
          <div className="p-col-3"></div>
          <div className="p-col-4">
            <label className="field-label">Assigned Attorney:</label>
            <br />
            <Dropdown
              value={selectedParcel?.webAssignedAttorneyUserID}
              options={attorneyUsers}
              onChange={(e) => {
                props.updateParcel({ ...selectedParcel, webAssignedAttorneyUserID: e.value as unknown as number } as IParcel);
                notify();
              }}
              placeholder="Select A Contact"
            />
          </div>
          <div className="p-col"></div>
        </div>

        <div className="p-grid">
          <div className="p-col-7">
            <ServiceTracking {...props} />
          </div>

          <div className="p-col-4">
            <label className="field-label">Legal Note:</label>
            <br />
            <InputTextarea
              rows={5}
              cols={50}
              autoResize
              name="caseNum"
              value={selectedParcel?.webNotes as string}
              onChange={(e) => {
                setSelectedParcel({ ...selectedParcel, webNotes: (e.target as unknown as HTMLTextAreaElement).value } as IParcel);
              }}
              onBlur={(e) => {
                props.updateParcel(selectedParcel as IParcel);
                notify();
              }}
            />
          </div>
        </div>

        <EditLegalDates {...props} />
        <div style={{ width: "100%", textAlign: "right", paddingRight: "20px", marginTop: "-40px", paddingBottom: "20px" }} >
          <a href={`/county/${selectedParcel?.countyID}/parcels/${props.selectedParcelId}?fromLegal=true&fromAdmin=false`} onClick={() => {
            props.history.push(`/county/${selectedParcel?.countyID}/parcels/${props.selectedParcelId}?fromLegal=true&fromAdmin=false`);
          }} >View Payoff & Adair Correspondence Details</a>
        </div>
      </div>
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to proceed?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />
      {/* <Button onClick={() => setVisible(true)} icon="pi pi-check" label="Confirm" /> */}
      <Dialog header="Manage Expenses" visible={displayExpenses} style={{ width: "95vw" }} onHide={() => setDisplayExpenses(false)}>
        <div className="p-grid">
          <div className="p-col">
            <Card title="" style={{ width: "100%" }}>
              <ExpenseTracking {...props} />
            </Card>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.parcel, ParcelStore.actionCreators)(LegalParcelDetails as any);


