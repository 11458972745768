import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../../store";
import * as ParcelStore from "../../../../store/parcel";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { IParcel } from "../../../../models/IParcel";
import { InputSwitch } from "primereact/inputswitch";
import { AccountType } from "../../../../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";

type ParcelDetailsPaymentPlanRequestProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & RouteComponentProps<{}>;

function ParcelDetailsPaymentPlanRequestSuccess(props: ParcelDetailsPaymentPlanRequestProps) {
  const [selectedParcel, setSelectedParcel] = useState(props.selectedParcel);
  const [comment, setComment] = useState("");
  const [currentUserId, setCurrentUserId] = useState(0);
  const [currentUser, setCurrentUser] = useState({ email: "default@email.com" });

  useEffect(() => {
    setSelectedParcel(props.selectedParcel);
  }, [props.selectedParcel]);

  useEffect(() => {
    const selectedParcelId = (props.match.params as any).parcelId;
    props.getParcel(selectedParcelId);
    props.logPayoffView(selectedParcelId);
  }, []);

  const notify = () => {
    toast.success("Settings Saved!");
  };

  const leftContents = (
    <React.Fragment>
      <Button
        icon="pi pi-arrow-left"
        className="p-mr-2"
        label={`Back to Certificate ${selectedParcel?.taxSaleNo}`}
        onClick={() => {
          props.history.push(`/parcels/${selectedParcel?.parcelID}`);
        }}
      />
    </React.Fragment>
  );

  const rightContents = <React.Fragment>{(selectedParcel?.payofF_TOTAL ?? 0) > 0 && <div></div>}</React.Fragment>;

  return (
    <React.Fragment>
      <div>
        <Toolbar left={leftContents} right={rightContents} />
      </div>

      <div className="p-message p-component p-message-success p-message-enter-done" style={{ padding: "8px" }}>
        <p style={{ fontSize: "30px" }}>{`Request for a payment plan for Tax Certificate Number ${selectedParcel?.taxSaleNo} was submitted successfully.`}</p>
        <p style={{ fontSize: "20px" }}>
          Your request for a payment plan has been sent to a Servicing Specialist for review. Payment plan options will be e-mailed to you at{" "}
          {props.currentUser.email} within 1 business day.
        </p>
        <p style={{ fontSize: "20px" }}>
          If you have any questions in the meantime, please do not hesitate to contact us by e-mail at{" "}
          <a href="mailto:servicing@usassets.net">servicing@usassets.net</a> or by telephone at <br />
          (844) 216-4341 using reference: {selectedParcel?.taxSaleNo}.
        </p>
      </div>

      <div className="p-grid">
        <div className="p-col">
          <Card title={`Tax Certificate ${selectedParcel?.taxSaleNo}`} subTitle={`Parcel Number ${selectedParcel?.parcelNo}`} style={{ height: "524px" }}>
            <label className="field-label">
              <i className="pi pi-user"></i>&nbsp; Owner:
            </label>
            <br /> {selectedParcel?.ownerName}
            <br />
            <br />
            <label className="field-label">
              <i className="pi pi-map"></i>&nbsp; Property Address:
            </label>
            <br />
            {selectedParcel?.propertyAddress}
            <br />
            {selectedParcel?.propertyCity}, {selectedParcel?.stateName}, {selectedParcel?.propertyZip}
            <br />
            <br />
            <label className="field-label">
              <i className="pi pi-calendar"></i>&nbsp; Purchase Date:
            </label>
            <br />
            {new Date(selectedParcel?.purchaseDate ?? "1/1/1970").toLocaleString().split(",")[0]}
            <br />
            <br />
            {selectedParcel?.caseNum != undefined && (
              <div>
                <label className="field-label">
                  <i className="pi pi-briefcase"></i>&nbsp; Case Number:{" "}
                </label>
                <br />
                <span>{selectedParcel?.caseNum}</span>
                <br />
                <br />
              </div>
            )}
            {selectedParcel?.claimNum != undefined && (
              <div>
                <label className="field-label">
                  <i className="pi pi-briefcase"></i>&nbsp;Bankruptcy Case Number:{" "}
                </label>
                <br />
                <span>{selectedParcel?.claimNum}</span>
                <br />
                <br />
              </div>
            )}
          </Card>
        </div>
        <div className="p-col">
          <Card
            title={`${selectedParcel?.propertyAddress}`}
            subTitle={`${selectedParcel?.propertyCity}, ${selectedParcel?.stateName}, ${selectedParcel?.propertyZip}`}
            style={{ height: "524px" }}
          >
            <div style={{ width: "100%", overflow: "hidden", height: "398px" }}>
              <iframe
                width="100%"
                height="700"
                loading="lazy"
                style={{ marginTop: "-150px" }}
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC2-toCyYhXyGwn4PBxifmdDT9ZOAjQ2ec&q=${selectedParcel?.propertyAddress ?? ""},${
                  selectedParcel?.propertyCity
                }+${selectedParcel?.stateName}`}
              ></iframe>
            </div>
          </Card>
        </div>
      </div>

      <br />
      <br />
      <br />
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.parcel, ParcelStore.actionCreators)(ParcelDetailsPaymentPlanRequestSuccess as any);
