import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../../store";
import * as ParcelStore from "../../../../store/parcel";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { IParcel } from "../../../../models/IParcel";
import { InputSwitch } from "primereact/inputswitch";
import { AccountType } from "../../../../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { Menu } from "primereact/menu";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";

import { TabView, TabPanel } from "primereact/tabview";
import { Column } from "primereact/column";

type CountyParcelDetailsPayoffProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & RouteComponentProps<{}>;

function CountyParcelDetailsPayoff(props: CountyParcelDetailsPayoffProps) {
  const [selectedParcel, setSelectedParcel] = useState(props.selectedParcel);
  const [selectedParcelId, setSelectedParcelId] = useState(props.selectedParcel?.parcelID);
  const [relatedTaxCertificateMenuItems, setRelatedTaxCertificateMenuItems] = useState();
  const [payoffDataCurrent, setPayoffDataCurrent] = useState([{ type: "Principal", amount: 0 }]);
  const [payoffDataNext, setPayoffDataNext] = useState([{ type: "Principal", amount: 0 }]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [expandedRows, setExpandedRows] = useState({});
  const [activeIndex1, setActiveIndex1] = useState(1);
  const [activeIndex2, setActiveIndex2] = useState(0);
  const [isFromAdmin, setIsFromAdmin] = useState(false);

  const dtCurrent = useRef<DataTable<any[]>>(null);
  const dtNext = useRef<DataTable<any[]>>(null);
  let items: any;
  const menu: any = useRef(null);

  useEffect(() => {
    setSelectedParcel(props.selectedParcel);
    setPayoffDataCurrent([
      { type: "Principal", amount: selectedParcel?.payofF_PRINCIPLE ?? 0 },
      { type: "Interest", amount: selectedParcel?.payofF_INTEREST ?? 0 },
      { type: "Expenses", amount: selectedParcel?.payofF_EXPENSE ?? 0 },
      { type: "Total Payoff", amount: selectedParcel?.payofF_TOTAL ?? 0 },
    ]);
    setPayoffDataNext([
      { type: "Principal", amount: selectedParcel?.payofF_PRINCIPLE_FUTURE ?? 0 },
      { type: "Interest", amount: selectedParcel?.payofF_INTEREST_FUTURE ?? 0 },
      { type: "Expenses", amount: selectedParcel?.payofF_EXPENSE_FUTURE ?? 0 },
      { type: "Total Payoff", amount: selectedParcel?.payofF_TOTAL_FUTURE ?? 0 },
    ]);
    items = props.selectedParcel?.relatedTaxCertificates.map(function (object, i) {
      return {
        label: object.taxSaleNo,
        icon: "pi pi-reply",
        command: () => {
          setSelectedParcelId(object.parcelID);
          props.getCountyParcel(object.parcelID);
        },
      };
    });
    setRelatedTaxCertificateMenuItems(items);
  }, [props.selectedParcel]);

  useEffect(() => {
    setPayoffDataCurrent([
      { type: "Principal", amount: selectedParcel?.payofF_PRINCIPLE ?? 0 },
      { type: "Interest", amount: selectedParcel?.payofF_INTEREST ?? 0 },
      { type: "Expenses", amount: selectedParcel?.payofF_EXPENSE ?? 0 },
      { type: "Total Payoff", amount: selectedParcel?.payofF_TOTAL ?? 0 },
    ]);
    setPayoffDataNext([
      { type: "Principal", amount: selectedParcel?.payofF_PRINCIPLE_FUTURE ?? 0 },
      { type: "Interest", amount: selectedParcel?.payofF_INTEREST_FUTURE ?? 0 },
      { type: "Expenses", amount: selectedParcel?.payofF_EXPENSE_FUTURE ?? 0 },
      { type: "Total Payoff", amount: selectedParcel?.payofF_TOTAL_FUTURE ?? 0 },
    ]);
  }, [selectedParcel]);

  useEffect(() => {
    const selectedParcelId = (props.match.params as any).parcelId;
    const query = new URLSearchParams(props.location.search);
    const isFromAdminParam = query.get("fromAdmin") == "true";
    setIsFromAdmin(isFromAdminParam);
    setSelectedParcelId(selectedParcelId);
    props.getParcel(selectedParcelId);
    props.logPayoffView(selectedParcelId);
  }, []);

  const notify = () => {
    toast.success("Settings Saved!");
  };

  const formatCurrency = (value: number) => {
    if (value != null) {
      return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
    } else {
      return "$0.00";
    }
  };

  const leftContents = (
    <React.Fragment>
      <Button
        icon="pi pi-arrow-left"
        className="p-mr-2"
        label={`Back to Certificate ${selectedParcel?.taxSaleNo}`}
        onClick={() => {
          props.history.push(`/county/${selectedParcel?.countyID}/parcels/${selectedParcel?.parcelID}?fromAdmin=${isFromAdmin}`);
        }}
      />
      {/* {(selectedParcel?.relatedTaxCertificates.length ?? 0) > 0 && (
        <div>
          <Menu model={relatedTaxCertificateMenuItems} popup ref={menu} />
          <Button
            label="View Related Tax Certificates"
            className="p-button-secondary"
            icon="pi pi-chevron-down"
            onClick={(event) => menu.current.toggle(event)}
          />
        </div>
      )} */}
    </React.Fragment>
  );

  const rightContents = <React.Fragment></React.Fragment>;

  const rowExpansionTemplate: any = (data: any) => {
    return (
      <div style={{ marginLeft: "100px" }}>
        <div className="p-grid">
          <div className="p-col-4">
            <label style={{ fontWeight: "bold", fontSize: "large", marginBottom: "4px" }}>Description</label>
            <br />
            {selectedParcel?.subsequentData.map((item) => {
              return (
                <div className="truncate" title={item.subsequentName}>
                  {item.subsequentName}
                  <br />
                </div>
              );
            })}
            <label style={{ fontWeight: "bold", marginTop: "4px" }}>Total</label>
          </div>
          <div className="p-col-2" style={{ textAlign: "right" }}>
            <label style={{ fontWeight: "bold", fontSize: "large", marginBottom: "4px" }}>Amount</label>
            <br />
            {selectedParcel?.subsequentData.map((item) => {
              return (
                <div>
                  {formatCurrency(item.subsequentAmt as number)}
                  <br />
                </div>
              );
            })}
            <label style={{ fontWeight: "bold", marginTop: "4px" }}>{formatCurrency(selectedParcel?.payofF_PRINCIPLE as number)}</label>
          </div>
        </div>
      </div>
    );
  };

  const paginatorLeft = <Button icon="pi pi-refresh" />;

  const header = (
    <div style={{ textAlign: "left" }}>
      <h4>Payoff Breakdown</h4>
    </div>
  );

  const handleRowClick: any = (e: any) => {
    if (e.data.type === "Principal") {
      const data = {
        Principal: true,
      };
      setExpandedRows(data);
    }
  };

  const rowClass: any = (rowData: any) => {
    return {
      noExpander: rowData.type !== "Principal",
    };
  };

  const priceBodyTemplate = (rowData: any) => {
    return formatCurrency(rowData.amount as number);
  };

  return (
    <React.Fragment>
      <div>
        <Toolbar left={leftContents} right={rightContents} />
      </div>
      {(selectedParcel?.relatedTaxCertificates.length ?? 0) > 0 && (
        <div className="p-grid">
          <div className="p-col">
            <br />
            <Card title={`Related Tax Certificates`}>
              {props.selectedParcel?.relatedTaxCertificates.map((item: any) => {
                return (
                  <React.Fragment>
                    <Button
                      className={`p-mr-2  ${item.status == "Redeemed" ? "p-button-success" : "p-button-outlined"}`}
                      label={`${item.taxSaleNo} - ${item.status}`}
                      icon="pi pi-reply"
                      onClick={() => {
                        setSelectedParcelId(item.parcelID);
                        props.getParcel(item.parcelID);
                      }}
                    />
                  </React.Fragment>
                );
              })}
            </Card>
          </div>
        </div>
      )}
      <br />
      <div className="p-grid">
        <div className="p-col">
          <Card title={`Tax Certificate ${selectedParcel?.taxSaleNo}`} subTitle={`Parcel Number ${selectedParcel?.parcelNo}`} style={{ height: "524px" }}>
            <label className="field-label">
              <i className="pi pi-user"></i>&nbsp; Owner:
            </label>
            <br /> {selectedParcel?.ownerName}
            <br />
            <br />
            <label className="field-label">
              <i className="pi pi-map"></i>&nbsp; Property Address:
            </label>
            <br />
            {selectedParcel?.propertyAddress}
            <br />
            {selectedParcel?.propertyCity}, {selectedParcel?.stateName}, {selectedParcel?.propertyZip}
            <br />
            <br />
            <label className="field-label">
              <i className="pi pi-calendar"></i>&nbsp; Purchase Date:
            </label>
            <br />
            {new Date(selectedParcel?.purchaseDate ?? "1/1/1970").toLocaleString().split(",")[0]}
            <br />
            <br />
            {selectedParcel?.caseNum != undefined && (
              <div>
                <label className="field-label">
                  <i className="pi pi-briefcase"></i>&nbsp; Case Number:{" "}
                </label>
                <br />
                <span>{selectedParcel?.caseNum}</span>
                <br />
                <br />
              </div>
            )}
            {selectedParcel?.claimNum != undefined && (
              <div>
                <label className="field-label">
                  <i className="pi pi-briefcase"></i>&nbsp;Bankruptcy Case Number:{" "}
                </label>
                <br />
                <span>{selectedParcel?.claimNum}</span>
                <br />
                <br />
              </div>
            )}
          </Card>
        </div>
        <div className="p-col">
          <Card
            title={`${selectedParcel?.propertyAddress}`}
            subTitle={`${selectedParcel?.propertyCity}, ${selectedParcel?.stateName}, ${selectedParcel?.propertyZip}`}
            style={{ height: "524px" }}
          >
            <div style={{ width: "100%", overflow: "hidden", height: "398px" }}>
              <iframe
                width="100%"
                height="700"
                loading="lazy"
                style={{ marginTop: "-150px" }}
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC2-toCyYhXyGwn4PBxifmdDT9ZOAjQ2ec&q=${selectedParcel?.propertyAddress ?? ""},${selectedParcel?.propertyCity
                  }+${selectedParcel?.stateName}`}
              ></iframe>
            </div>
          </Card>
        </div>
      </div>
      <br />
      {selectedParcel?.hasPPS && selectedParcel?.payPlanStatus != "DEFAULT" && (
        <React.Fragment>
          <br />
          <div className="p-grid">
            <div className="p-col">
              <Card title={`ATTENTION`} className={"red-text"}>
                Our records indicate tax certificate {selectedParcel.taxSaleNo} is in an active payment plan. If you would like to know the balance due or have
                additional questions regarding the payment plan, please contact us at <a href="mailto:servicing@usassets.net">servicing@usassets.net</a> or
                1-844-216-4341.
              </Card>
            </div>
          </div>
        </React.Fragment>
      )}
      <br />
      <Card className="card-full-width">
        <TabView className="tabview-custom">
          <TabPanel
            header={`Good Through: ${new Date(selectedParcel?.payofF_GOOD_THRU ?? "1/1/1970").toLocaleString().split(",")[0]}`}
            leftIcon="pi pi-calendar"
          >
            <DataTable
              filterDisplay="row"
              ref={dtCurrent}
              value={payoffDataCurrent}
              onRowClick={($event) => handleRowClick($event)}
              selectionMode="single"
              rowClassName={rowClass}
              stripedRows
              expandedRows={expandedRows}
              onRowToggle={(e) => setExpandedRows(e.data)}
              rowExpansionTemplate={rowExpansionTemplate}
              dataKey="type"
              header={header}
              globalFilter={globalFilter}
              paginator={false}
              paginatorLeft={paginatorLeft}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              rows={10}
              rowsPerPageOptions={[5, 10, 20, 50]}
              className={"payoff-table p-datatable-sm"}
            >
              <Column expander style={{ width: "40px" }} />
              <Column field="type" header="Type" sortable={false} style={{ width: "120px" }} />
              <Column field="amount" header="Amount" sortable={false} body={priceBodyTemplate} style={{ width: "120px", textAlign: "right" }} />
              <Column field="" header="" sortable={false} style={{ width: "400px" }} />
            </DataTable>
          </TabPanel>
          <TabPanel
            header={`Good Through: ${new Date(selectedParcel?.payofF_GOOD_THRU_FUTURE ?? "1/1/1970").toLocaleString().split(",")[0]}`}
            leftIcon="pi pi-calendar"
          >
            <DataTable
              filterDisplay="row"
              ref={dtNext}
              value={payoffDataNext}
              onRowClick={($event) => handleRowClick($event)}
              selectionMode="single"
              rowClassName={rowClass}
              stripedRows
              expandedRows={expandedRows}
              onRowToggle={(e) => setExpandedRows(e.data)}
              rowExpansionTemplate={rowExpansionTemplate}
              dataKey="type"
              header={header}
              globalFilter={globalFilter}
              paginator={false}
              paginatorLeft={paginatorLeft}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              rows={10}
              rowsPerPageOptions={[5, 10, 20, 50]}
              className={"payoff-table p-datatable-sm"}
            >
              <Column expander style={{ width: "40px" }} />
              <Column field="type" header="Type" sortable={false} style={{ width: "120px" }} />
              <Column field="amount" header="Amount" sortable={false} body={priceBodyTemplate} style={{ width: "120px", textAlign: "right" }} />
              <Column field="" header="" sortable={false} style={{ width: "400px" }} />
            </DataTable>
          </TabPanel>
        </TabView>
      </Card>
      <br />
      <br />
      <br />
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.parcel, ParcelStore.actionCreators)(CountyParcelDetailsPayoff as any);
