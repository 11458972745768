import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { push } from 'react-router-redux';
import { RouterAction } from 'connected-react-router';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UserAdminState {
   
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface LoginAction { type: 'LOGIN' }
export interface LogoutAction { type: 'LOGOUT' }
export interface RequestTokenAction { type: 'REQUEST_TOKEN', userAdminname: string, password: string }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = LoginAction | LogoutAction  | RequestTokenAction | RouterAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    login: () => ({ type: 'LOGIN' } as LoginAction),
    logout: () => ({ type: 'LOGOUT' } as LogoutAction),
    clearToken: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        localStorage.removeItem('token');
        dispatch({ type: 'LOGOUT' });  
    },
    requestAuthToken: (userAdminname: string, password: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState && password) {
            fetch(`api/auth/login`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: `{"userAdminname": "${userAdminname}","password": "${password}"}`
            })
                .then(response => {
                    var data = response.json() as Promise<any>;
                    return data;
                })
                .then(data => {
                    localStorage.setItem('token', data.token);
                    dispatch(push('/counter'));
                });

            dispatch({ type: 'REQUEST_TOKEN', userAdminname: userAdminname, password: password });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<UserAdminState> = (state: UserAdminState | undefined, incomingAction: Action): UserAdminState => {
    if (state === undefined) {
        return {
            //initial state here
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                isLoggedIn: true
            };
        case 'LOGOUT':
            return {
                ...state,
                isLoggedIn: false,
                userAdminname: '',
                password: ''
            };
        case 'REQUEST_TOKEN':
            return {
                ...state,
                isRequesting: true
            };
        default:
            return state;
    }
};
