import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../store";
import * as UserStore from "../../store/user";
import { ICreateUser } from "../../models/ICreateUser";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { AccountType } from "../../models/enums/accountType";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

type ForgotPasswordProps = UserStore.UserState &
  typeof UserStore.actionCreators &
  RouteComponentProps<{}>;

class ForgotPassword extends React.PureComponent<ForgotPasswordProps, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      username: "",
    };
  }

  public render() {
    return (
      <React.Fragment>
        <Card title="Recover your password" subTitle="">
          <label>Username:</label>
          <br />
          <InputText
            name="username"
            value={this.state.username}
            onChange={(e) =>
              this.setState({
                username: (e.target as unknown as HTMLTextAreaElement).value,
              })
            }
          />
          <br />
          <br />

          <Button
            label="Request password reset"
            onClick={() => {
              this.props.requestPasswordReset(this.state.username);
            }}
          />
        </Card>
      </React.Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.user,
  UserStore.actionCreators
)(ForgotPassword as any);
