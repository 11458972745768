import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../store";
import * as UserStore from "../../store/user";
import { ICreateUser } from "../../models/ICreateUser";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { AccountType } from "../../models/enums/accountType";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

type SignupProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

class SignupCounty extends React.PureComponent<SignupProps, ICreateUser> {
  constructor(props: any) {
    super(props);

    this.state = {
      Username: "",
      Password: "",
      PasswordConfirm: "",
      FirstName: "",
      LastName: "",
      Phone: "",
      Email: "",
      AccountType: AccountType.County,
      Address1: "",
      Address2: "",
      City: "",
      State: "",
      Zip: "",
    };
  }

  public render() {
    return (
      <React.Fragment>
        <Card title="Create a County Account" subTitle="">
          <label>Username:</label>
          <br />
          <InputText
            name="username"
            value={this.state.Username}
            onChange={(e) => this.setState({ Username: (e.target as unknown as HTMLTextAreaElement).value })}
          />
          <br />
          <br />

          <label>Password:</label>
          <br />
          <Password
            feedback={false}
            value={this.state.Password}
            onChange={(e) => this.setState({ Password: (e.target as unknown as HTMLTextAreaElement).value })}
          />
          <br />
          <br />

          <label>First Name:</label>
          <br />
          <InputText
            name="username"
            value={this.state.FirstName}
            onChange={(e) => this.setState({ FirstName: (e.target as unknown as HTMLTextAreaElement).value })}
          />
          <br />
          <br />

          <label>Last Name:</label>
          <br />
          <InputText
            name="username"
            value={this.state.LastName}
            onChange={(e) => this.setState({ LastName: (e.target as unknown as HTMLTextAreaElement).value })}
          />
          <br />
          <br />

          <label>Email:</label>
          <br />
          <InputText name="username" value={this.state.Email} onChange={(e) => this.setState({ Email: (e.target as unknown as HTMLTextAreaElement).value })} />
          <br />
          <br />

          <label>Phone:</label>
          <br />
          <InputText name="username" value={this.state.Phone} onChange={(e) => this.setState({ Phone: (e.target as unknown as HTMLTextAreaElement).value })} />
          <br />
          <br />

          <Button
            label="Sign Up"
            onClick={() => {
              this.props.createUser(this.state, "0");
            }}
          />

          <br />
          <br />
          <br />
          <p className="errorText">{this.props.createUserErrorMessage}</p>
        </Card>
      </React.Fragment>
    );
  }
}

export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(SignupCounty as any);
