import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../../store';
import * as AdminSettingsStore from '../../../store/adminSettings'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { IAdminSettings } from '../../../models/IAdminSettings';
import { Card } from 'primereact/card';
import { ToastContainer, toast } from 'react-toastify';
import { FileUpload } from 'primereact/fileupload';
import 'react-toastify/dist/ReactToastify.css';
import { Calendar } from 'primereact/calendar';
import { Editor } from 'primereact/editor';

type AdminSettingsProps =
    AdminSettingsStore.AdminSettingsState &
    typeof AdminSettingsStore.actionCreators &
    RouteComponentProps<{}>;

class AdminSettings extends React.PureComponent<AdminSettingsProps, IAdminSettings> {
    constructor(props: any) {
        super(props);

        this.state = {

            id: 0,
            created: new Date('1/1/2020'),
            updated: new Date('1/1/2020'),
        } as IAdminSettings;
    }

    componentDidMount() {
        this.props.getAdminSettings(1);
        setTimeout(() => { this.setState(this.props.currentAdminSettings as IAdminSettings); }, 250);
    }

    notify: any = () => toast.success('Settings Saved!');

    public render() {

        return (
            <React.Fragment>
                <Card title="Admin Settings" subTitle="">
                    <Button label="Save" onClick={() => {
                        this.props.updateAdminSettings(this.state);
                        this.notify();
                    }} />
                </Card>
            </React.Fragment>
        );
    }
};

export default connect(
    (state: ApplicationState) => state.adminSettings,
    AdminSettingsStore.actionCreators
)(AdminSettings as any);
