import { IServiceTracking } from "./../models/IServiceTracking";
import { BarChartData, DonutChartData } from "./../models/IDonutChartData";
import { IUser } from "./../models/IUser";
import jwt_decode from "jwt-decode";
import { ICounty } from "./../models/ICounty";
import { IParcelSearch } from "./../models/IParcelSearch";
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { RouterAction } from "connected-react-router";
import { IParcel } from "../models/IParcel";
import { History } from "history";
import { ICorrespondence } from "../models/ICorrespondence";
import { IState } from "../models/IState";
import { IPaymentPlan } from "../models/IPaymentPlan";
import { IPayoffRequest } from "../models/IPayoffRequest";
import { AnyRecord } from "dns";
import { IExpenseTracking } from "../models/IExpenseTracking";
import { IPayment } from "../models/IPayment";
import { IPayoff } from "../models/IPayoff";
import { IWebPaymentLog } from "../models/IWebPaymentLog";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ParcelState {
  selectedParcel?: IParcel;
  selectedParcelId?: number;
  selectedParcelImages?: Array<string>;
  selectedParcelDocuments?: Array<string>;
  parcelList: Array<IParcel>;
  paymentList: Array<IPayment>;
  payoffList: Array<IPayoff>;
  countyParcels: Array<IParcel>;
  legalParcels: Array<IParcel>;
  legalServiceTracking: Array<any>;
  legalServiceTrackingUpdated: boolean;
  legalExpenseTracking: Array<any>;
  legalPostedExpenseTracking: Array<any>;
  legalExpenseTrackingUpdated: boolean;
  selectedCountyParcelCorrespondenceList: Array<ICorrespondence>;
  selectedCountyParcelPayoffRequestList: Array<IPayoffRequest>;
  selectedCountyParcelPaymentPlanList: Array<IPaymentPlan>;
  states: Array<IState>;
  counties: Array<ICounty>;
  currentUser: IUser;
  paymentPlansByStatus: DonutChartData;
  countyCorrespondenceCounts: DonutChartData;
  redeemedCounts: BarChartData;
  attorneyUsers: Array<IUser>;
  paymentPlansReport: Array<any>;
  expensesByCertificateReport: Array<any>;
  isSearching: boolean;
  webPaymentLogs: Array<IWebPaymentLog>;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface GetParcelsAction {
  type: "GET_PARCELS_BEGIN";
}
export interface GetParcelsSuccessAction {
  type: "GET_PARCELS_SUCCESS";
  data: Array<IParcel>;
}
export interface GetParcelsErrorAction {
  type: "GET_PARCELS_ERROR";
}

export interface GetStatesAction {
  type: "GET_STATES_BEGIN";
}
export interface GetStatesSuccessAction {
  type: "GET_STATES_SUCCESS";
  data: Array<IState>;
}
export interface GetStatesErrorAction {
  type: "GET_STATES_ERROR";
}

export interface GetCountiesAction {
  type: "GET_COUNTIES_BEGIN";
}
export interface GetCountiesSuccessAction {
  type: "GET_COUNTIES_SUCCESS";
  data: Array<ICounty>;
}
export interface GetCountiesErrorAction {
  type: "GET_COUNTIES_ERROR";
}

export interface GetCountyParcelsAction {
  type: "GET_COUNTY_PARCELS_BEGIN";
}
export interface GetCountyParcelsSuccessAction {
  type: "GET_COUNTY_PARCELS_SUCCESS";
  data: Array<IParcel>;
}
export interface GetCountyParcelsErrorAction {
  type: "GET_COUNTY_PARCELS_ERROR";
}

export interface GetMyCountyParcelsAction {
  type: "GET_MY_COUNTY_PARCELS_BEGIN";
}
export interface GetMyCountyParcelsSuccessAction {
  type: "GET_MY_COUNTY_PARCELS_SUCCESS";
  data: Array<IParcel>;
}
export interface GetMyCountyParcelsErrorAction {
  type: "GET_MY_COUNTY_PARCELS_ERROR";
}

export interface GetMyParcelsAction {
  type: "GET_MY_PARCELS_BEGIN";
}
export interface GetMyParcelsSuccessAction {
  type: "GET_MY_PARCELS_SUCCESS";
  data: Array<IParcel>;
}
export interface GetMyParcelsErrorAction {
  type: "GET_MY_PARCELS_ERROR";
}

export interface GetMyPaymentsAction {
  type: "GET_MY_PAYMENTS_BEGIN";
}
export interface GetMyPaymentsSuccessAction {
  type: "GET_MY_PAYMENTS_SUCCESS";
  data: Array<IPayment>;
}
export interface GetMyPaymentsErrorAction {
  type: "GET_MY_PAYMENTS_ERROR";
}

export interface GetLegalParcelsAction {
  type: "GET_LEGAL_PARCELS_BEGIN";
}
export interface GetLegalParcelsSuccessAction {
  type: "GET_LEGAL_PARCELS_SUCCESS";
  data: Array<IParcel>;
}
export interface GetLegalParcelsErrorAction {
  type: "GET_LEGAL_PARCELS_ERROR";
}

export interface GetParcelAction {
  type: "GET_PARCEL_BEGIN";
  parcelId: number;
}
export interface GetParcelSuccessAction {
  type: "GET_PARCEL_SUCCESS";
  data: IParcel;
}
export interface GetParcelErrorAction {
  type: "GET_PARCEL_ERROR";
}

export interface UpdateParcelAction {
  type: "UPDATE_PARCEL_BEGIN";
  updatePArcel: IParcel;
}
export interface UpdateParcelSuccessAction {
  type: "UPDATE_PARCEL_SUCCESS";
  data: IParcel;
}
export interface UpdateParcelErrorAction {
  type: "UPDATE_PARCEL_ERROR";
}

export interface GetCountyParcelAction {
  type: "GET_COUNTY_PARCEL_BEGIN";
  parcelId: number;
}
export interface GetCountyParcelSuccessAction {
  type: "GET_COUNTY_PARCEL_SUCCESS";
  data: IParcel;
}
export interface GetCountyParcelErrorAction {
  type: "GET_COUNTY_PARCEL_ERROR";
}

export interface GetCountyParcelCorrespondenceAction {
  type: "GET_COUNTY_PARCEL_CORRESPONDENCE_BEGIN";
  parcelId: number;
}
export interface GetCountyParcelCorrespondenceSuccessAction {
  type: "GET_COUNTY_PARCEL_CORRESPONDENCE_SUCCESS";
  data: Array<ICorrespondence>;
}
export interface GetCountyParcelCorrespondenceErrorAction {
  type: "GET_COUNTY_PARCEL_CORRESPONDENCE_ERROR";
}

export interface GetPayoffRequestsAction {
  type: "GET_PAYOFF_REQUESTS_BEGIN";
  parcelId: number;
}
export interface GetPayoffRequestsSuccessAction {
  type: "GET_PAYOFF_REQUESTS_SUCCESS";
  data: Array<IPayoffRequest>;
}
export interface GetPayoffRequestsErrorAction {
  type: "GET_PAYOFF_REQUESTS_ERROR";
}

export interface GetPaymentPlansAction {
  type: "GET_PAYMENT_PLANS_BEGIN";
  parcelId: number;
}
export interface GetPaymentPlansSuccessAction {
  type: "GET_PAYMENT_PLANS_SUCCESS";
  data: Array<IPaymentPlan>;
}
export interface GetPaymentPlansErrorAction {
  type: "GET_PAYMENT_PLANS_ERROR";
}

export interface GetPayoffsAction {
  type: "GET_PAYOFFS_BEGIN";
}
export interface GetPayoffsSuccessAction {
  type: "GET_PAYOFFS_SUCCESS";
  data: Array<IPayoff>;
}
export interface GetPayoffsErrorAction {
  type: "GET_PAYOFFS_ERROR";
}

export interface GetWebPaymentLogsAction {
  type: "GET_WEB_PAYMENT_LOGS_BEGIN";
}
export interface GetWebPaymentLogsSuccessAction {
  type: "GET_WEB_PAYMENT_LOGS_SUCCESS";
  data: Array<IWebPaymentLog>;
}
export interface GetWebPaymentLogsErrorAction {
  type: "GET_WEB_PAYMENT_LOGS_ERROR";
}

export interface GetParcelImagesAction {
  type: "GET_PARCEL_IMAGES_BEGIN";
  parcelId: number;
}
export interface GetParcelImagesSuccessAction {
  type: "GET_PARCEL_IMAGES_SUCCESS";
  data: Array<string>;
}
export interface GetParcelImagesErrorAction {
  type: "GET_PARCEL_IMAGES_ERROR";
}

export interface GetParcelDocumentsAction {
  type: "GET_PARCEL_DOCUMENTS_BEGIN";
  parcelId: number;
}
export interface GetParcelDocumentsSuccessAction {
  type: "GET_PARCEL_DOCUMENTS_SUCCESS";
  data: Array<string>;
}
export interface GetParcelDocumentsErrorAction {
  type: "GET_PARCEL_DOCUMENTS_ERROR";
}

export interface GetPaymentPlansByStatusAction {
  type: "GET_PAYMENT_PLANS_BY_STATUS_BEGIN";
  countyId: number;
}
export interface GetPaymentPlansByStatusSuccessAction {
  type: "GET_PAYMENT_PLANS_BY_STATUS_SUCCESS";
  data: DonutChartData;
}
export interface GetPaymentPlansByStatusErrorAction {
  type: "GET_PAYMENT_PLANS_BY_STATUS_ERROR";
}

export interface GetCountyCorrespondenceCountsAction {
  type: "GET_COUNTY_CORRESPONDENCE_COUNTS_BEGIN";
  countyId: number;
}
export interface GetCountyCorrespondenceCountsSuccessAction {
  type: "GET_COUNTY_CORRESPONDENCE_COUNTS_SUCCESS";
  data: DonutChartData;
}
export interface GetCountyCorrespondenceCountsErrorAction {
  type: "GET_COUNTY_CORRESPONDENCE_COUNTS_ERROR";
}

export interface GetRedeemedCountsAction {
  type: "GET_REDEEMED_COUNTS_BEGIN";
  countyId: number;
}
export interface GetRedeemedCountsSuccessAction {
  type: "GET_REDEEMED_COUNTS_SUCCESS";
  data: BarChartData;
}
export interface GetRedeemedCountsErrorAction {
  type: "GET_REDEEMED_COUNTS_ERROR";
}

export interface GetLegalParcelAction {
  type: "GET_LEGAL_PARCEL_BEGIN";
  parcelId: number;
}
export interface GetLegalParcelSuccessAction {
  type: "GET_LEGAL_PARCEL_SUCCESS";
  data: IParcel;
}
export interface GetLegalParcelErrorAction {
  type: "GET_LEGAL_PARCEL_ERROR";
}

export interface GetPaymentPlansReportAction {
  type: "GET_PAYMENT_PLANS_REPORT_BEGIN";
  countyId: number;
}
export interface GetPaymentPlansReportSuccessAction {
  type: "GET_PAYMENT_PLANS_REPORT_SUCCESS";
  data: Array<any>;
}
export interface GetPaymentPlansReportErrorAction {
  type: "GET_PAYMENT_PLANS_REPORT_ERROR";
}

export interface GetExpensesByCertificateReportAction {
  type: "GET_EXPENSES_BY_CERTIFICATE_REPORT_BEGIN";
  countyId: number;
}
export interface GetExpensesByCertificateReportSuccessAction {
  type: "GET_EXPENSES_BY_CERTIFICATE_REPORT_SUCCESS";
  data: Array<any>;
}
export interface GetExpensesByCertificateReportErrorAction {
  type: "GET_EXPENSES_BY_CERTIFICATE_REPORT_ERROR";
}

export interface SearchParcelsAction {
  type: "SEARCH_PARCELS_BEGIN";
  search: IParcelSearch;
}
export interface SearchParcelsSuccessAction {
  type: "SEARCH_PARCELS_SUCCESS";
  data: Array<IParcel>;
}
export interface SearchParcelsErrorAction {
  type: "SEARCH_PARCELS_ERROR";
}
export interface ClearSavedACHAction {
  type: "CLEAR_SAVED_ACH_BEGIN";
  id: number;
}
export interface ClearSavedACHSuccessAction {
  type: "CLEAR_SAVED_ACH_SUCCESS";
}
export interface LogParcelPayoffViewAction {
  type: "LOG_PARCEL_PAYOFF_VIEW_BEGIN";
  id: number;
}
export interface LogParcelPayoffViewSuccessAction {
  type: "LOG_PARCEL_PAYOFF_VIEW_SUCCESS";
}
export interface LogParcelPayoffViewErrorAction {
  type: "LOG_PARCEL_PAYOFF_VIEW_ERROR";
}

export interface ToggleMyParcelAction {
  type: "TOGGLE_MY_PARCEL_BEGIN";
  id: number;
}
export interface ToggleMyParcelSuccessAction {
  type: "TOGGLE_MY_PARCEL_SUCCESS";
  isMyParcel: boolean;
}
export interface ToggleMyParcelErrorAction {
  type: "TOGGLE_MY_PARCEL_ERROR";
}

export interface RequestPaymentPlanAction {
  type: "REQUEST_PAYMENT_PLAN_BEGIN";
  id: number;
}
export interface RequestPaymentPlanSuccessAction {
  type: "REQUEST_PAYMENT_PLAN_SUCCESS";
}
export interface RequestPaymentPlanErrorAction {
  type: "REQUEST_PAYMENT_PLAN_ERROR";
}

export interface ClearCurrentParcel {
  type: "CLEAR_SELECTED_PARCEL";
}

export interface ClearCurrentParcels {
  type: "CLEAR_PARCEL_LIST";
}

export interface ClearCurrentPayments {
  type: "CLEAR_PAYMENTS_LIST";
}

export interface GetAttorneyUsersAction {
  type: "GET_ATTORNEY_USERS_BEGIN";
}
export interface GetAttorneyUsersSuccessAction {
  type: "GET_ATTORNEY_USERS_SUCCESS";
  data: Array<IUser>;
}

export interface GetServiceTrackingAction {
  type: "GET_SERVICE_TRACKING_BEGIN";
  parcelID: number;
}
export interface GetServiceTrackingSuccessAction {
  type: "GET_SERVICE_TRACKING_SUCCESS";
  data: Array<any>;
}
export interface GetServiceTrackingErrorAction {
  type: "GET_SERVICE_TRACKING_ERROR";
}

export interface CreateServiceTrackingAction {
  type: "CREATE_SERVICE_TRACKING_BEGIN";
  data: any;
}
export interface CreateServiceTrackingSuccessAction {
  type: "CREATE_SERVICE_TRACKING_SUCCESS";
  data: any;
}
export interface CreateServiceTrackingErrorAction {
  type: "CREATE_SERVICE_TRACKING_ERROR";
}

export interface UpdateServiceTrackingAction {
  type: "UPDATE_SERVICE_TRACKING_BEGIN";
  id: number;
}
export interface UpdateServiceTrackingSuccessAction {
  type: "UPDATE_SERVICE_TRACKING_SUCCESS";
  data: any;
}
export interface UpdateServiceTrackingErrorAction {
  type: "UPDATE_SERVICE_TRACKING_ERROR";
}

export interface DeleteServiceTrackingAction {
  type: "DELETE_SERVICE_TRACKING_BEGIN";
  defendantID: number;
}
export interface DeleteServiceTrackingSuccessAction {
  type: "DELETE_SERVICE_TRACKING_SUCCESS";
}
export interface DeleteServiceTrackingErrorAction {
  type: "DELETE_SERVICE_TRACKING_ERROR";
}

export interface ClearLegalServiceTracking {
  type: "CLEAR_LEGAL_SERVICE_TRACKING";
}

export interface GetExpenseTrackingAction {
  type: "GET_EXPENSE_TRACKING_BEGIN";
  parcelID: number;
}
export interface GetExpenseTrackingSuccessAction {
  type: "GET_EXPENSE_TRACKING_SUCCESS";
  data: any;
}
export interface GetExpenseTrackingErrorAction {
  type: "GET_EXPENSE_TRACKING_ERROR";
}

export interface CreateExpenseTrackingAction {
  type: "CREATE_EXPENSE_TRACKING_BEGIN";
  data: any;
}
export interface CreateExpenseTrackingSuccessAction {
  type: "CREATE_EXPENSE_TRACKING_SUCCESS";
  data: any;
}
export interface CreateExpenseTrackingErrorAction {
  type: "CREATE_EXPENSE_TRACKING_ERROR";
}

export interface UpdateExpenseTrackingAction {
  type: "UPDATE_EXPENSE_TRACKING_BEGIN";
  id: number;
}
export interface UpdateExpenseTrackingSuccessAction {
  type: "UPDATE_EXPENSE_TRACKING_SUCCESS";
  data: any;
}
export interface UpdateExpenseTrackingErrorAction {
  type: "UPDATE_EXPENSE_TRACKING_ERROR";
}

export interface DeleteExpenseTrackingAction {
  type: "DELETE_EXPENSE_TRACKING_BEGIN";
  defendantID: number;
}
export interface DeleteExpenseTrackingSuccessAction {
  type: "DELETE_EXPENSE_TRACKING_SUCCESS";
}
export interface DeleteExpenseTrackingErrorAction {
  type: "DELETE_EXPENSE_TRACKING_ERROR";
}

export interface ClearLegalExpenseTracking {
  type: "CLEAR_LEGAL_EXPENSE_TRACKING";
}

export interface GetCurrentUserAction {
  type: "GET_CURRENT_USER_BEGIN";
  userId: number;
}
export interface GetCurrentUserSuccessAction {
  type: "GET_CURRENT_USER_SUCCESS";
  data: IUser;
}
export interface GetCurrentUserErrorAction {
  type: "GET_CURRENT_USER_ERROR";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | GetParcelsAction
  | GetParcelsSuccessAction
  | GetParcelsErrorAction
  | GetStatesAction
  | GetStatesSuccessAction
  | GetStatesErrorAction
  | GetCountiesAction
  | GetCountiesSuccessAction
  | GetCountiesErrorAction
  | GetParcelImagesAction
  | GetParcelImagesSuccessAction
  | GetParcelImagesErrorAction
  | GetParcelDocumentsAction
  | GetParcelDocumentsSuccessAction
  | GetParcelDocumentsErrorAction
  | UpdateParcelAction
  | UpdateParcelSuccessAction
  | UpdateParcelErrorAction
  | GetCountyParcelsAction
  | GetCountyParcelsSuccessAction
  | GetCountyParcelsErrorAction
  | GetMyCountyParcelsAction
  | GetMyCountyParcelsSuccessAction
  | GetMyCountyParcelsErrorAction
  | GetMyParcelsAction
  | GetMyParcelsSuccessAction
  | GetMyParcelsErrorAction
  | GetMyPaymentsAction
  | GetMyPaymentsSuccessAction
  | GetMyPaymentsErrorAction
  | GetLegalParcelsAction
  | GetLegalParcelsSuccessAction
  | GetLegalParcelsErrorAction
  | SearchParcelsAction
  | SearchParcelsSuccessAction
  | SearchParcelsErrorAction
  | ClearSavedACHAction
  | ClearSavedACHSuccessAction
  | LogParcelPayoffViewAction
  | LogParcelPayoffViewSuccessAction
  | LogParcelPayoffViewErrorAction
  | ToggleMyParcelAction
  | ToggleMyParcelSuccessAction
  | ToggleMyParcelErrorAction
  | RequestPaymentPlanAction
  | RequestPaymentPlanSuccessAction
  | RequestPaymentPlanErrorAction
  | GetParcelAction
  | GetParcelSuccessAction
  | GetParcelErrorAction
  | GetPaymentPlansReportAction
  | GetPaymentPlansReportSuccessAction
  | GetPaymentPlansReportErrorAction
  | GetExpensesByCertificateReportAction
  | GetExpensesByCertificateReportSuccessAction
  | GetExpensesByCertificateReportErrorAction
  | GetCountyParcelAction
  | GetCountyParcelSuccessAction
  | GetCountyParcelErrorAction
  | GetCountyParcelCorrespondenceAction
  | GetCountyParcelCorrespondenceSuccessAction
  | GetCountyParcelCorrespondenceErrorAction
  | GetPaymentPlansAction
  | GetPaymentPlansSuccessAction
  | GetPaymentPlansErrorAction
  | GetPaymentPlansByStatusAction
  | GetPaymentPlansByStatusSuccessAction
  | GetPaymentPlansByStatusErrorAction
  | GetPayoffsAction
  | GetPayoffsSuccessAction
  | GetPayoffsErrorAction
  | GetWebPaymentLogsAction
  | GetWebPaymentLogsSuccessAction
  | GetWebPaymentLogsErrorAction
  | GetCountyCorrespondenceCountsAction
  | GetCountyCorrespondenceCountsSuccessAction
  | GetCountyCorrespondenceCountsErrorAction
  | GetRedeemedCountsAction
  | GetRedeemedCountsSuccessAction
  | GetRedeemedCountsErrorAction
  | GetPayoffRequestsAction
  | GetPayoffRequestsSuccessAction
  | GetPayoffRequestsErrorAction
  | GetLegalParcelAction
  | GetLegalParcelSuccessAction
  | GetLegalParcelErrorAction
  | GetServiceTrackingAction
  | GetServiceTrackingSuccessAction
  | GetServiceTrackingErrorAction
  | CreateServiceTrackingAction
  | CreateServiceTrackingSuccessAction
  | CreateServiceTrackingErrorAction
  | UpdateServiceTrackingAction
  | UpdateServiceTrackingSuccessAction
  | UpdateServiceTrackingErrorAction
  | DeleteServiceTrackingAction
  | DeleteServiceTrackingSuccessAction
  | DeleteServiceTrackingErrorAction
  | ClearLegalServiceTracking
  | GetExpenseTrackingAction
  | GetExpenseTrackingSuccessAction
  | GetExpenseTrackingErrorAction
  | CreateExpenseTrackingAction
  | CreateExpenseTrackingSuccessAction
  | CreateExpenseTrackingErrorAction
  | UpdateExpenseTrackingAction
  | UpdateExpenseTrackingSuccessAction
  | UpdateExpenseTrackingErrorAction
  | DeleteExpenseTrackingAction
  | DeleteExpenseTrackingSuccessAction
  | DeleteExpenseTrackingErrorAction
  | ClearLegalExpenseTracking
  | ClearCurrentParcel
  | ClearCurrentParcels
  | ClearCurrentPayments
  | RouterAction
  | GetAttorneyUsersAction
  | GetAttorneyUsersSuccessAction
  | GetCurrentUserAction
  | GetCurrentUserSuccessAction
  | GetCurrentUserErrorAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  getParcels: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      fetch(`api/parcels`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createParcel)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<IParcel>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_PARCELS_SUCCESS", data });
        });

      dispatch({ type: "GET_PARCELS_BEGIN" });
    }
  },
  getParcelsSuccess: (data: any) => ({ type: "GET_PARCELS_SUCCESS", data: data } as GetParcelsSuccessAction),
  getStates: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      fetch(`api/parcels/states`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createParcel)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<IState>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_STATES_SUCCESS", data });
        });

      dispatch({ type: "GET_STATES_BEGIN" });
    }
  },
  getStatesSuccess: (data: any) => ({ type: "GET_STATES_SUCCESS", data: data } as GetStatesSuccessAction),
  getCounties: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      fetch(`api/parcels/counties`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createParcel)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<ICounty>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_COUNTIES_SUCCESS", data });
        });

      dispatch({ type: "GET_COUNTIES_BEGIN" });
    }
  },
  getCountiesSuccess: (data: any) => ({ type: "GET_COUNTIES_SUCCESS", data: data } as GetCountiesSuccessAction),
  getCountyParcels:
    (countyId: number, onlyUnassigned: boolean): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/counties/${countyId}/parcels?onlyUnassigned=${onlyUnassigned}`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createParcel)
        })
          .then((response) => {
            var data = response.json() as Promise<Array<IParcel>>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_COUNTY_PARCELS_SUCCESS", data });
          });

        dispatch({ type: "GET_COUNTY_PARCELS_BEGIN" });
      }
    },
  getCountyParcelsSuccess: (data: any) => ({ type: "GET_COUNTY_PARCELS_SUCCESS", data: data } as GetCountyParcelsSuccessAction),
  getMyCountyParcels:
    (countyId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/counties/${countyId}/myparcels`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createParcel)
        })
          .then((response) => {
            var data = response.json() as Promise<Array<IParcel>>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_MY_COUNTY_PARCELS_SUCCESS", data });
          });

        dispatch({ type: "GET_MY_COUNTY_PARCELS_BEGIN" });
      }
    },
  getMyCountyParcelsSuccess: (data: any) => ({ type: "GET_MY_COUNTY_PARCELS_SUCCESS", data: data } as GetMyCountyParcelsSuccessAction),
  getMyParcels: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      fetch(`api/parcels/myparcels`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          var data = response.json() as Promise<Array<IParcel>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_MY_PARCELS_SUCCESS", data });
        });

      dispatch({ type: "GET_MY_PARCELS_BEGIN" });
    }
  },
  getMyParcelsSuccess: (data: any) => ({ type: "GET_MY_PARCELS_SUCCESS", data: data } as GetMyParcelsSuccessAction),
  getMyPayments: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      fetch(`api/parcels/mypayments`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          var data = response.json() as Promise<Array<IPayment>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_MY_PAYMENTS_SUCCESS", data });
        });

      dispatch({ type: "GET_MY_PAYMENTS_BEGIN" });
    }
  },
  getMyPaymentsSuccess: (data: any) => ({ type: "GET_MY_PAYMENTS_SUCCESS", data: data } as GetMyPaymentsSuccessAction),
  getLegalParcels: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      fetch(`api/parcels/legal`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createParcel)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<IParcel>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_LEGAL_PARCELS_SUCCESS", data });
        });

      dispatch({ type: "GET_LEGAL_PARCELS_BEGIN" });
    }
  },
  getLegalParcelsSuccess: (data: any) => ({ type: "GET_LEGAL_PARCELS_SUCCESS", data: data } as GetLegalParcelsSuccessAction),
  searchParcels:
    (search: IParcelSearch, history: History): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/parcels/search`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(search),
        })
          .then((response) => {
            var data = response.json() as Promise<Array<IParcel>>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "SEARCH_PARCELS_SUCCESS", data });
            history.push("/parcels/list");
          });

        dispatch({ type: "SEARCH_PARCELS_BEGIN", search });
      }
    },
  searchParcelsSuccess: (data: any) => ({ type: "SEARCH_PARCELS_SUCCESS", data: data } as SearchParcelsSuccessAction),
  logPayoffView:
    (id: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/parcels/${id}/payofflog`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(search),
        });
        // .then((response) => {
        //   var data = response.json() as Promise<Array<IParcel>>;
        //   return data;
        // })
        // .then((data) => {
        //   dispatch({ type: "LOG_PARCEL_PAYOFF_VIEW_SUCCESS", data });
        // });

        dispatch({ type: "LOG_PARCEL_PAYOFF_VIEW_BEGIN", id });
      }
    },
  logPayoffViewSuccess: (data: any) => ({ type: "LOG_PARCEL_PAYOFF_VIEW_SUCCESS", data: data } as LogParcelPayoffViewSuccessAction),
  clearSavedACH:
    (id: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/payments/ClearSavedACH/${id}`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(search),
        });
        // .then((response) => {
        //   var data = response.json() as Promise<Array<IParcel>>;
        //   return data;
        // })
        // .then((data) => {
        //   dispatch({ type: "LOG_PARCEL_PAYOFF_VIEW_SUCCESS", data });
        // });

        dispatch({ type: "CLEAR_SAVED_ACH_BEGIN", id });
      }
    },
  clearSavedACHSuccess: (data: any) => ({ type: "CLEAR_SAVED_ACH_SUCCESS", data: data } as ClearSavedACHSuccessAction),
  toggleMyParcel:
    (id: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/parcels/${id}/togglemyparcel`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(search),
        })
          .then((response) => {
            var data = response.json() as Promise<boolean>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "TOGGLE_MY_PARCEL_SUCCESS", isMyParcel: data });
          });

        dispatch({ type: "TOGGLE_MY_PARCEL_BEGIN", id });
      }
    },
  toggleMyParcelSuccess: (data: any) => ({ type: "TOGGLE_MY_PARCEL_SUCCESS", isMyParcel: data } as ToggleMyParcelSuccessAction),

  requestPaymentPlan:
    (id: number, comment: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/parcels/${id}/requestpaymentplan`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(comment),
        });
        // .then((response) => {
        //   var data = response.json() as Promise<Array<IParcel>>;
        //   return data;
        // })
        // .then((data) => {
        //   dispatch({ type: "REQUEST_PAYMENT_PLAN_SUCCESS", data });
        // });

        dispatch({ type: "REQUEST_PAYMENT_PLAN_BEGIN", id });
      }
    },
  requestPaymentPlanSuccess: (data: any) => ({ type: "REQUEST_PAYMENT_PLAN_SUCCESS", data: data } as RequestPaymentPlanSuccessAction),
  getParcel:
    (parcelId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/parcels/${parcelId}`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createParcel)
        })
          .then((response) => {
            var data = response.json() as Promise<IParcel>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_PARCEL_SUCCESS", data });
          });

        dispatch({ type: "GET_PARCEL_BEGIN", parcelId });
      }
    },
  getParcelSuccess: (data: any) => ({ type: "GET_PARCEL_SUCCESS", data: data } as GetParcelSuccessAction),
  getCurrentUser:
    (userId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/users/${userId}`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createUser)
        })
          .then((response) => {
            var data = response.json() as Promise<IUser>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_CURRENT_USER_SUCCESS", data });
          });

        dispatch({ type: "GET_CURRENT_USER_BEGIN", userId });
      }
    },
  getUserSuccess: (data: any) => ({ type: "GET_CURRENT_USER_SUCCESS", data: data } as GetCurrentUserSuccessAction),
  getParcelImages:
    (parcelId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/documents/parcels/${parcelId}/images`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createParcelImages)
        })
          .then((response) => {
            var data = response.json() as Promise<Array<string>>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_PARCEL_IMAGES_SUCCESS", data });
          });

        dispatch({ type: "GET_PARCEL_IMAGES_BEGIN", parcelId });
      }
    },
  getParcelImagesSuccess: (data: any) => ({ type: "GET_PARCEL_IMAGES_SUCCESS", data: data } as GetParcelImagesSuccessAction),
  getParcelDocuments:
    (parcelId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/documents/parcels/${parcelId}/documents`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createParcelDocuments)
        })
          .then((response) => {
            var data = response.json() as Promise<Array<string>>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_PARCEL_DOCUMENTS_SUCCESS", data });
          });

        dispatch({ type: "GET_PARCEL_DOCUMENTS_BEGIN", parcelId });
      }
    },
  getParcelDocumentsSuccess: (data: any) => ({ type: "GET_PARCEL_DOCUMENTS_SUCCESS", data: data } as GetParcelDocumentsSuccessAction),
  getCountyParcel:
    (parcelId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/parcels/${parcelId}/county`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createParcel)
        })
          .then((response) => {
            var data = response.json() as Promise<IParcel>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_COUNTY_PARCEL_SUCCESS", data });
          });

        dispatch({ type: "GET_COUNTY_PARCEL_BEGIN", parcelId });
      }
    },
  getCountyParcelSuccess: (data: any) => ({ type: "GET_COUNTY_PARCEL_SUCCESS", data: data } as GetCountyParcelSuccessAction),
  getCountyParcelCorrespondence:
    (parcelId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/parcels/${parcelId}/county/correspondence`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createParcel)
        })
          .then((response) => {
            var data = response.json() as Promise<Array<ICorrespondence>>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_COUNTY_PARCEL_CORRESPONDENCE_SUCCESS", data });
          });

        dispatch({ type: "GET_COUNTY_PARCEL_CORRESPONDENCE_BEGIN", parcelId });
      }
    },
  getCountyParcelCorrespondenceSuccess: (data: any) =>
    ({ type: "GET_COUNTY_PARCEL_CORRESPONDENCE_SUCCESS", data: data } as GetCountyParcelCorrespondenceSuccessAction),
  getPayoffRequests:
    (parcelId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/parcels/${parcelId}/payoffrequests`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createParcel)
        })
          .then((response) => {
            var data = response.json() as Promise<Array<IPayoffRequest>>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_PAYOFF_REQUESTS_SUCCESS", data });
          });

        dispatch({ type: "GET_PAYOFF_REQUESTS_BEGIN", parcelId });
      }
    },
  getPayoffRequestsSuccess: (data: any) => ({ type: "GET_PAYOFF_REQUESTS_SUCCESS", data: data } as GetPayoffRequestsSuccessAction),
  getPaymentPlans:
    (parcelId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/parcels/${parcelId}/paymentplans`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createParcel)
        })
          .then((response) => {
            var data = response.json() as Promise<Array<IPaymentPlan>>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_PAYMENT_PLANS_SUCCESS", data });
          });

        dispatch({ type: "GET_PAYMENT_PLANS_BEGIN", parcelId });
      }
    },
  getPaymentPlansSuccess: (data: any) => ({ type: "GET_PAYMENT_PLANS_SUCCESS", data: data } as GetPaymentPlansSuccessAction),
  getPaymentPlansByStatus:
    (countyId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/counties/${countyId}/payplansbystatus`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createParcel)
        })
          .then((response) => {
            var data = response.json() as Promise<DonutChartData>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_PAYMENT_PLANS_BY_STATUS_SUCCESS", data });
          });

        dispatch({ type: "GET_PAYMENT_PLANS_BY_STATUS_BEGIN", countyId });
      }
    },
  getPaymentPlansByStatusSuccess: (data: any) => ({ type: "GET_PAYMENT_PLANS_BY_STATUS_SUCCESS", data: data } as GetPaymentPlansByStatusSuccessAction),
  getPayoffs:
    (): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/parcels/payoffs`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createParcel)
        })
          .then((response) => {
            var data = response.json() as Promise<Array<IPayment>>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_PAYOFFS_SUCCESS", data });
          });
      }
    },
  getPayoffsSuccess: (data: any) => ({ type: "GET_PAYOFFS_SUCCESS", data: data } as GetPayoffsSuccessAction),
  getWebPaymentLogs:
    (): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/payments/GetWebPaymentLogs`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createParcel)
        })
          .then((response) => {
            var data = response.json() as Promise<Array<IWebPaymentLog>>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_WEB_PAYMENT_LOGS_SUCCESS", data });
          });
      }
    },
  getWebPaymentLogsSuccess: (data: any) => ({ type: "GET_WEB_PAYMENT_LOGS_SUCCESS", data: data } as GetWebPaymentLogsSuccessAction),
  getCountyCorrespondenceCounts:
    (countyId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/counties/${countyId}/countycorrespondencecounts`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createParcel)
        })
          .then((response) => {
            var data = response.json() as Promise<DonutChartData>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_COUNTY_CORRESPONDENCE_COUNTS_SUCCESS", data });
          });

        dispatch({ type: "GET_COUNTY_CORRESPONDENCE_COUNTS_BEGIN", countyId });
      }
    },
  getCountyCorrespondenceCountsSuccess: (data: any) =>
    ({ type: "GET_COUNTY_CORRESPONDENCE_COUNTS_SUCCESS", data: data } as GetCountyCorrespondenceCountsSuccessAction),
  getRedeemedCounts:
    (countyId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/counties/${countyId}/redeemedcounts`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createParcel)
        })
          .then((response) => {
            var data = response.json() as Promise<BarChartData>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_REDEEMED_COUNTS_SUCCESS", data });
          });

        dispatch({ type: "GET_REDEEMED_COUNTS_BEGIN", countyId });
      }
    },
  getRedeemedCountsSuccess: (data: any) => ({ type: "GET_REDEEMED_COUNTS_SUCCESS", data: data } as GetRedeemedCountsSuccessAction),
  getPaymentPlansReport:
    (countyId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/counties/${countyId}/paymentplansreport`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createParcel)
        })
          .then((response) => {
            var data = response.json() as Promise<Array<any>>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_PAYMENT_PLANS_REPORT_SUCCESS", data });
          });

        dispatch({ type: "GET_PAYMENT_PLANS_REPORT_BEGIN", countyId });
      }
    },
  getPaymentPlansReportSuccess: (data: any) => ({ type: "GET_PAYMENT_PLANS_REPORT_SUCCESS", data: data } as GetPaymentPlansReportSuccessAction),
  getExpensesByCertificateReport:
    (countyId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/counties/${countyId}/expensesbycertificate`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createParcel)
        })
          .then((response) => {
            var data = response.json() as Promise<Array<any>>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_EXPENSES_BY_CERTIFICATE_REPORT_SUCCESS", data });
          });

        dispatch({ type: "GET_EXPENSES_BY_CERTIFICATE_REPORT_BEGIN", countyId });
      }
    },
  getExpensesByCertificateReportSuccess: (data: any) =>
    ({ type: "GET_EXPENSES_BY_CERTIFICATE_REPORT_SUCCESS", data: data } as GetExpensesByCertificateReportSuccessAction),
  getLegalParcel:
    (parcelId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/parcels/${parcelId}/legal`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createParcel)
        })
          .then((response) => {
            var data = response.json() as Promise<IParcel>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_COUNTY_PARCEL_SUCCESS", data });
          });

        dispatch({ type: "GET_COUNTY_PARCEL_BEGIN", parcelId });
      }
    },
  getLegalParcelSuccess: (data: any) => ({ type: "GET_COUNTY_PARCEL_SUCCESS", data: data } as GetCountyParcelSuccessAction), //TODO - is this wrong?
  clearCurrentParcel: () => ({ type: "CLEAR_SELECTED_PARCEL" } as ClearCurrentParcel),
  clearCurrentParcels: () => ({ type: "CLEAR_PARCEL_LIST" } as ClearCurrentParcels),
  clearCurrentPayments: () => ({ type: "CLEAR_PAYMENTS_LIST" } as ClearCurrentPayments),
  getAttorneyUsers:
    (attorneyID: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/attorneys/${attorneyID}/users`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createUser)
        })
          .then((response) => {
            var data = response.json() as Promise<Array<IUser>>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_ATTORNEY_USERS_SUCCESS", data });
          });

        //dispatch({ type: "GET_ATTORNEY_USERS_BEGIN" });
      }
    },
  getAttorneyUsersSuccess: (data: any) => ({ type: "GET_ATTORNEY_USERS_SUCCESS", data: data } as GetAttorneyUsersSuccessAction),
  updateParcelSuccess: () => ({ type: "UPDATE_PARCEL_SUCCESS" } as UpdateParcelSuccessAction),
  updateParcelError: () => ({ type: "UPDATE_PARCEL_ERROR" } as UpdateParcelErrorAction),
  updateParcel:
    (updateParcel: IParcel): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/parcels`, {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(updateParcel),
        })
          .then((response) => {
            var data = response.json() as Promise<IParcel>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "UPDATE_PARCEL_SUCCESS", data });
          });

        //dispatch({ type: "UPDATE_PARCEL_BEGIN", updateParcel });
      }
    },

  createServiceTrackingSuccess: () => ({ type: "CREATE_SERVICE_TRACKING_SUCCESS" } as CreateServiceTrackingSuccessAction),
  createServiceTrackingError: () => ({ type: "CREATE_SERVICE_TRACKING_ERROR" } as CreateServiceTrackingErrorAction),
  createServiceTracking:
    (createServiceTracking: any): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not al12223ready loading)
      const appState = getState();
      if (appState) {
        fetch(`api/legal/${createServiceTracking.parcelID}/servicetracking`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(createServiceTracking),
        })
          .then((response) => {
            var data = response.json() as Promise<IServiceTracking>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "CREATE_SERVICE_TRACKING_SUCCESS", data });
          });

        //dispatch({ type: "CREATE_SERVICE_ITEM_BEGIN", createServiceTracking });
      }
    },

  updateServiceTrackingSuccess: () => ({ type: "UPDATE_SERVICE_TRACKING_SUCCESS" } as UpdateServiceTrackingSuccessAction),
  updateServiceTrackingError: () => ({ type: "UPDATE_SERVICE_TRACKING_ERROR" } as UpdateServiceTrackingErrorAction),
  updateServiceTracking:
    (updateServiceTracking: any): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not al12223ready loading)
      const appState = getState();
      if (appState) {
        fetch(`api/legal/${updateServiceTracking.parcelID}/servicetracking`, {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(updateServiceTracking),
        })
          .then((response) => {
            var data = response.json() as Promise<any>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "UPDATE_SERVICE_TRACKING_SUCCESS", data });
          });

        //dispatch({ type: "UPDATE_SERVICE_ITEM_BEGIN", updateServiceTracking });
      }
    },

  getServiceTrackingSuccess: () => ({ type: "GET_SERVICE_TRACKING_SUCCESS" } as GetServiceTrackingSuccessAction),
  getServiceTrackingError: () => ({ type: "GET_SERVICE_TRACKING_ERROR" } as GetServiceTrackingErrorAction),
  getServiceTracking:
    (parcelID: any): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not al12223ready loading)
      const appState = getState();
      if (appState) {
        fetch(`api/legal/${parcelID}/servicetracking`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(getServiceTracking),
        })
          .then((response) => {
            var data = response.json() as Promise<any>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_SERVICE_TRACKING_SUCCESS", data });
          });

        //dispatch({ type: "GET_SERVICE_ITEM_BEGIN", getServiceTracking });
      }
    },
  deleteServiceTrackingSuccess: () => ({ type: "DELETE_SERVICE_TRACKING_SUCCESS" } as DeleteServiceTrackingSuccessAction),
  deleteServiceTrackingError: () => ({ type: "DELETE_SERVICE_TRACKING_ERROR" } as DeleteServiceTrackingErrorAction),
  deleteServiceTracking:
    (defendantID: any): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not al12223ready loading)
      const appState = getState();
      if (appState) {
        fetch(`api/legal/servicetracking/${defendantID}`, {
          method: "delete",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(getServiceTracking),
        }).then((response) => {
          dispatch({ type: "DELETE_SERVICE_TRACKING_SUCCESS" });
        });

        //dispatch({ type: "DELETE_SERVICE_ITEM_BEGIN", getServiceTracking });
      }
    },
  clearLegalServiceTracking: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: "CLEAR_LEGAL_SERVICE_TRACKING" });
  },

  createExpenseTrackingSuccess: () => ({ type: "CREATE_EXPENSE_TRACKING_SUCCESS" } as CreateExpenseTrackingSuccessAction),
  createExpenseTrackingError: () => ({ type: "CREATE_EXPENSE_TRACKING_ERROR" } as CreateExpenseTrackingErrorAction),
  createExpenseTracking:
    (createExpenseTracking: any): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not al12223ready loading)
      const appState = getState();
      if (appState) {
        fetch(`api/legal/${createExpenseTracking.parcelID}/expensetracking`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(createExpenseTracking),
        })
          .then((response) => {
            var data = response.json() as Promise<IExpenseTracking>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "CREATE_EXPENSE_TRACKING_SUCCESS", data });
          });

        //dispatch({ type: "CREATE_EXPENSE_ITEM_BEGIN", createExpenseTracking });
      }
    },

  updateExpenseTrackingSuccess: () => ({ type: "UPDATE_EXPENSE_TRACKING_SUCCESS" } as UpdateExpenseTrackingSuccessAction),
  updateExpenseTrackingError: () => ({ type: "UPDATE_EXPENSE_TRACKING_ERROR" } as UpdateExpenseTrackingErrorAction),
  updateExpenseTracking:
    (updateExpenseTracking: any): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not al12223ready loading)
      const appState = getState();
      if (appState) {
        fetch(`api/legal/${updateExpenseTracking.parcelID}/expensetracking`, {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(updateExpenseTracking),
        })
          .then((response) => {
            var data = response.json() as Promise<any>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "UPDATE_EXPENSE_TRACKING_SUCCESS", data });
          });

        //dispatch({ type: "UPDATE_EXPENSE_ITEM_BEGIN", updateExpenseTracking });
      }
    },

  getExpenseTrackingSuccess: () => ({ type: "GET_EXPENSE_TRACKING_SUCCESS" } as GetExpenseTrackingSuccessAction),
  getExpenseTrackingError: () => ({ type: "GET_EXPENSE_TRACKING_ERROR" } as GetExpenseTrackingErrorAction),
  getExpenseTracking:
    (parcelID: any): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not al12223ready loading)
      const appState = getState();
      if (appState) {
        fetch(`api/legal/${parcelID}/expensetracking`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(getExpenseTracking),
        })
          .then((response) => {
            var data = response.json() as Promise<any>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_EXPENSE_TRACKING_SUCCESS", data });
          });

        //dispatch({ type: "GET_EXPENSE_ITEM_BEGIN", getExpenseTracking });
      }
    },
  deleteExpenseTrackingSuccess: () => ({ type: "DELETE_EXPENSE_TRACKING_SUCCESS" } as DeleteExpenseTrackingSuccessAction),
  deleteExpenseTrackingError: () => ({ type: "DELETE_EXPENSE_TRACKING_ERROR" } as DeleteExpenseTrackingErrorAction),
  deleteExpenseTracking:
    (defendantID: any): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not al12223ready loading)
      const appState = getState();
      if (appState) {
        fetch(`api/legal/expensetracking/${defendantID}`, {
          method: "delete",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(getExpenseTracking),
        }).then((response) => {
          dispatch({ type: "DELETE_EXPENSE_TRACKING_SUCCESS" });
        });

        //dispatch({ type: "DELETE_EXPENSE_ITEM_BEGIN", getExpenseTracking });
      }
    },
  clearLegalExpenseTracking: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: "CLEAR_LEGAL_EXPENSE_TRACKING" });
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<ParcelState> = (state: ParcelState | undefined, incomingAction: Action): ParcelState => {
  if (state === undefined) {
    return {
      selectedParcel: undefined,
      parcelList: [],
      paymentList: [],
      countyParcels: [],
      legalParcels: [],
      legalServiceTracking: [],
      selectedCountyParcelCorrespondenceList: [],
      selectedCountyParcelPaymentPlanList: [],
      selectedCountyParcelPayoffRequestList: [],
      states: [],
      counties: [],
      currentUser: {} as IUser,
      paymentPlansByStatus: {} as DonutChartData,
      countyCorrespondenceCounts: {} as DonutChartData,
      redeemedCounts: {} as BarChartData,
      attorneyUsers: [],
      paymentPlansReport: [],
      expensesByCertificateReport: [],
      selectedParcelImages: [],
      selectedParcelDocuments: [],
      legalServiceTrackingUpdated: false,
      legalExpenseTracking: [],
      legalPostedExpenseTracking: [],
      legalExpenseTrackingUpdated: false,
      isSearching: false,
      payoffList: [],
      webPaymentLogs: []
    };
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "GET_PARCELS_SUCCESS":
      return {
        ...state,
        parcelList: (action as GetParcelsSuccessAction).data,
      };
    case "SEARCH_PARCELS_BEGIN":
      return {
        ...state,
        isSearching: true,
      };
    case "GET_STATES_SUCCESS":
      return {
        ...state,
        states: (action as GetStatesSuccessAction).data,
      };
    case "GET_COUNTIES_SUCCESS":
      return {
        ...state,
        counties: (action as GetCountiesSuccessAction).data,
      };
    case "GET_PAYMENT_PLANS_REPORT_SUCCESS":
      return {
        ...state,
        paymentPlansReport: (action as GetPaymentPlansReportSuccessAction).data,
      };
    case "GET_EXPENSES_BY_CERTIFICATE_REPORT_SUCCESS":
      return {
        ...state,
        expensesByCertificateReport: (action as GetExpensesByCertificateReportSuccessAction).data,
      };
    case "SEARCH_PARCELS_SUCCESS":
      return {
        ...state,
        parcelList: (action as SearchParcelsSuccessAction).data,
        isSearching: false,
      };
    case "GET_COUNTY_PARCELS_SUCCESS":
      return {
        ...state,
        countyParcels: (action as GetCountyParcelsSuccessAction).data,
        parcelList: (action as GetCountyParcelsSuccessAction).data,
      };
    case "GET_MY_COUNTY_PARCELS_SUCCESS":
      return {
        ...state,
        countyParcels: (action as GetMyCountyParcelsSuccessAction).data,
        parcelList: (action as GetMyCountyParcelsSuccessAction).data,
      };
    case "GET_MY_PARCELS_SUCCESS":
      return {
        ...state,
        parcelList: (action as GetMyParcelsSuccessAction).data,
      };
    case "GET_MY_PAYMENTS_SUCCESS":
      return {
        ...state,
        paymentList: (action as GetMyPaymentsSuccessAction).data,
      };
    case "UPDATE_PARCEL_SUCCESS":
      return {
        ...state,
        selectedParcel: (action as UpdateParcelSuccessAction).data,
      };
    case "GET_COUNTY_PARCEL_SUCCESS":
      return {
        ...state,
        selectedParcel: (action as GetCountyParcelSuccessAction).data,
      };
    case "GET_PARCEL_IMAGES_SUCCESS":
      return {
        ...state,
        selectedParcelImages: (action as GetParcelImagesSuccessAction).data,
      };
    case "GET_PARCEL_DOCUMENTS_SUCCESS":
      return {
        ...state,
        selectedParcelDocuments: (action as GetParcelDocumentsSuccessAction).data,
      };
    case "TOGGLE_MY_PARCEL_SUCCESS":
      return {
        ...state,
        selectedParcel: { ...(state.selectedParcel as IParcel), isMyParcel: (action as ToggleMyParcelSuccessAction).isMyParcel },
      };
    case "GET_COUNTY_PARCEL_CORRESPONDENCE_SUCCESS":
      return {
        ...state,
        selectedCountyParcelCorrespondenceList: (action as GetCountyParcelCorrespondenceSuccessAction).data,
      };
    case "GET_PAYOFF_REQUESTS_SUCCESS":
      return {
        ...state,
        selectedCountyParcelPayoffRequestList: (action as GetPayoffRequestsSuccessAction).data,
      };
    case "GET_PAYMENT_PLANS_SUCCESS":
      return {
        ...state,
        selectedCountyParcelPaymentPlanList: (action as GetPaymentPlansSuccessAction).data,
      };
    case "GET_PAYOFFS_SUCCESS":
      return {
        ...state,
        payoffList: (action as GetPayoffsSuccessAction).data,
      };
    case "GET_WEB_PAYMENT_LOGS_SUCCESS":
      return {
        ...state,
        webPaymentLogs: (action as GetWebPaymentLogsSuccessAction).data,
      };
    case "GET_PAYMENT_PLANS_BY_STATUS_SUCCESS":
      return {
        ...state,
        paymentPlansByStatus: (action as GetPaymentPlansByStatusSuccessAction).data,
      };
    case "GET_COUNTY_CORRESPONDENCE_COUNTS_SUCCESS":
      return {
        ...state,
        countyCorrespondenceCounts: (action as GetCountyCorrespondenceCountsSuccessAction).data,
      };
    case "GET_REDEEMED_COUNTS_SUCCESS":
      return {
        ...state,
        redeemedCounts: (action as GetRedeemedCountsSuccessAction).data,
      };
    case "GET_PARCEL_SUCCESS":
      var localState = {
        ...state,
        selectedParcel: (action as GetParcelSuccessAction).data,
        // currentUser: {
        //   username: localStorage.getItem("token") != null ? (jwt_decode(localStorage.getItem("token") as string) as any).unique_name : "",
        //   accountType: localStorage.getItem("token") != null ? (jwt_decode(localStorage.getItem("token") as string) as any).AccountType : "",
        //   id: localStorage.getItem("token") != null ? (jwt_decode(localStorage.getItem("token") as string) as any).nameid : "",
        //   email: localStorage.getItem("token") != null ? (jwt_decode(localStorage.getItem("token") as string) as any).Email : "",
        //   hasStripeVerifiedACH: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).HasStripeVerifiedACH : "",
        //   stripeCustomerId: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).StripeCustomerId : "",
        // } as IUser,
      };
      return localState;
    case "CLEAR_SELECTED_PARCEL":
      return {
        ...state,
        selectedParcel: undefined,
      };
    case "CLEAR_LEGAL_SERVICE_TRACKING":
      return {
        ...state,
        legalServiceTrackingUpdated: false,
      };
    case "DELETE_SERVICE_TRACKING_SUCCESS":
      return {
        ...state,
        legalServiceTrackingUpdated: true,
      };
    case "GET_CURRENT_USER_SUCCESS":
      return {
        ...state,
        currentUser: (action as GetCurrentUserSuccessAction).data,
      };
    case "GET_SERVICE_TRACKING_SUCCESS":
      return {
        ...state,
        legalServiceTracking: (action as GetServiceTrackingSuccessAction).data,
      };
    case "CREATE_SERVICE_TRACKING_SUCCESS":
      return {
        ...state,
        legalServiceTrackingUpdated: true,
      };
    case "CLEAR_PARCEL_LIST":
      return {
        ...state,
        parcelList: [],
      };
    case "GET_ATTORNEY_USERS_SUCCESS":
      return {
        ...state,
        attorneyUsers: (action as GetAttorneyUsersSuccessAction).data,
      };
    case "CLEAR_LEGAL_EXPENSE_TRACKING":
      return {
        ...state,
        legalExpenseTrackingUpdated: false,
      };
    case "DELETE_EXPENSE_TRACKING_SUCCESS":
      return {
        ...state,
        legalExpenseTrackingUpdated: true,
      };
    case "GET_EXPENSE_TRACKING_SUCCESS":
      return {
        ...state,
        legalExpenseTracking: (action as GetExpenseTrackingSuccessAction).data.unpostedExpenses,
        legalPostedExpenseTracking: (action as GetExpenseTrackingSuccessAction).data.postedExpenses,
      };
    case "CREATE_EXPENSE_TRACKING_SUCCESS":
      return {
        ...state,
        legalExpenseTrackingUpdated: true,
      };
    default:
      return state;
      2;
  }
};
