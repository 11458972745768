import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { AUTH_ROUTES } from "./auth.routing.constants";
import signup from "../../pages/signup/signup";
import login from "../../pages/login/login";
import confirmation from "../../pages/signup/confirmation";
import signupCounty from "../../pages/signup/signupCounty";
import signupTaxPayer from "../../pages/signup/signupTaxPayer";
import signupAdmin from "../../pages/signup/signupAdmin";
import confirmationTaxPayer from "../../pages/signup/confirmationTaxPayer";
import forgotPassword from "../../pages/login/forgotPassword";
import forgotPasswordConfirmation from "../../pages/login/forgotPasswordConfirmation";
import resetPassword from "../../pages/login/resetPassword";
import resetPasswordConfirmation from "../../pages/login/resetPasswordConfirmation";
import Home from "../../components/Home";
import about from "../../pages/content/about";
import faq from "../../pages/content/faq";
import help from "../../pages/content/help";
import privacy from "../../pages/content/privacy";
import parcelList from "../../pages/taxpayer/parcel/list/parcelList";
import parcelDetails from "../../pages/taxpayer/parcel/details/parcelDetails";
import parcelDetailsPaymentPlanRequest from "../../pages/taxpayer/parcel/details/parcelDetailsPaymentPlanRequest";
import parcelDetailsPayoffRequest from "../../pages/taxpayer/parcel/details/parcelDetailsPayoffRequest";
import contact from "../../pages/content/contact";
import parcelDetailsPaymentPlanRequestSuccess from "../../pages/taxpayer/parcel/details/parcelDetailsPaymentPlanRequestSuccess";
import disclaimer from "../../pages/content/disclaimer";
import parcelDetailsPayoffRequestPrint from "../../pages/taxpayer/parcel/details/parcelDetailsPayoffRequestPrint";
import paymentProcessSuccess from "../../pages/taxpayer/parcel/paymentProcessing/paymentProcessSuccess";
import paymentProcessCancel from "../../pages/taxpayer/parcel/paymentProcessing/paymentProcessCancel";

export const AuthRouting: FC = () => (
  <Switch>
    <Route path={AUTH_ROUTES.LOGIN} component={login} />
    <Route path={AUTH_ROUTES.SIGNUP} component={signup} />
    <Route path={AUTH_ROUTES.SIGNUP_ADMIN} component={signupAdmin} />
    <Route path={AUTH_ROUTES.SIGNUP_COUNTY} component={signupCounty} />
    <Route path={AUTH_ROUTES.SIGNUP_TAXPAYER} component={signupTaxPayer} />
    <Route path={AUTH_ROUTES.RESET_PASSWORD} component={resetPassword} />
    <Route path={AUTH_ROUTES.RESET_PASSWORD_CONFIRMATION} component={resetPasswordConfirmation} />
    <Route path={AUTH_ROUTES.FORGOT_PASSWORD} component={forgotPassword} />
    <Route path={AUTH_ROUTES.FORGOT_PASSWORD_CONFIRMATION} component={forgotPasswordConfirmation} />
    <Route path={AUTH_ROUTES.CONFIRMATION} component={confirmation} />
    <Route path={AUTH_ROUTES.CONFIRMATION_TAXPAYER} component={confirmationTaxPayer} />
    <Route path={AUTH_ROUTES.HOME} component={Home} />
    <Route path={AUTH_ROUTES.ABOUT} component={about} />
    <Route path={AUTH_ROUTES.FAQ} component={faq} />
    <Route path={AUTH_ROUTES.DISCLAIMER} component={disclaimer} />
    <Route path={AUTH_ROUTES.HELP} component={help} />
    <Route path={AUTH_ROUTES.PRIVACY} component={privacy} />
    <Route path={AUTH_ROUTES.CONTACT} component={contact} />

    <Route path={AUTH_ROUTES.PARCEL_DETAILS_PAYOFF_REQUEST} component={parcelDetailsPayoffRequest} />
    <Route path={AUTH_ROUTES.PARCEL_DETAILS_PAYMENT_PLAN_REQUEST_PRINT} component={parcelDetailsPayoffRequestPrint} />
    <Route path={AUTH_ROUTES.PARCEL_DETAILS_PAYMENT_PLAN_REQUEST} component={parcelDetailsPaymentPlanRequest} />
    <Route path={AUTH_ROUTES.PARCEL_DETAILS_PAYMENT_PLAN_REQUEST_SUCCESS} component={parcelDetailsPaymentPlanRequestSuccess} />

    <Route path={AUTH_ROUTES.PARCEL_DETAILS_PAYMENT_PROCESS_SUCCESS} component={paymentProcessSuccess} />
    <Route path={AUTH_ROUTES.PARCEL_DETAILS_PAYMENT_PROCESS_CANCEL} component={paymentProcessCancel} />

    <Route path={AUTH_ROUTES.PARCEL_LIST} component={parcelList} />
    <Route path={AUTH_ROUTES.PARCEL_DETAILS} component={parcelDetails} />
  </Switch>
);
