import * as React from "react";
import { Container } from "reactstrap";
//import "primereact/resources/themes/saga-blue"
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Navigation from "./Navigation";
import Footer from "./footer";

export default (props: { children?: React.ReactNode }) => (
  <React.Fragment>
    <Navigation  />
    <Container>{props.children}</Container>
    <Footer />
  </React.Fragment>
);
