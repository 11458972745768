import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../store";
import * as UserStore from "../../store/user";
import { ICreateUser } from "../../models/ICreateUser";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { AccountType } from "../../models/enums/accountType";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Link } from "react-router-dom";

type ResetPasswordConfirmationProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

class ResetPasswordConfirmation extends React.PureComponent<ResetPasswordConfirmationProps, any> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  public render() {
    return (
      <React.Fragment>
        <Card title="Success!" subTitle="">
          <h2>Your password has been reset.</h2>
          <Link to={"/login"}>Click Here To Login</Link>
        </Card>
      </React.Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.user,
  UserStore.actionCreators
)(ResetPasswordConfirmation as any);
