import { IContactForm } from "./../models/IContactForm";
import { AccountType } from "./../models/enums/accountType";
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { ITokenResult } from "../models/ITokenResult";
import { ICreateUser } from "../models/ICreateUser";
import { push } from "react-router-redux";
import { RouterAction } from "connected-react-router";
import jwt_decode from "jwt-decode";
import { IUser } from "../models/IUser";
import { ICounty } from "../models/ICounty";
import { IState } from "../models/IState";
import { IAttorney } from "../models/IAttorney";
import { ICountySettings } from "../models/ICountySettings";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UserState {
  currentUser: IUser;
  selectedUser?: IUser;
  selectedCounty?: ICounty;
  selectedCountySettings?: ICountySettings;
  userList: Array<IUser>;
  username: string;
  password: string;
  isLoggedIn: boolean;
  isAdmin: boolean;
  isRequesting: boolean;
  token: string;
  createUserErrorMessage: string;
  loginErrorMessage: string;
  counties: Array<ICounty>;
  attorneys: Array<IAttorney>;
  attorneyUsers: Array<IUser>;
  states: Array<IState>;
  unassignedLegalParcelCount: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface LoginAction {
  type: "LOGIN";
}
export interface LogoutAction {
  type: "LOGOUT";
}
export interface CreateUserAction {
  type: "CREATE_USER_BEGIN";
  createUser: ICreateUser;
}
export interface CreateUserSuccessAction {
  type: "CREATE_USER_SUCCESS";
}
export interface CreateUserErrorAction {
  type: "CREATE_USER_ERROR";
}
export interface LoginUserErrorAction {
  type: "LOGIN_USER_ERROR";
}
export interface LoginNotApprovedUserErrorAction {
  type: "LOGIN_NOT_APPROVED_USER_ERROR";
}
export interface ReceiveTokenAction {
  type: "RECEIVE_TOKEN";
  token: string;
}
export interface RequestTokenAction {
  type: "REQUEST_TOKEN";
  username: string;
  password: string;
}
export interface UpdateUsernameAction {
  type: "UPDATE_USERNAME";
  username: string;
}
export interface UpdatePasswordAction {
  type: "UPDATE_PASSWORD";
  password: string;
}

export interface GetUsersAction {
  type: "GET_USERS_BEGIN";
}
export interface GetUsersSuccessAction {
  type: "GET_USERS_SUCCESS";
  data: Array<IUser>;
}
export interface GetUsersErrorAction {
  type: "GET_USERS_ERROR";
}

export interface UpdateUserAction {
  type: "UPDATE_USER_BEGIN";
  updateUser: IUser;
}
export interface UpdateUserSuccessAction {
  type: "UPDATE_USER_SUCCESS";
}
export interface UpdateUserErrorAction {
  type: "UPDATE_USER_ERROR";
}

export interface UpdateCountySettingsAction {
  type: "UPDATE_COUNTY_SETTINGS_BEGIN";
  updateCountySettings: ICountySettings;
}
export interface UpdateCountySettingsSuccessAction {
  type: "UPDATE_COUNTY_SETTINGS_SUCCESS";
}
export interface UpdateCountySettingsErrorAction {
  type: "UPDATE_COUNTY_SETTINGS_ERROR";
}

export interface GetCountyAction {
  type: "GET_COUNTY_BEGIN";
}
export interface GetCountySuccessAction {
  type: "GET_COUNTY_SUCCESS";
  data: ICounty;
}
export interface GetCountySettingsAction {
  type: "GET_COUNTY_SETTINGS_BEGIN";
}
export interface GetCountySettingsSuccessAction {
  type: "GET_COUNTY_SETTINGS_SUCCESS";
  data: ICountySettings;
}


export interface SendContactMessageAction {
  type: "SEND_CONTACT_MESSAGE_BEGIN";
  contactForm: IContactForm;
}
export interface SendContactMessageSuccessAction {
  type: "SEND_CONTACT_MESSAGE_SUCCESS";
}
export interface SendContactMessageErrorAction {
  type: "SEND_CONTACT_MESSAGE_ERROR";
}
export interface GetUnassignedLegalParcelsAction {
  type: "GET_UNASSIGNED_LEGAL_COUNT_BEGIN";
  countyID: number;
}
export interface GetUnassignedLegalParcelsSuccessAction {
  type: "GET_UNASSIGNED_LEGAL_COUNT_SUCCESS";
  data: number;
}
export interface GetUserAction {
  type: "GET_USER_BEGIN";
  userId: number;
}
export interface GetUserSuccessAction {
  type: "GET_USER_SUCCESS";
  data: IUser;
}
export interface GetUserErrorAction {
  type: "GET_USER_ERROR";
}

export interface GetCountiesAction {
  type: "GET_COUNTIES_BEGIN";
}
export interface GetCountiesSuccessAction {
  type: "GET_COUNTIES_SUCCESS";
  data: Array<ICounty>;
}

export interface GetAttorneysAction {
  type: "GET_ATTORNEYS_BEGIN";
}
export interface GetAttorneysSuccessAction {
  type: "GET_ATTORNEYS_SUCCESS";
  data: Array<IAttorney> | null;
}

export interface GetAttorneyUsersAction {
  type: "GET_ATTORNEY_USERS_BEGIN";
}
export interface GetAttorneyUsersSuccessAction {
  type: "GET_ATTORNEY_USERS_SUCCESS";
  data: Array<IUser>;
}

export interface GetStatesAction {
  type: "GET_STATES_BEGIN";
}
export interface GetStatesSuccessAction {
  type: "GET_STATES_SUCCESS";
  data: Array<IState>;
}

export interface RequestPasswordResetAction {
  type: "REQUEST_PASSWORD_RESET_BEGIN";
  userId: number;
}
export interface RequestPasswordResetSuccessAction {
  type: "REQUEST_PASSWORD_RESET_SUCCESS";
  data: IUser;
}
export interface RequestPasswordResetErrorAction {
  type: "REQUEST_PASSWORD_RESET_ERROR";
}

export interface ResetPasswordAction {
  type: "RESET_PASSWORD_BEGIN";
  userId: number;
}
export interface ResetPasswordSuccessAction {
  type: "REQUEST_PASSWORD_RESET_SUCCESS";
  data: IUser;
}
export interface ResetPasswordErrorAction {
  type: "REQUEST_PASSWORD_RESET_ERROR";
}

export interface ClearCurrentUser {
  type: "CLEAR_SELECTED_USER";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | LoginAction
  | LogoutAction
  | UpdateUsernameAction
  | UpdatePasswordAction
  | ReceiveTokenAction
  | RequestTokenAction
  | GetUsersAction
  | GetUsersSuccessAction
  | GetUsersErrorAction
  | GetCountiesAction
  | GetCountiesSuccessAction
  | GetAttorneysAction
  | GetAttorneysSuccessAction
  | GetAttorneyUsersAction
  | GetAttorneyUsersSuccessAction
  | GetStatesAction
  | GetStatesSuccessAction
  | CreateUserAction
  | CreateUserSuccessAction
  | CreateUserErrorAction
  | LoginUserErrorAction
  | LoginNotApprovedUserErrorAction
  | UpdateUserAction
  | UpdateUserSuccessAction
  | UpdateUserErrorAction
  | UpdateCountySettingsAction
  | UpdateCountySettingsSuccessAction
  | UpdateCountySettingsErrorAction
  | SendContactMessageAction
  | SendContactMessageSuccessAction
  | SendContactMessageErrorAction
  | GetUserAction
  | GetUserSuccessAction
  | GetUnassignedLegalParcelsAction
  | GetUnassignedLegalParcelsSuccessAction
  | GetUserErrorAction
  | RequestPasswordResetAction
  | RequestPasswordResetSuccessAction
  | RequestPasswordResetErrorAction
  | ResetPasswordAction
  | ResetPasswordSuccessAction
  | ResetPasswordErrorAction
  | ClearCurrentUser
  | GetCountyAction
  | GetCountySuccessAction
  | GetCountySettingsAction
  | GetCountySettingsSuccessAction
  | RouterAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  login: () => ({ type: "LOGIN" } as LoginAction),
  logout: () => ({ type: "LOGOUT" } as LogoutAction),
  clearToken: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    localStorage.removeItem("token");
    dispatch({ type: "LOGOUT" });
  },
  // createUser: (createUser: ICreateUser) => ({ type: 'CREATE_USER_BEGIN', createUser: createUser } as CreateUserAction),
  createUserSuccess: () => ({ type: "CREATE_USER_SUCCESS" } as CreateUserSuccessAction),
  createUserError: () => ({ type: "CREATE_USER_ERROR" } as CreateUserErrorAction),
  updateUsername: (username: string) =>
    ({
      type: "UPDATE_USERNAME",
      username: username,
    } as UpdateUsernameAction),
  updatePassword: (password: string) =>
    ({
      type: "UPDATE_PASSWORD",
      password: password,
    } as UpdatePasswordAction),
  receiveToken: (token: string) => ({ type: "RECEIVE_TOKEN", token: token } as ReceiveTokenAction),
  //requestToken: (username: string, password: string) => ({ type: 'REQUEST_TOKEN', username: username, password: password} as RequestTokenAction),
  requestAuthToken:
    (username: string, password: string, fromParcelId: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState && appState.user && username && password && !appState.user.isLoggedIn) {
        fetch(`api/auth/login`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: `{"username": "${username}","password": "${password}"}`,
        })
          .then((response) => {
            if (response.status == 401) {
              dispatch({ type: "LOGIN_NOT_APPROVED_USER_ERROR" });
            } else if (response.status == 400) {
              dispatch({ type: "LOGIN_USER_ERROR" });
            }

            var data = response.json() as Promise<ITokenResult>;
            return data;
          })
          .then((data) => {
            if (data.tokenString) {
              localStorage.setItem("token", data.tokenString);
              dispatch({ type: "RECEIVE_TOKEN", token: data.tokenString });
              const accountType = (jwt_decode(localStorage.getItem("token") as string) as any).AccountType as number;

              const countyId = (jwt_decode(localStorage.getItem("token") as string) as any).CountyId as number;
              switch (accountType.toString()) {
                case AccountType.Admin.toString():
                  dispatch(push("/admin/landing"));
                  break;
                case AccountType.County.toString():
                  dispatch(push(`/county/${countyId}/landing`));
                  break;
                case AccountType.Legal.toString():
                  dispatch(push(`/legal/parcels?countyID=${countyId}&mine=true`));
                  break;
                case AccountType.TaxPayer.toString():
                  if (fromParcelId !== "0") {
                    dispatch(push(`/parcels/${fromParcelId}`));
                  } else {
                    dispatch(push("/parcels/list"));
                  }
                  break;
              }
            }
          });

        dispatch({
          type: "REQUEST_TOKEN",
          username: username,
          password: password,
        });
      }
    },
  createUser:
    (createUser: ICreateUser, fromParcelId: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState && appState.user && !appState.user.isLoggedIn) {
        fetch(`api/users`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(createUser),
        })
          .then((response) => {
            if (response.status === 500) {
              dispatch({ type: "CREATE_USER_ERROR" });
            } else {
              var data = response.json() as Promise<IUser>;
              return data;
            }
          })
          .then((data) => {
            if (data) {
              dispatch({ type: "CREATE_USER_SUCCESS" });
              if (data.accountType === AccountType.TaxPayer) {
                //dispatch(push("/confirmationTaxPayer"));
                dispatch(push(`/login?newAccount=true&parcelId=${fromParcelId}`));
              } else {
                dispatch(push("/confirmation"));
              }
            }
          });

        dispatch({ type: "CREATE_USER_BEGIN", createUser });
      }
    },
  isUserAdmin: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    return appState && appState.user && appState.user.isAdmin;
  },
  getUsers: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      fetch(`api/users`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createUser)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<IUser>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_USERS_SUCCESS", data });
        });

      dispatch({ type: "GET_USERS_BEGIN" });
    }
  },
  getUsersSuccess: (data: any) => ({ type: "GET_USERS_SUCCESS", data: data } as GetUsersSuccessAction),
  updateUserSuccess: () => ({ type: "UPDATE_USER_SUCCESS" } as UpdateUserSuccessAction),
  updateUserError: () => ({ type: "UPDATE_USER_ERROR" } as UpdateUserErrorAction),
  updateUser:
    (updateUser: IUser): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/users`, {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(updateUser),
        })
          .then((response) => {
            var data = response.json() as Promise<ITokenResult>;
            return data;
          })
          .then(() => {
            dispatch({ type: "UPDATE_USER_SUCCESS" });
            if (appState.user?.currentUser.accountType == AccountType.Admin) {
              dispatch(push(`/admin/users`));
            }
          });

        dispatch({ type: "UPDATE_USER_BEGIN", updateUser });
      }
    },
  sendContactMessageSuccess: () => ({ type: "SEND_CONTACT_MESSAGE_SUCCESS" } as SendContactMessageSuccessAction),
  sendContactMessageError: () => ({ type: "SEND_CONTACT_MESSAGE_ERROR" } as SendContactMessageErrorAction),
  sendContactMessage:
    (contactForm: IContactForm): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/users/contact`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(contactForm),
        })
          .then((response) => {
            var data = response.json() as Promise<any>;
            return data;
          })
          .then(() => {
            dispatch({ type: "SEND_CONTACT_MESSAGE_SUCCESS" });
          });

        dispatch({ type: "SEND_CONTACT_MESSAGE_BEGIN", contactForm });
      }
    },
  getUser:
    (userId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/users/${userId}`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createUser)
        })
          .then((response) => {
            var data = response.json() as Promise<IUser>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_USER_SUCCESS", data });
          });

        dispatch({ type: "GET_USER_BEGIN", userId });
      }
    },
  getUserSuccess: (data: any) => ({ type: "GET_USER_SUCCESS", data: data } as GetUserSuccessAction),
  getCounties: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      fetch(`api/counties`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createUser)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<ICounty>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_COUNTIES_SUCCESS", data });
        });

      //dispatch({ type: "GET_COUNTIES_BEGIN" });
    }
  },
  getCountiesSuccess: (data: any) => ({ type: "GET_COUNTIES_SUCCESS", data: data } as GetCountiesSuccessAction),
  getCounty: (countyID: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      fetch(`api/counties/${countyID}`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          var data = response.json() as Promise<ICounty>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_COUNTY_SUCCESS", data });
        });
    }
  },
  getCountySuccess: (data: any) => ({ type: "GET_COUNTY_SUCCESS", data: data } as GetCountySuccessAction),
  getCountySettings: (countyID: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      fetch(`api/counties/${countyID}/settings`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          var data = response.json() as Promise<ICountySettings>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_COUNTY_SETTINGS_SUCCESS", data });
        });
    }
  },
  getCountySettingsSuccess: (data: any) => ({ type: "GET_COUNTY_SETTINGS_SUCCESS", data: data } as GetCountySettingsSuccessAction),
  updateCountySettingsSuccess: () => ({ type: "UPDATE_COUNTY_SETTINGS_SUCCESS" } as UpdateCountySettingsSuccessAction),
  updateCountySettingsError: () => ({ type: "UPDATE_COUNTY_SETTINGS_ERROR" } as UpdateCountySettingsErrorAction),
  updateCountySettings:
    (updateCountySettings: ICountySettings): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/counties/${updateCountySettings.countyID}/settings`, {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(updateCountySettings),
        })
          .then((response) => {
            var data = response.json() as Promise<ITokenResult>;
            return data;
          })
          .then(() => {
            dispatch({ type: "UPDATE_COUNTY_SETTINGS_SUCCESS" });
            dispatch(push(`/admin/counties`));
          });

        dispatch({ type: "UPDATE_COUNTY_SETTINGS_BEGIN", updateCountySettings });
      }
    },
  getAttorneys: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      fetch(`api/attorneys`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createUser)
      })
        .then((response) => {
          console.log(response.statusText);
          if (response.statusText != "No Content") {
            var data = response.json() as Promise<Array<IAttorney>>;
            return data;
          } else {
            return null;
          }
        })
        .then((data) => {
          dispatch({ type: "GET_ATTORNEYS_SUCCESS", data });
        });

      //dispatch({ type: "GET_ATTORNEYS_BEGIN" });
    }
  },
  getAttorneysSuccess: (data: any) => ({ type: "GET_ATTORNEYS_SUCCESS", data: data } as GetAttorneysSuccessAction),
  getAttorneyUsers:
    (attorneyID: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/attorneys/${attorneyID}/users`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createUser)
        })
          .then((response) => {
            var data = response.json() as Promise<Array<IUser>>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_ATTORNEY_USERS_SUCCESS", data });
          });

        //dispatch({ type: "GET_ATTORNEY_USERS_BEGIN" });
      }
    },
  getAttorneyUsersSuccess: (data: any) => ({ type: "GET_ATTORNEY_USERS_SUCCESS", data: data } as GetAttorneyUsersSuccessAction),
  getUnassignedLegalParcels:
    (countyID: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`/api/counties/${countyID}/unassignedlegalparcelcount`, {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createUser)
        })
          .then((response) => {
            var data = response.json() as Promise<number>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "GET_UNASSIGNED_LEGAL_COUNT_SUCCESS", data });
          });

        //dispatch({ type: "GET_ATTORNEY_USERS_BEGIN" });
      }
    },
  getUnassignedLegalParcelsSuccess: (data: any) => ({ type: "GET_ATTORNEY_USERS_SUCCESS", data: data } as GetAttorneyUsersSuccessAction),
  getStates: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState) {
      fetch(`api/states`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        //body: JSON.stringify(createUser)
      })
        .then((response) => {
          var data = response.json() as Promise<Array<IState>>;
          return data;
        })
        .then((data) => {
          dispatch({ type: "GET_STATES_SUCCESS", data });
        });

      //dispatch({ type: "GET_STATES_BEGIN" });
    }
  },
  getStatesSuccess: (data: any) => ({ type: "GET_STATES_SUCCESS", data: data } as GetStatesSuccessAction),
  requestPasswordReset:
    (username: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/users/${username}/reset`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          //body: JSON.stringify(createUser)
        })
          .then((response) => {
            var data = response.json() as Promise<any>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "REQUEST_PASSWORD_RESET_SUCCESS", data });
            dispatch(push(`/forgotPasswordConfirmation`));
          });
      }
    },
  requestPasswordResetSuccess: (data: any) =>
    ({
      type: "REQUEST_PASSWORD_RESET_SUCCESS",
      data: data,
    } as RequestPasswordResetSuccessAction),
  resetPassword:
    (password: string, token: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();
      if (appState) {
        fetch(`api/users/resetpassword?password=${password}`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(token),
        })
          .then((response) => {
            var data = response.json() as Promise<any>;
            return data;
          })
          .then((data) => {
            dispatch({ type: "REQUEST_PASSWORD_RESET_SUCCESS", data });
            dispatch(push(`/resetPasswordConfirmation`));
          });
      }
    },
  resetPasswordSuccess: (data: any) =>
    ({
      type: "REQUEST_PASSWORD_RESET_SUCCESS",
      data: data,
    } as RequestPasswordResetSuccessAction),
  clearCurrentUser: () => ({ type: "CLEAR_SELECTED_USER" } as ClearCurrentUser),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<UserState> = (state: UserState | undefined, incomingAction: Action): UserState => {
  if (state === undefined) {
    return {
      currentUser: {
        username: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).unique_name : "",
        accountType: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).AccountType : "",
        countyId: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).CountyId : "",
        attorneyID: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).AttorneyID : "",
        id: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).nameid : "",
        hasStripeVerifiedACH: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).HasStripeVerifiedACH : "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        approved: false,
        created: new Date(),
        updated: undefined,
        passwordHash: "",
        passwordSalt: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        showRefNumLegal: false,
        stripeCustomers: [],
      },
      selectedUser: {
        username: "",
        accountType: 0,
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        approved: false,
        created: new Date(),
        updated: undefined,
        passwordHash: "",
        passwordSalt: "",
        countyId: 0,
        attorneyID: 0,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        hasStripeVerifiedACH: false,
        showRefNumLegal: false,
        stripeCustomers: [],
      },
      username: "",
      password: "",
      isLoggedIn: !!localStorage.getItem("token"),
      isAdmin: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.Admin : false,
      token: localStorage.getItem("token") as string,
      isRequesting: false,
      userList: [],
      createUserErrorMessage: "",
      loginErrorMessage: "",
      counties: [],
      states: [],
      attorneys: [],
      attorneyUsers: [],
      selectedCounty: undefined,
      selectedCountySettings: undefined,
      unassignedLegalParcelCount: 0,
    };
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isLoggedIn: true,
        isAdmin: (jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.Admin,
        currentUser: {
          username: (jwt_decode(localStorage.getItem("token") as string) as any).unique_name,
          accountType: (jwt_decode(localStorage.getItem("token") as string) as any).AccountType,
          id: (jwt_decode(localStorage.getItem("token") as string) as any).nameid,
          countyId: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).CountyId : "",
          attorneyID: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).AttorneyID : "",
          hasStripeVerifiedACH: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).HasStripeVerifiedACH : false,
          //stripeCustomerId: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).StripeCustomerId : "",
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          approved: false,
          created: new Date(),
          updated: undefined,
          passwordHash: "",
          passwordSalt: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          showRefNumLegal: false,
          stripeCustomers: [],
        },
      };
    case "LOGOUT":
      return {
        ...state,
        isLoggedIn: false,
        username: "",
        password: "",
      };
    case "CREATE_USER_BEGIN":
      return {
        ...state,
      };
    case "CREATE_USER_ERROR":
      return {
        ...state,
        createUserErrorMessage: "Username/Email already exists.  Please login to that account, or choose a new username/email address.",
      };
    case "LOGIN_USER_ERROR":
      return {
        ...state,
        loginErrorMessage: "Invalid Username or Password.   Please try again.",
      };
    case "LOGIN_NOT_APPROVED_USER_ERROR":
      return {
        ...state,
        loginErrorMessage: "Your user account has not yet been approved.   If you believe this is in error, please contact US Assets for assistance.",
      };
    case "UPDATE_USERNAME":
      return {
        ...state,
        username: (action as UpdateUsernameAction).username,
      };
    case "UPDATE_PASSWORD":
      return {
        ...state,
        password: (action as UpdatePasswordAction).password,
      };
    case "REQUEST_TOKEN":
      return {
        ...state,
        isRequesting: true,
      };
    case "RECEIVE_TOKEN":
      return {
        ...state,
        token: (action as ReceiveTokenAction).token,
        isLoggedIn: true,
        isRequesting: false,
        currentUser: {
          username: (jwt_decode(localStorage.getItem("token") as string) as any).unique_name,
          accountType: (jwt_decode(localStorage.getItem("token") as string) as any).AccountType,
          id: (jwt_decode(localStorage.getItem("token") as string) as any).nameid,
          hasStripeVerifiedACH: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).HasStripeVerifiedACH : "",
          //stripeCustomerId: localStorage.getItem("token") ? (jwt_decode(localStorage.getItem("token") as string) as any).StripeCustomerId : "",
          firstName: "",
          lastName: "",
          email: (jwt_decode(localStorage.getItem("token") as string) as any).Email,
          phone: "",
          approved: false,
          created: new Date(),
          updated: undefined,
          passwordHash: "",
          passwordSalt: "",
          countyId: 0,
          attorneyID: 0,
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          showRefNumLegal: false,
          stripeCustomers: [],
        },
      };
    case "GET_USERS_SUCCESS":
      return {
        ...state,
        userList: (action as GetUsersSuccessAction).data,
      };
    case "GET_UNASSIGNED_LEGAL_COUNT_SUCCESS":
      return {
        ...state,
        unassignedLegalParcelCount: (action as GetUnassignedLegalParcelsSuccessAction).data,
      };
    case "GET_USER_SUCCESS":
      var localState = {
        ...state,
        selectedUser: (action as GetUserSuccessAction).data,
      };
      return localState;
    case "GET_COUNTIES_SUCCESS":
      return {
        ...state,
        counties: (action as GetCountiesSuccessAction).data,
      };
    case "GET_COUNTY_SUCCESS":
      return {
        ...state,
        selectedCounty: (action as GetCountySuccessAction).data,
      };
    case "GET_COUNTY_SETTINGS_SUCCESS":
      return {
        ...state,
        selectedCountySettings: (action as GetCountySettingsSuccessAction).data,
      };
    case "GET_ATTORNEYS_SUCCESS":
      return {
        ...state,
        attorneys: (action as GetAttorneysSuccessAction).data ?? [],
      };
    case "GET_ATTORNEY_USERS_SUCCESS":
      return {
        ...state,
        attorneyUsers: (action as GetAttorneyUsersSuccessAction).data,
      };
    case "GET_STATES_SUCCESS":
      return {
        ...state,
        states: (action as GetStatesSuccessAction).data,
      };
    case "CLEAR_SELECTED_USER":
      return {
        ...state,
        selectedUser: undefined,
      };
    default:
      return state;
  }
};
