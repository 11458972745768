export class IServiceTracking {
  /**
   *
   */
  constructor() {
    this.defendantID = 0;
    this.parcelID = 0;
    this.defendantName = "";
    this.serviceType = "";
    this.serviceDate = new Date();
    this.created = new Date();
    this.updated = new Date();
    this.createdUserID = 0;
    this.updatedUserID = 0;
  }
  defendantID: number;
  parcelID: number;
  defendantName: string;
  serviceType: string;
  serviceDate: Date;
  created: Date;
  updated: Date;
  createdUserID: number;
  updatedUserID: number;
}
