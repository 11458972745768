import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { useEffect } from "react";
import { ApplicationState } from "../../store";
import * as UserStore from "../../store/user";

type LegalLandingProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

function LegalLanding(props: LegalLandingProps) {
  // const [selectedLegal, setSelectedLegal] = useState(props.selectedLegal);

  // useEffect(() => {
  //   console.log("firing the user set");
  //   setSelectedLegal(props.selectedLegal);
  // }, [props.selectedLegal]);

  useEffect(() => {
    //const selectedLegalId = (props.match.params as any).LegalId;
    //props.getLegal(selectedLegalId);
  }, []);

  return (
    <React.Fragment>
      <h1>Legal landing page here.</h1>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(LegalLanding as any);
