import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../store";
import * as UserStore from "../../store/user";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Password } from "primereact/password";
import { Link } from "react-router-dom";
import { Divider } from "primereact/divider";

type UserProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

class Login extends React.PureComponent<UserProps> {
  public render() {
    const query = new URLSearchParams(this.props.location.search);
    const isNewAccount = query.get("newAccount") == "true";
    const fromParcelId = query.get("parcelId") ?? "0";

    return (
      <React.Fragment>

        {isNewAccount && (
          <div className="card" style={{ textAlign: "center", padding: "25px", width: "850px" }}>
            <h2>
              Your signup was successful! <br /> Please log in below to get started.
            </h2>
          </div>
        )}


        <div className="card" style={{ paddingTop: "20px", paddingLeft: "7px" }}>
          <div className="p-d-flex p-flex-column p-flex-md-row p-jc-center">
            <div className="p-mb-2 p-mr-2">
              <div className="p-fluid">
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    this.props.requestAuthToken(this.props.username, this.props.password, fromParcelId);
                  }}
                >
                  <label style={{ fontWeight: "bold", fontSize: "large" }}>Username:</label>

                  <InputText
                    name="username"
                    value={this.props.username}
                    onChange={(e) => this.props.updateUsername((e.target as unknown as HTMLTextAreaElement).value)}
                  />
                  <br />
                  <br />
                  <label style={{ fontWeight: "bold", fontSize: "large" }}>Password:</label>

                  <Password
                    feedback={false}
                    value={this.props.password}
                    onChange={(e) => this.props.updatePassword((e.target as unknown as HTMLTextAreaElement).value)}
                  />
                  <br />
                  <br />
                  <button type="submit" className={"p-button p-component p-button-text-only"}>
                    <span className={"p-button-text p-c"}>Log In</span>
                  </button>
                  <br />
                  {this.props.loginErrorMessage.length > 0 && (
                    <p className="errorText" style={{ textAlign: "center", marginTop: "80px" }}>
                      {this.props.loginErrorMessage}
                    </p>
                  )}
                  <br />
                  <Link to={"/forgotPassword"}>Forgot Password</Link>
                </form>
              </div>
            </div>
            <div className="p-mb-2 p-mr-2 p-d-sm p-d-md-none" style={{ width: "100%" }}>
              <Divider layout="vertical">
                <b>OR</b>
              </Divider>
            </div>
            <div className="p-mb-2 p-mr-2 p-d-none p-d-md-inline" style={{ width: "20%" }}>
              <Divider layout="vertical">
                <b>OR</b>
              </Divider>
            </div>

            <div className="p-mb-2 p-mr-2 p-d-sm p-d-md-none p-fluid" style={{ width: "100%" }}>
              <Button
                className="p-button p-mt-4 p-as-center"
                label="Sign Up"
                onClick={() => {
                  this.props.history.push(`/signup?parcelId=${fromParcelId}`);
                }}
              />
            </div>
            <div className="p-mb-2 p-mr-2 p-d-none p-d-md-inline" style={{ width: "20%" }}>
              <div className="p-d-flex p-flex-column" style={{ height: "170px" }}>
                <div className="p-mt-auto">
                  <Button
                    className="p-button p-mt-4 p-as-center"
                    label="Sign Up"
                    onClick={() => {
                      this.props.history.push(`/signup?parcelId=${fromParcelId}`);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(Login as any);
