import { InputText } from "primereact/inputtext";
import { useState, useEffect } from "react";
import { Button } from "primereact/button";
import React from "react";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { loadStripe, Stripe, TokenResult } from '@stripe/stripe-js';

const accountHolderTypes = [
    { label: 'Individual', value: 'individual' },
    { label: 'Company', value: 'company' }
];

const accountTypes = [
    { label: 'Checking ', value: 'checking' },
    { label: 'Savings', value: 'savings' }
];

const Message = ({ message }: { message: string }) => (
    <section>
        <p>{message}</p>
    </section>
);

const AchInfo = {
    country: 'US',
    currency: 'usd',
    routing_number: "110000000",
    account_number: "000123456789",
    account_holder_name: "Jenny Rosen",
    account_holder_type: "individual",
    account_type: "savings",
    amount1: "32",
    amount2: "45",
    chargeAmount: "1524.32"
}

let stripe: Stripe | null;

export default function Payment() {
    const [message, setMessage] = useState("")
    const [verifyAch, setVerifyAch] = useState(false);
    const [chargeAch, setChargeAch] = useState(false);
    const [achInfo] = useState(AchInfo);

    loadStripe('pk_test_51JYgg1C3oIFdHD3jCJElQdVnU4JODFE5ajT8ZScY2j3GDVDbb4Y3ZvpoR5yghJMDK9kbstXlUOmGR5BO3gBcdpK90037wDOzme')
        .then(function (result: Stripe | null) {
            stripe = result
        })
        .catch(function (error) {
            alert(error);
        });

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setMessage("Payment accepted!");
        }

        if (query.get("canceled")) {
            setMessage(
                "Payment canceled -- is this what you intended?"
            );
        }

        if (query.get("ach")) {
            setVerifyAch(true);
        }

        if (query.get("charge")) {
            setChargeAch(true);
        }
    }, []);

    if (chargeAch) {
        return (
            <React.Fragment>
                <div className="p-grid">
                    <div className="p-col-4"></div>
                    <div className="p-col-3">
                        <Card title="Charge ACH" subTitle="" style={{ width: "500px" }}>
                            <label>Amount: </label>
                            <br />
                            <InputText
                                name="chargeAmount"
                                value={achInfo.chargeAmount}
                            />
                            <br />
                            <br />

                            <Button
                                label="Charge!"
                                onClick={() => {
                                    fetch(`api/payments/chargeAch`, {
                                        method: 'post',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                                        },
                                        body: JSON.stringify({
                                            chargeAmount: achInfo.chargeAmount
                                        })
                                    }).then((response) => {
                                        return response.json() as Promise<any>;
                                    }).then((charge) => {
                                        localStorage.setItem('stripeBankAccount', JSON.stringify(charge));
                                        window.location.href = '/payment?success=true';
                                    });
                                }
                                }
                            />
                        </Card>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    if (verifyAch) {
        return (
            <React.Fragment>
                <div className="p-grid">
                    <div className="p-col-4"></div>
                    <div className="p-col-3">
                        <Card title="Verify ACH Information" subTitle="" style={{ width: "500px" }}>
                            <label>1st Amount: </label>
                            <br />
                            <InputText
                                name="amount1"
                                value={achInfo.amount1}
                            />
                            <br />
                            <br />

                            <label>2nd Amount: </label>
                            <br />
                            <InputText
                                name="amount2"
                                value={achInfo.amount2}
                            />
                            <br />
                            <br />

                            <Button
                                label="Verfiy"
                                onClick={() => {
                                    fetch(`api/payments/verifyAch`, {
                                        method: 'post',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                                        },
                                        body: JSON.stringify({
                                            amount1: achInfo.amount1,
                                            amount2: achInfo.amount2
                                        })
                                    }).then((response) => {
                                        return response.json() as Promise<any>;
                                    }).then((bankAccount) => {
                                        localStorage.setItem('stripeBankAccount', JSON.stringify(bankAccount));
                                        window.location.href = '/payment?charge=true';
                                    });
                                }
                                }
                            />
                        </Card>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return message ? (
        <Message message={message} />
    ) : (
        <React.Fragment>
            <div className="p-grid">
                <div className="p-col-4"></div>
                <div className="p-col-3">
                    <Card title="Submit ACH Information" subTitle="" style={{ width: "500px" }}>
                        <label>Routing Number:</label>
                        <br />
                        <InputText
                            name="routing_number"
                            value={achInfo.routing_number}
                        />
                        <br />
                        <br />

                        <label>Account Number:</label>
                        <br />
                        <InputText
                            name="account_number"
                            value={achInfo.account_number}
                        />
                        <br />
                        <br />

                        <label>Account Holder Name:</label>
                        <br />
                        <InputText
                            name="account_holder_name"
                            value={achInfo.account_holder_name}
                        />
                        <br />
                        <br />

                        <label>Account Holder Type:</label>
                        <br />
                        <Dropdown
                            name="account_holder_type"
                            options={accountHolderTypes}
                            placeholder="Select An Account Holder Type"
                            value={achInfo.account_holder_type}
                        />
                        <br />
                        <br />

                        <label>Account Type:</label>
                        <br />
                        <Dropdown
                            name="account_type"
                            options={accountTypes}
                            placeholder="Select An Account Type"
                            value={achInfo.account_type}
                        />
                        <br />
                        <br />

                        <Button
                            label="Submit"
                            onClick={() => {
                                if (stripe != null) {
                                    stripe.createToken('bank_account', {
                                        country: achInfo.country,
                                        currency: achInfo.currency,
                                        routing_number: achInfo.routing_number,
                                        account_number: achInfo.account_number,
                                        account_holder_name: achInfo.account_holder_name,
                                        account_holder_type: achInfo.account_holder_type,
                                        //account_type: achInfo.account_type,   //it complained when I sent this...
                                    }).then(function (tr: TokenResult) {
                                        if (tr.error) {
                                            alert(JSON.stringify(tr));
                                        } else {
                                            fetch(`api/payments/ach`, {
                                                method: 'post',
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                                                },
                                                body: JSON.stringify(tr)
                                            }).then((response) => {
                                                return response.json() as Promise<any>;
                                            }).then((customer) => {
                                                localStorage.setItem('stripeAchCustomer', JSON.stringify(customer));
                                                window.location.href = '/payment?ach=true';
                                            });
                                        }
                                    }).catch(function (error: any) {
                                        alert(error);
                                    });
                                }
                            }
                            }
                        />
                    </Card>
                </div>
                <div className="p-col-2"></div>
            </div>
        </React.Fragment>
    );
}
