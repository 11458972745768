import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { ApplicationState } from "../../store";
import * as UserStore from "../../store/user";

type CountyReportsProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

function CountyReports(props: CountyReportsProps) {
  const [selectedCountyId, setSelectedCountyId] = useState(0);

  // useEffect(() => {
  //   console.log("firing the user set");
  //   setSelectedCounty(props.selectedCounty);
  // }, [props.selectedCounty]);

  useEffect(() => {
    const selectedCountyId = (props.match.params as any).countyId;
    setSelectedCountyId(selectedCountyId);
    //props.getCounty(selectedCountyId);
  }, []);

  return (
    <React.Fragment>
      <Card title="Reports">
        <div style={{ textAlign: "center" }}>
          <Button
            style={{ width: "25%" }}
            icon="pi pi-arrow-right"
            iconPos="right"
            label="Payment Plans"
            onClick={() => {
              props.history.push(`/county/${selectedCountyId}/reports/paymentplans`);
            }}
          />
          <br />
          <br />
          <Button
            style={{ width: "25%" }}
            icon="pi pi-arrow-right"
            iconPos="right"
            label="Expenses By Certificate"
            onClick={() => {
              props.history.push(`/county/${selectedCountyId}/reports/expensesbycertificate`);
            }}
          />
          <br />
          <br />
          <Button
            style={{ width: "25%" }}
            icon="pi pi-arrow-right"
            iconPos="right"
            label="Redemptions"
            onClick={() => {
              alert("This report is coming soon.");
            }}
          />
          <br />
          <br />

          <Button
            style={{ width: "25%" }}
            icon="pi pi-arrow-right"
            iconPos="right"
            label="Servicing Stats"
            onClick={() => {
              alert("This report is coming soon.");
            }}
          />
          <br />
          <br />
          <Button
            style={{ width: "25%" }}
            icon="pi pi-arrow-right"
            iconPos="right"
            label="Portfolio Status By Year"
            onClick={() => {
              alert("This report is coming soon.");
            }}
          />
        </div>
      </Card>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(CountyReports as any);
