import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import * as React from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import * as UserStore from "../../store/user";
import { ApplicationState } from "../../store";
import { useState } from "react";
import { toast } from "react-toastify";
import { IContactForm } from "../../models/IContactForm";
import { Divider } from "primereact/divider";
import { Link } from "react-router-dom";

type ContactUsProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

function Contact(props: ContactUsProps) {
  const [contactFormData, setContactFormData] = useState({
    fullName: "",
    address: "",
    cityStateZip: "",
    email: "",
    phone: "",
    message: "",
    relatedProperty: "",
  });

  const notify = () => {
    toast.success("Message Sent!");
  };

  return (

      <Card title="Contact Us" subTitle="" className="p-fluid" style={{maxWidth: "520px"}}>
        US Assets, LLC
        <br />
        405 N 115th St, Suite 100
        <br /> Omaha, Nebraska 68154 <br />
        Phone: (402) 399-9049 <br />
        Toll Free: 1-844-216-4341 <br />
        Fax: (402) 399-5350
        <br />
        <br />
        <br />
        <label>Full Name:</label>
        <br />
        <InputText
          name="fullName"
          value={contactFormData.fullName}
          onChange={(e) => setContactFormData({ ...contactFormData, fullName: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />
        <label>Address:</label>
        <br />
        <InputText
          name="address"
          value={contactFormData.address}
          onChange={(e) => setContactFormData({ ...contactFormData, address: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />
        <label>City, State, Zip:</label>
        <br />
        <InputText
          name="cityStateZip"
          value={contactFormData.cityStateZip}
          onChange={(e) => setContactFormData({ ...contactFormData, cityStateZip: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />
        <label>Email:</label>
        <br />
        <InputText
          name="email"
          keyfilter="email"
          value={contactFormData.email}
          onChange={(e) => setContactFormData({ ...contactFormData, email: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />
        <label>Phone:</label>
        <br />
        <InputText
          name="phone"
          value={contactFormData.phone}
          onChange={(e) => setContactFormData({ ...contactFormData, phone: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />
        <label>Related Property:</label>
        <br />
        <InputText
          name="relatedProperty"
          value={contactFormData.relatedProperty}
          onChange={(e) => setContactFormData({ ...contactFormData, relatedProperty: (e.target as unknown as HTMLTextAreaElement).value })}
        />
        <br />
        <br />
        <label>Message:</label>
        <br />
        <InputTextarea
          name="message"
          rows={5}
          cols={30}
          value={contactFormData.message}
          onChange={(e) => setContactFormData({ ...contactFormData, message: e.target.value })}
          autoResize
        />
        <br />
        <br />
        <Button
          label="Send Message"
          onClick={() => {
            props.sendContactMessage(contactFormData as IContactForm);
            notify();
          }}
        />
      </Card>
  );
}

export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(Contact as any);
