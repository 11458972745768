import { DESTRUCTION } from "dns";

export class IExpenseTracking {
  /**
   *
   */
  constructor() {
    this.expenseID = 0,
    this.userID = 0,
    this.parcelID = 0,
    this.expenseDate = new Date(),
    this.expenseDescr = "",
    this.expenseAmt = 0,
    this.expenseRef = false,
    this.accrueInterest = false,
    this.newExpenseID = 0,
    this.lastExpenseID = 0
  }
  expenseID: number;
  userID: number | null;
  parcelID: number | null;
  expenseDate: Date | null;
  expenseDescr: string;
  expenseAmt: number | null;
  expenseRef: boolean | null;
  accrueInterest: boolean | null;
  newExpenseID: number | null;
  lastExpenseID: number | null;
}
