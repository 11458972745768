import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { ApplicationState } from "../../store";
import * as ParcelStore from "../../store/parcel";
import { Chart } from "primereact/chart";
import { ICounty } from "../../models/ICounty";
import { BarChartData, DonutChartData } from "../../models/IDonutChartData";

type CountyLandingProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & RouteComponentProps<{}>;

function CountyLanding(props: CountyLandingProps) {
  const [selectedCounty, setSelectedCounty] = useState({} as ICounty);
  const [payPlansByStatus, setPayPlansByStatus] = useState({} as DonutChartData);
  const [countyCorrespondenceCounts, setCountyCorrespondenceCounts] = useState({} as DonutChartData);
  const [redeemedCounts, setRedeemedCounts] = useState({} as BarChartData);

  useEffect(() => {
    const selectedCountyId = (props.match.params as any).countyId;
    setSelectedCounty(props.counties.find((e) => e.countyID == selectedCountyId) as ICounty);
    props.getPaymentPlansByStatus(selectedCountyId);
    props.getCountyCorrespondenceCounts(selectedCountyId);
    props.getRedeemedCounts(selectedCountyId);
  }, [props.counties]);

  useEffect(() => {
    setPayPlansByStatus(props.paymentPlansByStatus);
  }, [props.paymentPlansByStatus]);

  useEffect(() => {
    setCountyCorrespondenceCounts(props.countyCorrespondenceCounts);
  }, [props.countyCorrespondenceCounts]);

  useEffect(() => {
    setRedeemedCounts(props.redeemedCounts);
  }, [props.redeemedCounts]);

  useEffect(() => {
    props.getCounties();
  }, []);

  
  const multiAxisData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Dataset 1",
        backgroundColor: ["#EC407A", "#AB47BC", "#42A5F5", "#7E57C2", "#66BB6A", "#FFCA28", "#26A69A"],
        yAxisID: "y",
        data: [65, 59, 80, 81, 56, 55, 10],
      },
      {
        label: "Dataset 2",
        backgroundColor: "#78909C",
        yAxisID: "y1",
        data: [28, 48, 40, 19, 86, 27, 90],
      },
    ],
  };

  const lightOptions = {
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
  };

  let multiAxisOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
      tooltips: {
        mode: "index",
        intersect: true,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        ticks: {
          min: 0,
          max: 100,
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
          color: "#ebedef",
        },
        ticks: {
          min: 0,
          max: 100,
          color: "#495057",
        },
      },
    },
  };

  return (
    <React.Fragment>
      <Card title={`${selectedCounty?.countyName} County Dashboard`} subTitle="">
        <div className="p-grid">
          <div className="p-col-6">
            <div className=" p-d-flex p-jc-center">
              <h5>Payment Plans By Status</h5>
              <Chart type="doughnut" data={payPlansByStatus} options={lightOptions} style={{ position: "relative", width: "100%" }} />
            </div>
          </div>
          <div className="p-col-6">
            <div className=" p-d-flex p-jc-center">
              <h5>Correspondence Counts</h5>
              <Chart type="doughnut" data={countyCorrespondenceCounts} options={lightOptions} style={{ position: "relative", width: "100%" }} />
            </div>
          </div>
        </div>

        <div className="">
          <h5>Redemptions By Year</h5>
          <Chart type="bar" data={redeemedCounts} options={multiAxisOptions} />
        </div>
      </Card>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.parcel, ParcelStore.actionCreators)(CountyLanding as any);
