export const APP_ROUTES = {
  DASHBOARD: "/",
  PAYMENT: "/payment",
  USER_LIST: "/admin/users",
  USER_EDIT: "/users/:userId",
  COUNTY_LIST: "/admin/counties",
  COUNTY_EDIT: "/admin/counties/:countyId",
  ADMIN_SETTINGS: "/admin/settings",
  ADMIN_LANDING: "/admin/landing",
  ADMIN_PAYMENTS: "/admin/payments",
  ADMIN_WEBPAYMENTSLOG: "/admin/webPaymentLogs",
  TAXPAYER_LANDING: "/taxpayer/landing",
  TAXPAYER_MY_PARCELS: "/taxpayer/parcels/mine",
  TAXPAYER_MY_PAYMENTS: "/taxpayer/payments/mine",
  TAXPAYER_PARCEL_LIST: "/taxpayer/parcels",
  TAXPAYER_PARCEL_DETAILS: "/taxpayer/parcels/:parcelId",
  COUNTY_LANDING: "/county/:countyId/landing",
  COUNTY_REPORTS: "/county/:countyId/reports",
  COUNTY_PAYMENT_PLANS_REPORT: "/county/:countyId/reports/paymentplans",
  COUNTY_EXPENSES_BY_CERTIFICATE_REPORT: "/county/:countyId/reports/expensesbycertificate",
  COUNTY_PARCELS: "/county/:countyId/parcels",
  COUNTY_PARCEL_DETAILS: "/county/:countyId/parcels/:parcelId",
  COUNTY_PARCEL_DETAILS_PAYOFF: "/county/:countyId/parcels/:parcelId/payoff",
  LEGAL_LANDING: "/legal/landing",
  LEGAL_PARCELS: "/legal/parcels",
  LEGAL_PARCEL_DETAILS: "/legal/parcels/:parcelId",
};
