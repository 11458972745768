import react, * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../store";
import * as ParcelStore from "../store/parcel";
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import "./footer.scss";
import { AccountType } from "../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import ExpenseTrackingItem from "./ExpenseTrackingItem";
import { Button } from "primereact/button";
import { IExpenseTracking } from "../models/IExpenseTracking";

type ExpenseTrackingProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & RouteComponentProps<{}>;

function ExpenseTracking(props: ExpenseTrackingProps) {
  const [trackingItems, setTrackingItems] = react.useState([{} as IExpenseTracking]);
  const [postedTrackingItems, setPostedTrackingItems] = react.useState([{} as IExpenseTracking]);
  const [selectedParcelId, setSelectedParcelId] = react.useState(props.selectedParcel?.parcelID);

  react.useEffect(() => {
    setTrackingItems(props.legalExpenseTracking);
    setPostedTrackingItems(props.legalPostedExpenseTracking);
  }, [props.legalExpenseTracking]);

  react.useEffect(() => {
    setTrackingItems(trackingItems);
  }, [trackingItems]);

  react.useEffect(() => {
    if (props.legalExpenseTrackingUpdated) {
      props.getExpenseTracking((props.match.params as any).parcelId);
      props.clearLegalExpenseTracking();
    }
  }, [props.legalExpenseTrackingUpdated]);

  react.useEffect(() => {
    props.getExpenseTracking((props.match.params as any).parcelId);
    setSelectedParcelId((props.match.params as any).parcelId);
  }, []);

  const formatCurrency = (value: number | null) => {
    if (value == null) {
      return "$0";
    }
    return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
  };

  return (
    <React.Fragment>
      <h3>Posted:</h3>
      <div className="p-grid">
        <div className="p-col-2">Amount</div>
        <div className="p-col-2">Date</div>
        <div className="p-col-2">Description</div>
        <div className="p-col-2">Accrues Interest</div>
        <div className="p-col-2">Refundable</div>
        <div className="p-col-1"></div>
      </div>
      {postedTrackingItems.map((item: IExpenseTracking) => {
        return (
          <div className="p-grid">
            <div className="p-col-2">{formatCurrency(item.expenseAmt)}</div>
            <div className="p-col-2">{item.expenseDate != null ? new Date(item.expenseDate).toLocaleDateString() : ""}</div>
            <div className="p-col-2">{item.expenseDescr}</div>
            <div className="p-col-2">{item.accrueInterest ? "Yes" : "No"}</div>
            <div className="p-col-2">{item.expenseRef ? "Yes" : "No"}</div>
            <div className="p-col-1"></div>
          </div>
        );
      })}

      <br />
      <br />
      <h3>Un-Posted Expenses:</h3>
      <div className="p-grid">
        <div className="p-col-2">Amount</div>
        <div className="p-col-2">Date</div>
        <div className="p-col-2">Description</div>
        <div className="p-col-2">Accrues Interest</div>
        <div className="p-col-2">Refundable</div>
        <div className="p-col-1"></div>
      </div>
      {trackingItems.map((item: IExpenseTracking) => {
        return <ExpenseTrackingItem key={item.expenseID} expenseTracking={item} {...props} />;
      })}
      <Button
        label={`Add`}
        icon="pi pi-plus"
        className="p-mr-2"
        onClick={() => {
          var expenseTracking = new IExpenseTracking();
          expenseTracking.parcelID = selectedParcelId ?? 0;
          props.createExpenseTracking(expenseTracking);
        }}
      />
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.user, ParcelStore.actionCreators)(ExpenseTracking as any);
