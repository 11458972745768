import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ITokenResult } from '../models/ITokenResult';
import { IAdminSettings } from '../models/IAdminSettings';
import { push } from 'react-router-redux';
import { RouterAction } from 'connected-react-router';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface AdminSettingsState {
    countyList: Array<IAdminSettings>,
    currentAdminSettings?: IAdminSettings
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface CreateAdminSettingsAction { type: 'CREATE_ADMIN_SETTINGS_BEGIN', createAdminSettings: IAdminSettings }
export interface CreateAdminSettingsSuccessAction { type: 'CREATE_ADMIN_SETTINGS_SUCCESS' }
export interface CreateAdminSettingsErrorAction { type: 'CREATE_ADMIN_SETTINGS_ERROR' }

export interface UpdateAdminSettingsAction { type: 'UPDATE_ADMIN_SETTINGS_BEGIN', updateAdminSettings: IAdminSettings }
export interface UpdateAdminSettingsSuccessAction { type: 'UPDATE_ADMIN_SETTINGS_SUCCESS' }
export interface UpdateAdminSettingsErrorAction { type: 'UPDATE_ADMIN_SETTINGS_ERROR' }

export interface GetAdminSettingssAction { type: 'GET_ALL_ADMIN_SETTINGS_BEGIN' }
export interface GetAdminSettingssSuccessAction { type: 'GET_ALL_ADMIN_SETTINGS_SUCCESS', data: Array<IAdminSettings> }
export interface GetAdminSettingssErrorAction { type: 'GET_ALL_ADMIN_SETTINGS_ERROR' }

export interface GetAdminSettingsAction { type: 'GET_ADMIN_SETTINGS_BEGIN', id: number }
export interface GetAdminSettingsSuccessAction { type: 'GET_ADMIN_SETTINGS_SUCCESS', data: IAdminSettings }
export interface GetAdminSettingsErrorAction { type: 'GET_ADMIN_SETTINGS_ERROR' }

export interface ClearCurrentAdminSettings { type: 'CLEAR_CURRENT_ADMIN_SETTINGS' }


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = CreateAdminSettingsAction | CreateAdminSettingsSuccessAction | CreateAdminSettingsErrorAction |
    UpdateAdminSettingsAction | UpdateAdminSettingsSuccessAction | UpdateAdminSettingsErrorAction |
    GetAdminSettingssAction | GetAdminSettingssSuccessAction | GetAdminSettingssErrorAction |
    GetAdminSettingsAction | GetAdminSettingsSuccessAction | GetAdminSettingsErrorAction |
    ClearCurrentAdminSettings | RouterAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    // createAdminSettings: (createAdminSettings: ICreateAdminSettings) => ({ type: 'CREATE_ADMIN_SETTINGS_BEGIN', createAdminSettings: createAdminSettings } as CreateAdminSettingsAction),
    createAdminSettingsSuccess: () => ({ type: 'CREATE_ADMIN_SETTINGS_SUCCESS' } as CreateAdminSettingsSuccessAction),
    createAdminSettingsError: () => ({ type: 'CREATE_ADMIN_SETTINGS_ERROR' } as CreateAdminSettingsErrorAction),
    createAdminSettings: (createAdminSettings: IAdminSettings): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            fetch(`api/adminsettings`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(createAdminSettings)
            })
                .then(response => {
                    var data = response.json() as Promise<ITokenResult>;
                    return data;
                })
                .then(() => {
                    dispatch({ type: 'CREATE_ADMIN_SETTINGS_SUCCESS' });
                    dispatch(push('/adminsettings'));
                });

            dispatch({ type: 'CREATE_ADMIN_SETTINGS_BEGIN', createAdminSettings });
        }
    },
    updateAdminSettingsSuccess: () => ({ type: 'UPDATE_ADMIN_SETTINGS_SUCCESS' } as UpdateAdminSettingsSuccessAction),
    updateAdminSettingsError: () => ({ type: 'UPDATE_ADMIN_SETTINGS_ERROR' } as UpdateAdminSettingsErrorAction),
    updateAdminSettings: (updateAdminSettings: IAdminSettings): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            fetch(`api/adminsettings`, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(updateAdminSettings)
            })
                .then(response => {
                    var data = response.json() as Promise<ITokenResult>;
                    return data;
                })
                .then(() => {
                    dispatch({ type: 'UPDATE_ADMIN_SETTINGS_SUCCESS' });
                    dispatch(push(`/adminsettings`));
                });

            dispatch({ type: 'UPDATE_ADMIN_SETTINGS_BEGIN', updateAdminSettings });
        }
    },
    getAdminSettingss: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            fetch(`api/adminsettings`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                //body: JSON.stringify(createAdminSettings)
            })
                .then(response => {
                    var data = response.json() as Promise<Array<IAdminSettings>>;
                    return data;
                })
                .then(data => {
                    dispatch({ type: 'GET_ALL_ADMIN_SETTINGS_SUCCESS', data });
                });

            dispatch({ type: 'GET_ALL_ADMIN_SETTINGS_BEGIN' });
        }
    },
    getAdminSettingssSuccess: (data: any) => ({ type: 'GET_ALL_ADMIN_SETTINGS_SUCCESS', data: data } as GetAdminSettingssSuccessAction),
    getAdminSettings: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (false && appState && appState.adminSettings) {
            fetch(`api/adminsettings/${id}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                //body: JSON.stringify(createAdminSettings)
            })
                .then(response => {
                    var data = response.json() as Promise<IAdminSettings>;
                    return data;
                })
                .then(data => {
                    dispatch({ type: 'GET_ADMIN_SETTINGS_SUCCESS', data });
                });

            dispatch({ type: 'GET_ADMIN_SETTINGS_BEGIN', id });
        }
    },
    getAdminSettingsSuccess: (data: any) => ({ type: 'GET_ADMIN_SETTINGS_SUCCESS', data: data } as GetAdminSettingsSuccessAction),
    clearCurrentAdminSettings: () => ({ type: 'CLEAR_CURRENT_ADMIN_SETTINGS' } as ClearCurrentAdminSettings)
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<AdminSettingsState> = (state: AdminSettingsState | undefined, incomingAction: Action): AdminSettingsState => {
    if (state === undefined) {
        return {
            countyList: [],
            currentAdminSettings: undefined
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CREATE_ADMIN_SETTINGS_BEGIN':
            return {
                ...state
            };
        case 'GET_ALL_ADMIN_SETTINGS_SUCCESS':
            return {
                ...state,
                countyList: (action as GetAdminSettingssSuccessAction).data
            };
        case 'GET_ADMIN_SETTINGS_SUCCESS':
            return {
                ...state,
                currentAdminSettings: (action as GetAdminSettingsSuccessAction).data
            };
        case 'CLEAR_CURRENT_ADMIN_SETTINGS':
            return {
                ...state,
                currentAdminSettings: undefined
            };
        default:
            return state;
    }
};
