import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../store";
import * as UserStore from "../../store/user";
import { ICreateUser } from "../../models/ICreateUser";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { AccountType } from "../../models/enums/accountType";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { Fieldset } from "primereact/fieldset";

type SignupProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

class Signup extends React.PureComponent<SignupProps, ICreateUser> {
  constructor(props: any) {
    super(props);

    this.state = {
      Username: "",
      Password: "",
      PasswordConfirm: "",
      FirstName: "",
      LastName: "",
      Phone: "",
      Email: "",
      AccountType: AccountType.TaxPayer,
      Address1: "",
      Address2: "",
      City: "",
      State: "",
      Zip: "",
    };
  }

  public render() {
    const query = new URLSearchParams(this.props.location.search);
    const fromParcelId = query.get("parcelId") ?? "0";

    return (
      <React.Fragment>
        <div className="p-grid">
          <div className="p-col-4"></div>
          <div className="p-col-4">
            <Card title="Create an Account" subTitle="">
              <label>User Type:</label>
              <br />
              <Dropdown
                value={this.state.AccountType}
                options={[
                  { label: "TaxPayer", value: "2" },
                  { label: "County Official", value: "1" },
                  { label: "Legal Representative", value: "3" },
                  { label: "Administrator", value: "0" },
                ]}
                onChange={(e) => {
                  this.setState({ AccountType: e.value });
                }}
                placeholder="Select an Account Type"
              />
              <br />
              <br />
              <label>Username:</label>
              <br />
              <InputText
                name="username"
                value={this.state.Username}
                onChange={(e) => this.setState({ Username: (e.target as unknown as HTMLTextAreaElement).value })}
              />
              <br />
              <br />
              <label>Password:</label>
              <br />
              <Password
                feedback={false}
                value={this.state.Password}
                onChange={(e) => this.setState({ Password: (e.target as unknown as HTMLTextAreaElement).value })}
              />{" "}
              <br />
              <br />
              <label>Confirm Password:</label>
              <br />
              <Password
                feedback={false}
                value={this.state.PasswordConfirm}
                onChange={(e) => this.setState({ PasswordConfirm: (e.target as unknown as HTMLTextAreaElement).value })}
              />
              <br />
              <br />
              <label>First Name:</label>
              <br />
              <InputText
                name="username"
                value={this.state.FirstName}
                onChange={(e) => this.setState({ FirstName: (e.target as unknown as HTMLTextAreaElement).value })}
              />
              <br />
              <br />
              <label>Last Name:</label>
              <br />
              <InputText
                name="username"
                value={this.state.LastName}
                onChange={(e) => this.setState({ LastName: (e.target as unknown as HTMLTextAreaElement).value })}
              />
              <br />
              <br />
              <label>Email:</label>
              <br />
              <InputText
                name="username"
                value={this.state.Email}
                onChange={(e) => this.setState({ Email: (e.target as unknown as HTMLTextAreaElement).value })}
              />
              <br />
              <br />
              <label>Phone:</label>
              <br />
              {/* <InputText
                name="username"
                value={this.state.Phone}
                onChange={(e) => this.setState({ Phone: (e.target as unknown as HTMLTextAreaElement).value })}
              /> */}
              <InputMask
                id="phone"
                mask="(999) 999-9999"
                placeholder="(999) 999-9999"
                value={this.state.Phone}
                onChange={(e) => this.setState({ Phone: (e.target as unknown as HTMLTextAreaElement).value })}
              ></InputMask>
              <br />
              <br />
              {this.state.AccountType == 2 && (
                <Fieldset legend="Mailing Address">
                  <label>Address 1:</label>
                  <br />
                  <InputText
                    name="address1"
                    value={this.state.Address1}
                    onChange={(e) => this.setState({ Address1: (e.target as unknown as HTMLTextAreaElement).value })}
                  />
                  <br />
                  <br />
                  <label>Address 2:</label>
                  <br />
                  <InputText
                    name="address2"
                    value={this.state.Address2}
                    onChange={(e) => this.setState({ Address2: (e.target as unknown as HTMLTextAreaElement).value })}
                  />
                  <br />
                  <br />
                  <label>City:</label>
                  <br />
                  <InputText
                    name="city"
                    value={this.state.City}
                    onChange={(e) => this.setState({ City: (e.target as unknown as HTMLTextAreaElement).value })}
                  />
                  <br />
                  <br />
                  <label>State:</label>
                  <br />
                  <InputText
                    name="state"
                    value={this.state.State}
                    onChange={(e) => this.setState({ State: (e.target as unknown as HTMLTextAreaElement).value })}
                  />
                  <br />
                  <br />
                  <label>Zip:</label>
                  <br />
                  <InputText name="zip" value={this.state.Zip} onChange={(e) => this.setState({ Zip: (e.target as unknown as HTMLTextAreaElement).value })} />
                  <br />
                  <br />
                </Fieldset>
              )}
              <Button
                label="Sign Up"
                onClick={() => {
                  if (
                    this.state?.Email === "" ||
                    this.state?.FirstName === "" ||
                    this.state?.LastName === "" ||
                    this.state?.Phone === "" ||
                    this.state?.Username === ""
                  ) {
                    alert("All fields are required!");
                  } else {
                    if (this.state?.Password !== this.state.PasswordConfirm) {
                      alert("Passwords do not match!");
                    } else {
                      if (this.state.AccountType != AccountType.TaxPayer) {
                        if (confirm("Are you sure you want to create a non-taxpayer account?  These accounts are not for general public use and require approval.  Press OK to continue or Cancel to change your account type.")) {
                          this.props.createUser(this.state, fromParcelId);
                          //alert("Creating User...");
                        }
                        else {
                          //this.props.createUser(this.state, fromParcelId);
                          //alert("Go change your type...");
                        }
                      }
                      else {
                        this.props.createUser(this.state, fromParcelId);
                        //alert("Creating User...");
                      }
                    }
                  }
                }}
              />
              <br />
              <br />
              <br />
              <p className="errorText">{this.props.createUserErrorMessage}</p>
            </Card>
          </div>
          <div className="p-col-2"></div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(Signup as any);
