import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import * as UserStore from '../../store/user';
import { ICreateUser } from '../../models/ICreateUser';
import { Card } from 'primereact/card';

type ConfirmationProps =
    UserStore.UserState &
    typeof UserStore.actionCreators &
    RouteComponentProps<{}>;


class Confirmation extends React.PureComponent<ConfirmationProps, ICreateUser> {

    public render() {
        return (
            <React.Fragment>
                <Card title="Success!" subTitle="">

                    <h2>User account was created successfully.   <br/><br/>We will be in touch once your account has been approved for login.</h2>
                </Card>


            </React.Fragment>
        );
    }
};

export default connect(
    (state: ApplicationState) => state.user,
    UserStore.actionCreators,
)(Confirmation as any);
