import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../store";
import * as UserStore from "../../store/user";
import { ICreateUser } from "../../models/ICreateUser";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { AccountType } from "../../models/enums/accountType";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

type ResetPasswordProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

class ResetPassword extends React.PureComponent<ResetPasswordProps, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      password: "",
      token: "",
    };
  }

  componentDidMount() {
    const tokenParam = (this.props.match.params as any).token;
    this.setState({ token: tokenParam });
  }

  public render() {
    return (
      <React.Fragment>
        <Card title="Reset your password" subTitle="">
          <form
            onSubmit={(event) => {
              event.preventDefault();
              this.props.resetPassword(this.state.password, this.state.token);
            }}
          >
            <label>New Password:</label>
            <br />
            <Password
              feedback={false}
              value={this.state.password}
              onChange={(e) =>
                this.setState({
                  password: (e.target as unknown as HTMLTextAreaElement).value,
                })
              }
            />
            <br />
            <br />
            <button className={"p-button p-component p-button-text-only"}>
              <span className={"p-button-text p-c"}>Reset Password</span>
            </button>
          </form>
        </Card>
      </React.Fragment>
    );
  }
}

export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(ResetPassword as any);
