import { Card } from "primereact/card";
import * as React from "react";
import { connect } from "react-redux";

const About = () => (
  <Card className="card-full-width" title={`Our Company`} subTitle="">
    <p>
      US Assets, LLC has been working with County Governments throughout the United States for over 22 years. Located in Omaha, Nebraska our team of seasoned
      professionals understands both the opportunities and pitfalls of tax certificate investing. We have established working relationships that benefit the
      community as well as the county.
    </p>

    <div>
      <h4>Background:</h4>
      <p>
        The collection of property taxes is a high priority for counties. This is what allows them to pay for the continued development and upkeep of their
        schools, streets, police and fire protection. Without the ability to collect these funds, the community suffers. To assist in this process, the counties
        will offer delinquent taxes as a lien against the property to outside investors such as ourselves. This allows the county to continue to run
        efficiently, allows the property owner extra time to pay their taxes and allows the investor a return on their funds.
        <br />
        <br />
        In the event the property owner does not pay back the funds, the county issues the investor a deed to allow them the opportunity to sell the real
        estate. Through this process, we have developed a portfolio of properties we hope to sell to individuals or companies that will refocus the property and
        return it to the tax rolls.
      </p>
      <h4>Mission Statement:</h4>
      <p>
        We are a company that values its workforce, maintains high ethical standards, optimizes profitability and makes a positive contribution that is mutually
        beneficial to us and the communities we serve.
      </p>
      <h4>Community Involvement:</h4>
      <p>
        As a company, we are exploring opportunities to support the restoration of our communities through partnerships with other businesses. For the last
        several years, we have partnered with Habitat for Humanity to help make someone’s dream a reality. &nbsp;
        {/* <a id="mainContent_hlCommunity" href="Community.aspx">
            (More...)
          </a> */}
      </p>
      <div>
        <img id="mainContent_imgHabitat" src="images/HabitatLogo.png" />
      </div>
    </div>
  </Card>
);

export default connect()(About);
