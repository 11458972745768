import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../../store";
import * as ParcelStore from "../../../../store/parcel";
import * as UserStore from "../../../../store/user";
import { DataTable, DataTableExpandedRows, DataTableFilterMeta } from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { Button } from "primereact/button";
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { NavItem, NavLink } from "reactstrap";
import { Tag } from 'primereact/tag';
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { IUser } from "../../../../models/IUser";
import LegalParcelDetails from "../details/legalParcelDetails";
import { IParcel } from "../../../../models/IParcel";

type LegalParcelListProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & RouteComponentProps<{}>;

function LegalParcelList(props: LegalParcelListProps) {
  const [selectedState, setSelectedState] = useState("OH");
  const [states, setStates] = useState([{ label: "OH", value: "OH" }]);
  const [address, setAddress] = useState("");
  const [selectedCountyId, setSelectedCountyId] = useState((props.match.params as any).countyId);
  const [ownerInfo, setOwnerInfo] = useState("");
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
  const [selectedActiveFilterValue, setSelectedActiveFilterValue] = useState(undefined as any);
  const [selectedStatusFilterValue, setSelectedStatusFilterValue] = useState(undefined as any);
  const [currentUserId, setCurrentUserId] = useState(0);
  const [isMyParcels, setIsMyParcels] = useState(false);
  const [filterActive, setFilterActive] = useState(true);
  const [isUnassignedParcels, setIsUnassignedParcels] = useState(false);
  const [currentUser, setCurrentUser] = useState({} as IUser);
  const [attorneys, setAttorneys] = useState([] as IUser[]);
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | undefined | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [statuses] = useState<string[]>(['Active Certificate', 'REO', 'Legal', 'Bankruptcy', 'Redeemed']);
  const [attorneyList, setAttorneyList] = useState<string[]>([]);
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    parcelNo: { value: '', matchMode: FilterMatchMode.CONTAINS },
    taxSaleNo: { value: '', matchMode: FilterMatchMode.CONTAINS },
    ownerName: { value: '', matchMode: FilterMatchMode.CONTAINS },
    propertyAddressFull: { value: '', matchMode: FilterMatchMode.CONTAINS },
    caseNum: { value: '', matchMode: FilterMatchMode.CONTAINS },
    webAssignedAttorneyUserName: { value: '', matchMode: FilterMatchMode.CONTAINS },
    isActive: { value: true, matchMode: FilterMatchMode.EQUALS },
    webStatus: { value: '', matchMode: FilterMatchMode.EQUALS },
    webRefNum: { value: '', matchMode: FilterMatchMode.EQUALS },
  });

  //let dt: DataTable | null | undefined;
  const dt = useRef<DataTable<IParcel[]>>(null);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);

    const countyID = query.get("countyID");
    setSelectedCountyId(countyID);

    const isMine = query.get("mine");
    const isUnassigned = query.get("unassigned");

    if (isMine == "true") {
      props.getMyCountyParcels(countyID as unknown as number);
      setIsMyParcels(true);
    } else {
      if (isUnassigned == "true") {
        props.getCountyParcels(countyID as unknown as number, true);
      }
      else {
        props.getCountyParcels(countyID as unknown as number, false);
      }

      setIsMyParcels(false);
    }


    if (isUnassigned == "true") {
      setIsUnassignedParcels(true);
    } else {
      setIsUnassignedParcels(false);
    }

    setLoading(false);
  }, [props.location]);

  useEffect(() => {
    setCurrentUser(props.currentUser);
    props.getAttorneyUsers(props.currentUser.attorneyID);
  }, [props.currentUser]);

  useEffect(() => {
    setAttorneys(props.attorneyUsers);
    if (props.attorneyUsers.length > 0) {
      var list = [] as string[];
      props.attorneyUsers.map((attorney) => {

        list.push(attorney.lastName + ", " + attorney.firstName);
      });
      setAttorneyList(list);
    }
  }, [props.attorneyUsers]);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const isUnassigned = query.get("unassigned");
    const countyID = query.get("countyID");
    setSelectedCountyId(countyID);
    const isMine = query.get("mine");
    if (isMine == "true") {
      props.getMyCountyParcels(countyID as unknown as number);
      setIsMyParcels(true);
    } else {
      if (isUnassigned == "true") {
        props.getCountyParcels(countyID as unknown as number, true);
      }
      else {
        props.getCountyParcels(countyID as unknown as number, false);
      }
      setIsMyParcels(false);
    }

    if (isUnassigned == "true") {
      setIsUnassignedParcels(true);

    } else {
      setIsUnassignedParcels(false);
    }

    if (localStorage.getItem("token")) {
      setCurrentUserId((jwt_decode(localStorage.getItem("token") as string) as any).UserId);
      props.getCurrentUser((jwt_decode(localStorage.getItem("token") as string) as any).UserId);
    }

    if (currentUserId > 0)
      props.getAttorneyUsers(props.currentUser.attorneyID);

    setLoading(false);
  }, []);

  useEffect(() => {
    if (dt && dt.current != null) {
      dt.current.filter(true, "isActive", "equals");


      if (isUnassignedParcels) {

        dt.current.filter("", "webAssignedAttorneyUserName", "notEquals");
      }
    }
    //setSelectedActiveFilterValue(true);
    if (dt && dt.current != null) {
      dt.current.filter(selectedActiveFilterValue, "isActive", "equals");
    }
  }, [props.parcelList]);


  const nameTemplate: any = (rowData: any) => {
    return `${rowData.lastName}, ${rowData.firstName}`;
  };

  const onExport = () => {
    if (dt && dt.current != null) { dt.current.exportCSV({ selectionOnly: false }); }
  };

  const handleRowClick: any = (e: any) => {
    //props.history.push(`/county/${e.data.countyID}/parcels/${e.data.parcelID}?fromLegal=true&fromAdmin=false`);
  };

  const exportExcel: any = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(props.parcelList);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
      saveAsExcelFile(excelBuffer, "products");
    });
  };

  const saveAsExcelFile: any = (buffer: any, fileName: any) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };

    // @ts-ignore
    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const header = (
    <div style={{ textAlign: "left" }}>
      <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
      <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search Records" />
      <Button className="float-right" type="button" icon="pi pi-external-link" iconPos="left" label="Export to CSV" onClick={onExport}></Button>
      {/* <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success mr-2" data-pr-tooltip="XLS" /> */}
    </div>
  );

  const formatAddress: any = (rowData: any) => {
    return (
      <div>
        {rowData.propertyAddress}
        <br />
        {rowData.propertyCity}, {rowData.stateID}, {rowData.propertyZip}
      </div>
    );
  };

  const [boolText, setBoolText] = useState([
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]);

  const activeFilter = (options: ColumnFilterElementTemplateOptions) => {
    return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
  };

  const formatDate: any = (date: any) => {
    let value = new Date(date);
    return value.toLocaleString().split(",")[0];
  };

  const formatFieldAsDate: any = (rowData: any) => {
    return formatDate(rowData.Field);
  };

  const formatBankruptcyInDateAsDate: any = (rowData: any) => {
    return formatDate(rowData.bankruptcyInDate);
  };
  const formatBankruptcyOutDateAsDate: any = (rowData: any) => {
    return formatDate(rowData.bankruptcyOutDate);
  };
  const formatPocFiledAsDate: any = (rowData: any) => {
    return formatDate(rowData.pocFiled);
  };
  const formatRtReceivedDateAsDate: any = (rowData: any) => {
    return formatDate(rowData.rtReceivedDate);
  };
  const formatDismissalFiledAsDate: any = (rowData: any) => {
    return formatDate(rowData.dismissalFiled);
  };
  const formatSentLegalFileAsDate: any = (rowData: any) => {
    return formatDate(rowData.sentLegalFile);
  };
  const formatNoiFiledDateAsDate: any = (rowData: any) => {
    return formatDate(rowData.noiFiledDate);
  };
  const formatLienSearchDateAsDate: any = (rowData: any) => {
    return formatDate(rowData.lienSearchDate);
  };
  const formatLienSearchReceivedDateAsDate: any = (rowData: any) => {
    return formatDate(rowData.lienSearchReceivedDate);
  };
  const formatComplaintFiledDateAsDate: any = (rowData: any) => {
    return formatDate(rowData.complaintFiledDate);
  };
  const formatPublicationDateAsDate: any = (rowData: any) => {
    return formatDate(rowData.publicationDate);
  };
  const formatPublicationReceivedDateAsDate: any = (rowData: any) => {
    return formatDate(rowData.publicationReceivedDate);
  };
  const formatAllPartiesServedAsDate: any = (rowData: any) => {
    return formatDate(rowData.allPartiesServed);
  };
  const formatFileMSJPDAsDate: any = (rowData: any) => {
    return formatDate(rowData.fileMSJPD);
  };
  const formatJudgmentEnteredAsDate: any = (rowData: any) => {
    return formatDate(rowData.judgmentEntered);
  };
  const formatWritRequestedAsDate: any = (rowData: any) => {
    return formatDate(rowData.writRequested);
  };
  const formatSheriffSaleDateAsDate: any = (rowData: any) => {
    return formatDate(rowData.sheriffSaleDate);
  };
  const formatSecondSheriffsSaleDateAsDate: any = (rowData: any) => {
    return formatDate(rowData.secondSheriffsSaleDate);
  };
  const formatMotionConfirmSaleAsDate: any = (rowData: any) => {
    return formatDate(rowData.motionConfirmSale);
  };
  const formatOrderConfirmSaleAsDate: any = (rowData: any) => {
    return formatDate(rowData.orderConfirmSale);
  };
  const formatMotionForForfeitureAsDate: any = (rowData: any) => {
    return formatDate(rowData.motionForForfeiture);
  };
  const formatDecreeOfForfeitureAsDate: any = (rowData: any) => {
    return formatDate(rowData.decreeOfForfeiture);
  };
  const formatTaxDeedIssuedAsDate: any = (rowData: any) => {
    return formatDate(rowData.taxDeedIssued);
  };

  const isActiveLegalTemplate: any = (rowData: any) => {
    let isActive = rowData.isActive as boolean;

    if (isActive) {
      return <span>Yes</span>;
    } else {
      return <span>No</span>;
    }
  };

  const getSeverity = (status: string) => {
    switch (status) {
      case 'Active Certificate':
        return 'success';

      case 'Redeemed':
        return 'success';

      case 'Legal':
        return 'warning';

      case 'Bankruptcy':
        return 'danger';

      case 'REO':
        return 'info';
    }
  };

  const statusBodyTemplate = (rowData: any) => {
    return <Tag value={rowData.status} severity={getSeverity(rowData.webStatus)} className={rowData.webStatus} />;
  };

  const statusItemTemplate = (option: any) => {
    return <Tag value={option} severity={getSeverity(option)} className={option} />;
  };

  const statusRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
    );
  };

  const assignedUserRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown value={options.value} options={attorneyList} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
    );
  };


  const rowExpansionTemplate = (data: any) => {
    return (
      <LegalParcelDetails selectedParcelId={data.parcelID} {...props} />
    );
  }

  const onRowToggle: any = (data: any) => {
    // console.log(data);
    // //Collapse them all
    // let _expandedRows: DataTableExpandedRows = {};
    // setExpandedRows(_expandedRows);

    // // products.forEach((p) => (_expandedRows[`${p.id}`] = true));
    // var firstValue = Object.keys(data.data)[0];
    // if (firstValue == undefined) {
    //   firstValue = Object.keys(data.data)[1];
    // }

    // _expandedRows[`${firstValue}`] = true;
    // setExpandedRows(_expandedRows);
    //}
    setExpandedRows(data.data);
  }


  const expandRow: any = (data: any) => {

  }

  const allowExpansion: any = (rowData: any) => {
    return true;
  };


  // Extra Stuff
  const onRowExpand = (event: any) => {
    //alert("Expand " + JSON.stringify(event.data));
    setExpandedRows({ [event.data.parcelID]: true });
  };

  const onRowCollapse = (event: any) => {
    //alert("Collapse " + JSON.stringify(event.data));
    setExpandedRows(null);
  };

  const expandAll = () => {
    let _expandedRows: any = {};

    props.parcelList.forEach((p) => (_expandedRows[`${p.parcelID}`] = true));

    setExpandedRows(_expandedRows);
  };

  const collapseAll = () => {
    setExpandedRows(null);
  };



  return (
    <React.Fragment>
      <div style={{ width: '120%', marginLeft: '-10%' }}>

        {isMyParcels && (<Card title={`My Assigned Parcels`} subTitle={``} style={{}}></Card>)}
        {isUnassignedParcels && (<Card title={`Unassigned Parcels`} subTitle={``} style={{}}></Card>)}
        {!isMyParcels && !isUnassignedParcels && (<Card title={`All Parcels`} subTitle={``} style={{}}></Card>)}

        <br />
        {props.parcelList?.length > 0 ? (
          <DataTable
            dataKey="parcelID"
            filterDisplay="row"
            //ref={dt}
            className={"p-datatable-sm"}
            value={props.parcelList}
            onRowClick={($event) => handleRowClick($event)}
            selectionMode="single"
            stripedRows
            header={header}
            globalFilter={globalFilterValue}
            filters={filters}
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            rows={15}
            rowsPerPageOptions={[5, 10, 20, 50]}
            expandedRows={expandedRows as any}
            //onRowToggle={onRowToggle}
            onRowExpand={onRowExpand}
            onRowCollapse={onRowCollapse}
            rowExpansionTemplate={rowExpansionTemplate}
            emptyMessage="No records found."
          //stateStorage="local"
          //stateKey="dt-state-local-legal"
          >
            <Column expander={allowExpansion} style={{ width: '3em' }} />
            <Column field="parcelNo" header="Parcel Number" sortable={true} showFilterMenu={false} filter filterPlaceholder="Search by Parcel" />
            <Column field="taxSaleNo" header="Tax Certificate Number" sortable={true} showFilterMenu={false} filter filterPlaceholder="Search by Certificate #" />
            <Column field="webStatus" header="Status" sortable={true} showFilterMenu={false} filter filterElement={statusRowFilterTemplate} body={statusBodyTemplate} />
            <Column field="ownerName" header="Owner" sortable={true} showFilterMenu={false} filter filterPlaceholder="Search by Owner" />
            <Column field="propertyAddressFull" header="Property" sortable={true} showFilterMenu={false} filter filterPlaceholder="Search by Address" body={formatAddress} />
            <Column field="webAssignedAttorneyUserName" header="Assigned Attorney" sortable={true} showFilterMenu={false} filter filterPlaceholder="Search by Asignee" filterElement={assignedUserRowFilterTemplate} />
            {currentUser.showRefNumLegal && <Column field="webRefNum" header="Ref #" sortable={true} showFilterMenu={false} filter filterPlaceholder="Search by Ref Num" />}
            {<Column field="caseNum" header="Case #" sortable={true} showFilterMenu={false} filter filterPlaceholder="Search by Case Num" />}
            {/* <Column field="isActive" header="Active Legal" body={isActiveLegalTemplate} style={{ width: "200px" }} filter={true} filterElement={activeFilter} /> */}
            <Column field="isActive" header="Active Legal" body={isActiveLegalTemplate} showFilterMenu={false} filter filterElement={activeFilter} dataType="boolean" />



            {/* <Column field="payofF_TOTAL" header="Total Due" sortable={true} /> */}
            <Column field="stateName" header="stateName" style={{ display: "none" }} />
            <Column field="countyName" header="countyName" style={{ display: "none" }} />
            <Column field="taxSaleNo" header="taxSaleNo" style={{ display: "none" }} />
            <Column field="parcelNo" header="parcelNo" style={{ display: "none" }} />
            <Column field="ownerName" header="ownerName" style={{ display: "none" }} />
            <Column field="propertyAddress" header="propertyAddress" style={{ display: "none" }} />
            <Column field="propertyCity" header="propertyCity" style={{ display: "none" }} />
            <Column field="propertyZip" header="propertyZip" style={{ display: "none" }} />
            <Column field="caseNum" header="caseNum" style={{ display: "none" }} />
            <Column field="webRefNum" header="webRefNum" style={{ display: "none" }} />
            <Column field="webNotes" header="webNotes" style={{ display: "none" }} />

            <Column field="bankruptcyType" header="bankruptcyType" style={{ display: "none" }} body={formatDate} />
            <Column field="bankruptcyInDate" header="bankruptcyInDate" style={{ display: "none" }} body={formatBankruptcyInDateAsDate} />
            <Column field="bankruptcyOutDate" header="bankruptcyOutDate" style={{ display: "none" }} body={formatBankruptcyOutDateAsDate} />
            <Column field="pocFiled" header="pocFiled" style={{ display: "none" }} body={formatPocFiledAsDate} />
            <Column field="caseNum" header="caseNum" style={{ display: "none" }} />
            <Column field="rtReceivedDate" header="rtReceivedDate" style={{ display: "none" }} body={formatRtReceivedDateAsDate} />
            <Column field="dismissalFiled" header="dismissalFiled" style={{ display: "none" }} body={formatDismissalFiledAsDate} />
            <Column field="sentLegalFile" header="sentLegalFile" style={{ display: "none" }} body={formatSentLegalFileAsDate} />
            <Column field="noiFiledDate" header="noiFiledDate" style={{ display: "none" }} body={formatNoiFiledDateAsDate} />
            <Column field="lienSearchDate" header="lienSearchDate" style={{ display: "none" }} body={formatLienSearchDateAsDate} />
            <Column field="lienSearchReceivedDate" header="lienSearchReceivedDate" style={{ display: "none" }} body={formatLienSearchReceivedDateAsDate} />
            <Column field="complaintFiledDate" header="complaintFiledDate" style={{ display: "none" }} body={formatComplaintFiledDateAsDate} />
            <Column field="publicationDate" header="publicationDate" style={{ display: "none" }} body={formatPublicationDateAsDate} />
            <Column field="publicationReceivedDate" header="publicationReceivedDate" style={{ display: "none" }} body={formatPublicationReceivedDateAsDate} />
            <Column field="allPartiesServed" header="allPartiesServed" style={{ display: "none" }} body={formatAllPartiesServedAsDate} />
            <Column field="fileMSJPD" header="fileMSJPD" style={{ display: "none" }} body={formatFileMSJPDAsDate} />
            <Column field="judgmentEntered" header="judgmentEntered" style={{ display: "none" }} body={formatJudgmentEnteredAsDate} />
            <Column field="writRequested" header="writRequested" style={{ display: "none" }} body={formatWritRequestedAsDate} />
            <Column field="sheriffSaleDate" header="sheriffSaleDate" style={{ display: "none" }} body={formatSheriffSaleDateAsDate} />
            <Column field="secondSheriffsSaleDate" header="secondSheriffsSaleDate" style={{ display: "none" }} body={formatSecondSheriffsSaleDateAsDate} />
            <Column field="motionConfirmSale" header="motionConfirmSale" style={{ display: "none" }} body={formatMotionConfirmSaleAsDate} />
            <Column field="orderConfirmSale" header="orderConfirmSale" style={{ display: "none" }} body={formatOrderConfirmSaleAsDate} />
            <Column field="rtAmountReceived" header="rtAmountReceived" style={{ display: "none" }} />
            <Column field="motionForForfeiture" header="motionForForfeiture" style={{ display: "none" }} body={formatMotionForForfeitureAsDate} />
            <Column field="decreeOfForfeiture" header="decreeOfForfeiture" style={{ display: "none" }} body={formatDecreeOfForfeitureAsDate} />
            <Column field="taxDeedIssued" header="taxDeedIssued" style={{ display: "none" }} body={formatTaxDeedIssuedAsDate} />

            {/* <Column field="claimNum" header="claimNum" style={{ display: "none" }} />

          <Column field="countyID" header="countyID" style={{ display: "none" }} />

          <Column field="ohsAttorneyID" header="ohsAttorneyID" style={{ display: "none" }} />

          <Column field="parcelID" header="parcelID" style={{ display: "none" }} />

          <Column field="relatedTaxCertificates" header="relatedTaxCertificates" style={{ display: "none" }} />
          <Column field="resultOfSale" header="resultOfSale" style={{ display: "none" }} />

          <Column field="webAssignedAttorneyUserID" header="webAssignedAttorneyUserID" style={{ display: "none" }} /> */}
          </DataTable>
        ) : (
          <div>
            <ProgressSpinner />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.parcel, ParcelStore.actionCreators)(LegalParcelList as any);
