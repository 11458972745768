import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../../store";
import * as ParcelStore from "../../../../store/parcel";
import * as UserStore from "../../../../store/user";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { IParcelSearch } from "../../../../models/IParcelSearch";
import { Divider } from "primereact/divider";
import "../../../../../node_modules/primeflex/primeflex.scss";
import { ProgressSpinner } from "primereact/progressspinner";
import { IParcel } from "../../../../models/IParcel";

type ParcelListProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & RouteComponentProps<{}>;

function ParcelList(props: ParcelListProps) {
  const [selectedState, setSelectedState] = useState("OH");
  const [states, setStates] = useState([{ label: "OH", value: "OH" }]);
  const [selectedCounty, setSelectedCounty] = useState(855);
  const [counties, setCounties] = useState([
    {
      "label": "Summit",
      "value": 855,
      "state": "OH"
    },
    {
      "label": "Hamilton",
      "value": 854,
      "state": "OH"
    },
    {
      "label": "Franklin",
      "value": 856,
      "state": "OH"
    },
    {
      "label": "Pickaway",
      "value": 1032,
      "state": "OH"
    },
    {
      "label": "Montgomery",
      "value": 1033,
      "state": "OH"
    }
  ]);
  const [visibleCounties, setVisibleCounties] = useState([{ label: "Summit", value: 855 }]);
  const [address, setAddress] = useState("");
  const [ownerInfo, setOwnerInfo] = useState("");
  const [parcelNumber, setParcelNumber] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");
  const [showNoResults, setShowNoResults] = useState(false);
  const [showRequiredFieldsMessage, setShowRequiredFieldsMessage] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [parcelList, setParcelList] = useState([] as Array<IParcel>);

  const dt = useRef<DataTable<any[]>>(null);

  // useEffect(() => {
  //   setStates(
  //     props.states?.map(function (item) {
  //       return { label: item.stateID, value: item.stateID };
  //     }) ?? []
  //   );
  // }, [props.states]);

  useEffect(() => {
    props.getStates();
    props.getCounties();
  }, []);

  useEffect(() => {
    setStates(
      props.states?.map(function (item) {
        return { label: item.stateID, value: item.stateID };
      }) ?? []
    );
  }, [props.states]);

  useEffect(() => {
    setCounties(
      props.counties?.map(function (item) {
        return { label: item.countyName, value: item.countyID, state: item.stateID };
      }) ?? []
    );

    if (counties.length > 0) {
      var _visibleCounties = counties.filter((item) => {
        if (item.state == selectedState) {
          return true;
        }
        else {
          return false;
        }
      });


      setVisibleCounties(_visibleCounties);
      setSelectedCounty(_visibleCounties[0].value);
    }
  }, [props.counties]);

  useEffect(() => {
    if (counties.length > 0) {
      var _visibleCounties = counties.filter((item) => {
        if (item.state == selectedState) {
          return true;
        }
        else {
          return false;
        }
      });

      setVisibleCounties(_visibleCounties);
      setSelectedCounty(_visibleCounties[0].value);
    }
  }, [selectedState]);

  useEffect(() => {
    setIsSearching(props.isSearching);
  }, [props.isSearching]);

  useEffect(() => {
    setParcelList(props.parcelList);
    if (props.parcelList.length === 0) {
      setShowNoResults(true);
    } else {
      setShowNoResults(false);
    }
  }, [props.parcelList]);

  useEffect(() => {
    return () => {
      //props.clearCurrentParcels();
    };
  }, []);

  const nameTemplate: any = (rowData: any) => {
    return `${rowData.lastName}, ${rowData.firstName}`;
  };

  const formatPurchaseDate: any = (rowData: any) => {
    let value = new Date(rowData.purchaseDate);

    return value.toLocaleString().split(",")[0];
  };

  const formatStatus: any = (rowData: any) => {
    if (rowData?.rtReceivedDate != null && rowData.rtReceivedDate) {
      return <span>Redeemed</span>;
    } else {
      return <span>Active</span>;
    }
  };

  const formatAddress: any = (rowData: any) => {
    return (
      <div>
        {rowData.propertyAddress}
        <br />
        {rowData.propertyCity}, {rowData.stateID}, {rowData.propertyZip}
      </div>
    );
  };

  const onExport = () => {
    if (dt && dt.current != null) dt.current.exportCSV({ selectionOnly: false });
  };

  const handleRowClick: any = (e: any) => {
    props.history.push(`/parcels/${e.data.parcelID}`);
  };

  const handleSearch: any = (source: any) => {
    if (source == "address") {
      if (selectedState && selectedCounty && (address || ownerInfo)) {
        props.clearCurrentParcels();
        props.searchParcels(
          { state: selectedState, county: selectedCounty, address: address, ownerInfo: ownerInfo, parcelNumber: parcelNumber } as IParcelSearch,
          props.history
        );
        setHasSearched(true);
        setShowRequiredFieldsMessage(false);
      } else {
        props.clearCurrentParcels();
        setShowRequiredFieldsMessage(true);
        setShowNoResults(false);
        setHasSearched(false);
      }
    } else if (source == "parcel") {
      if (parcelNumber && parcelNumber.length > 4) {
        props.searchParcels(
          { state: selectedState, county: selectedCounty, address: address, ownerInfo: ownerInfo, parcelNumber: parcelNumber } as IParcelSearch,
          props.history
        );
        setHasSearched(true);
        setShowRequiredFieldsMessage(false);
      }
      // else if (parcelNumber && parcelNumber.length < 5) {
      //   alert("You must enter at least 5 numbers of your parcel number.");
      // }
      else {
        props.clearCurrentParcels();
        setShowRequiredFieldsMessage(true);
        setShowNoResults(false);
        setHasSearched(false);
      }
    }
  };

  const header = (
    <div style={{ textAlign: "left" }}>
      <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
      <InputText
        type="search"
        onInput={(e) => setGlobalFilter((e.target as unknown as HTMLTextAreaElement).value)}
        placeholder="Filter List"
        size={50}
        style={{ width: "300px", marginLeft: "8px" }}
      />
    </div>
  );

  // const approvedFilter = (
  //   <Dropdown
  //     style={{ width: "100%" }}
  //     placeholder="Select a value"
  //     value={selectedApprovedFilterValue}
  //     options={boolText}
  //     onChange={onApprovedFilterValueChange}
  //     showClear
  //   />
  // );

  const paginatorLeft = <Button icon="pi pi-refresh" />;

  return (
    <React.Fragment>
      <Card
        title="Lien Payoff"
        subTitle="To find out the balance owed to pay off your outstanding tax certificate, search for your lien by selecting your state and county below, and then either
      the property address, parcel number or owner's name."
      ></Card>
      <div className="card" style={{ paddingTop: "20px", paddingLeft: "7px" }}>
        <div className="p-d-flex p-flex-column p-flex-md-row p-jc-center">
          <div className="p-mb-2 p-mr-2">
            <div className="p-fluid">
              <div className="p-field">
                <label className="field-label">Parcel Number:</label>
                <br />
                <InputText
                  name="parcelNumber"
                  className={showRequiredFieldsMessage ? "p-invalid" : ""}
                  value={parcelNumber}
                  onChange={(e) => setParcelNumber((e.target as unknown as HTMLTextAreaElement).value)}
                  onKeyDown={(e) => e.key === "Enter" && handleSearch("parcel")}
                />
              </div>
              <Button
                label="Lookup"
                onClick={() => {
                  handleSearch("parcel");
                }}
              />
            </div></div>
          <div className="p-mb-2 p-mr-2 p-d-sm p-d-md-none" style={{ width: "100%" }}>
            <Divider layout="vertical">
              <b>OR</b>
            </Divider>
          </div>
          <div className="p-mb-2 p-mr-2 p-d-none p-d-md-inline" style={{ width: "20%" }}>
            <Divider layout="vertical">
              <b>OR</b>
            </Divider>
          </div>
          <div className="p-mb-2 p-mr-2">
            <div className="p-grid">
              <div className="p-col-6">
                <label className="field-label">State:</label>
                <br />
                <Dropdown
                  value={selectedState}
                  options={states}
                  onChange={(e) => {
                    setSelectedState(e.value);
                  }}
                  placeholder="Select A State"
                />
              </div>
              <div className="p-col-6">
                <label className="field-label">County:</label>
                <br />
                <Dropdown
                  value={selectedCounty}
                  options={visibleCounties}
                  onChange={(e) => {
                    setSelectedCounty(e.value);
                  }}
                  placeholder="Select A County"
                />
              </div>
            </div>
            <br />
            <div className="p-grid">
              <div className="p-col-6 p-fluid">
                <label className="field-label">Address:</label>
                <br />
                <InputText
                  name="address"
                  id="address"
                  autoFocus
                  className={showRequiredFieldsMessage ? "p-invalid" : ""}
                  value={address}
                  onChange={(e) => setAddress((e.target as unknown as HTMLTextAreaElement).value)}
                  onKeyDown={(e) => e.key === "Enter" && handleSearch("address")}
                />
              </div>
              <div className="p-col-6  p-fluid">
                <label className="field-label">Owner Name:</label>
                <br />
                <InputText
                  name="ownerInfo"
                  placeholder="Format: Last First"
                  className={showRequiredFieldsMessage ? "p-invalid" : ""}
                  value={ownerInfo}
                  onChange={(e) => setOwnerInfo((e.target as unknown as HTMLTextAreaElement).value)}
                  onKeyDown={(e) => e.key === "Enter" && handleSearch("address")}
                />
              </div>
            </div>
            <div className="p-grid">
              <div className="p-col-6 p-fluid">
                <Button
                  style={{ marginTop: "6px" }}
                  className="p-button-secondary p-button-outlined"
                  label="Clear"
                  onClick={() => {
                    setParcelNumber("");
                    setAddress("");
                    setOwnerInfo("");
                    setParcelList([] as Array<IParcel>);
                  }}
                />

              </div>
              <div className="p-col-6 p-fluid">
                <Button
                  style={{ marginTop: "6px" }}
                  label="Search"
                  onClick={() => {
                    handleSearch("address");
                  }}
                />
              </div>
            </div>

          </div>
        </div>
      </div>
      <br />
      {props.parcelList.length > 0 ? (
        <DataTable
          filterDisplay="row"
          ref={dt}
          value={parcelList}
          onRowClick={($event) => handleRowClick($event)}
          selectionMode="single"
          header={header}
          globalFilter={globalFilter}
          paginator={true}
          paginatorLeft={paginatorLeft}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rows={10}
          rowsPerPageOptions={[5, 10, 20, 50]}
        // stateStorage="local"
        // stateKey="dt-state-local-payer"
        >
          <Column field="taxSaleNo" header="Certificate Number" sortable={true} />
          <Column field="parcelNo" header="Parcel Number" sortable={true} />
          <Column field="status" header="Status" sortable={true} />
          <Column field="purchaseDate" header="Purchase Date" sortable={true} body={formatPurchaseDate} />
          <Column field="ownerName" header="Owner" sortable={true} />
          <Column field="propertyAddress" header="Property Address" sortable={true} body={formatAddress} />
          {/* <Column field="payofF_TOTAL" header="Amount Owed" sortable={true} /> */}
        </DataTable>
      ) : null}
      {showNoResults && hasSearched && !isSearching && (
        <Card>
          <h3 style={{ textAlign: "center" }}>No results. Please refine your search criteria above.</h3>
        </Card>
      )}
      {isSearching && (
        <Card>
          <h3 style={{ textAlign: "center" }}>
            <ProgressSpinner />
          </h3>
        </Card>
      )}
      {showRequiredFieldsMessage && (
        <Card>
          <h3 style={{ textAlign: "center" }}>You must search by parcel(at least 5 numbers) or enter an address or owner name.</h3>
        </Card>
      )}
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.parcel, ParcelStore.actionCreators)(ParcelList as any);
