import { Card } from "primereact/card";
import * as React from "react";
import { connect } from "react-redux";

const FAQ = () => (
  <Card className="card-full-width" title={`Frequently Asked Questions`} subTitle="">
    <h4>Who is US Assets?</h4> US Assets, LLC is the parent company of Adair Asset Management, LLC, Adair Holdings, LLC, Summit Servicing Agency, LLC and other
    affiliated companies which have been working with County Governments throughout the United States since 1992. Located in Omaha, Nebraska our team of
    seasoned professionals understands both the opportunities and pitfalls of tax certificate investing. We have established working relationships that benefit
    the community as well as the county. We purchase unpaid property taxes from various states via county tax sales throughout the year. This allows the county
    to continue to run efficiently, allows the property owner extra time to pay their taxes before foreclosure happens and allows the investor a return on their
    funds. We are proud to assist County Governments in the collection of tax dollars to help fund their operations.
    <br />
    <br />
    <h4>What is a Tax Lien?</h4> A tax lien, sometimes known as a tax certificate, is a lien put on your property similar to a mortgage. These are the result of
    delinquent property taxes that were offered for sale by local county and municipal governments in order to recover property tax dollars for use in their
    current budgets. This money is what allows them to pay for the continued development and upkeep of their schools, streets, police and fire protection.
    Without the ability to collect these funds, the community suffers.
    <br />
    <br />
    <h4>Why do I have to work directly with US Assets?</h4> Your local taxing jurisdiction, according to the established statutes, has sold and/or assigned your
    delinquent tax lien to US Assets.
    <br />
    <br />
    <h4>How do I find out how much I owe and pay my delinquent tax lien?</h4> Please <a href="/parcels/list">click here</a> to be redirected to our online
    portal. You may also call us toll free at (844) 216-4341 or email us at <a href="mailto:servicing@usassets.net">servicing@usassets.net</a>.
    <br />
    <br />
    <h4>Does US Assets accept partial payments?</h4> You may be eligible to qualify for a payment plan. You can look up your parcel through our online portal by{" "}
    <a href="/parcels/list">clicking here</a> to request your options be sent to you or you are welcome to call us toll free at (844) 216-4341 or email us at{" "}
    <a href="mailto:servicing@usassets.net">servicing@usassets.net</a> to discuss your options and determine eligibility.
    <br />
    <br />
    <h4>What if I don’t pay my taxes?</h4> US Assets is entitled to file a notice of intent to foreclose on a parcel within four years after the purchase of the
    tax lien, or by the date negotiated with the county treasurer.
    <br />
    <br />
    <h4>Can I negotiate my tax lien payoff amount?</h4> No. US Assets is not able to release a lien unless full payment has been made. This includes the
    principal, accrued interest, and any other costs, fees, or penalties, including legal fees if the parcel is in foreclosure.
    <br />
    <br />
    <h4>I paid my lien in full, what happens next?</h4> US Assets must wait for the funds used to pay the balance to fully clear the bank, timing of which will
    vary based on form of payment. Once the transaction has cleared, a release of the lien will be recorded with the county. This process typically takes 30-60
    days. If you have additional questions or need an expedited release, please contact us toll free at (844) 216-4341 or email us at{" "}
    <a href="mailto:servicing@usassets.net">servicing@usassets.net</a>.
    <br />
    <br />
    <br />
    <br />
    <span style={{ fontWeight: "bold", fontSize: "larger" }}>
      IMPORTANT – The answers supplied to “Frequently Asked Questions” are to be used as a general guide, solely as a courtesy and convenience for interested
      parties. We urge you to consult with an attorney to best answer your specific questions.
    </span>
  </Card>
);

export default connect()(FAQ);
