import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../../store";
import * as ParcelStore from "../../../../store/parcel";
import * as UserStore from "../../../../store/user";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";

import { useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { IParcel } from "../../../../models/IParcel";

type ParcelListProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & RouteComponentProps<{}>;

function ParcelList(props: ParcelListProps) {
  const [selectedState, setSelectedState] = useState("OH");
  const [states, setStates] = useState([{ label: "OH", value: "OH" }]);
  const [address, setAddress] = useState("");
  const [selectedCountyId, setSelectedCountyId] = useState((props.match.params as any).countyId);
  const [ownerInfo, setOwnerInfo] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");

  const dt = useRef<DataTable<IParcel[]>>(null);

  // useEffect(() => {
  //   setStates(
  //     props.states?.map(function (item) {
  //       return { label: item.stateID, value: item.stateID };
  //     }) ?? []
  //   );
  // }, [props.states]);

  useEffect(() => {
    setSelectedCountyId((props.match.params as any).countyId);
    props.getCountyParcels(selectedCountyId, false);
  }, []);

  useEffect(() => {
    return () => {
      props.clearCurrentParcels();
    };
  }, []);

  const nameTemplate: any = (rowData: any) => {
    return `${rowData.lastName}, ${rowData.firstName}`;
  };

  const onExport = () => {
    if (dt && dt.current != null) dt.current.exportCSV({ selectionOnly: false });
  };

  const handleRowClick: any = (e: any) => {
    props.history.push(`/county/${selectedCountyId}/parcels/${e.data.parcelID}`);
  };

  const header = (
    <div style={{ textAlign: "left" }}>
      <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
      <InputText type="search" onInput={(e) => setGlobalFilter((e.target as unknown as HTMLTextAreaElement).value)} placeholder="Filter List" size={50} />
    </div>
  );

  const formatStatus: any = (rowData: any) => {
    if (rowData?.rtReceivedDate != null && rowData.rtReceivedDate) {
      return <span>Redeemed</span>;
    } else {
      return <span>Active</span>;
    }
  };

  const formatAddress: any = (rowData: any) => {
    return (
      <div>
        {rowData.propertyAddress}
        <br />
        {rowData.propertyCity}, {rowData.stateID}, {rowData.propertyZip}
      </div>
    );
  };

  // const approvedFilter = (
  //   <Dropdown
  //     style={{ width: "100%" }}
  //     placeholder="Select a value"
  //     value={selectedApprovedFilterValue}
  //     options={boolText}
  //     onChange={onApprovedFilterValueChange}
  //     showClear
  //   />
  // );

  const paginatorLeft = <Button icon="pi pi-refresh" />;

  return (
    <React.Fragment>
      <Card title={`All Liens`} subTitle={``} style={{}}></Card>

      <br />
      {props.countyParcels.length > 0 ? (
        <DataTable
          filterDisplay="row"
          ref={dt}
          className={"p-datatable-sm parcel-list"}
          value={props.countyParcels}
          onRowClick={($event) => handleRowClick($event)}
          selectionMode="single"
          stripedRows
          //header={header}
          globalFilter={globalFilter}
          paginator={true}
          paginatorLeft={paginatorLeft}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rows={15}
          rowsPerPageOptions={[5, 10, 20, 50]}
        //stateStorage="local"
        //stateKey="dt-state-local-county"
        >
          <Column field="parcelNo" header="Parcel Number" sortable={true} filter filterPlaceholder="Search by Parcel" />
          <Column field="taxSaleNo" header="Tax Certificate Number" sortable={true} filter filterPlaceholder="Search by Certificate #" />
          <Column field="webStatus" header="Status" sortable={true} filter filterPlaceholder="Search Status" />
          <Column field="ownerName" header="Owner" sortable={true} filter filterPlaceholder="Search by Owner" />
          <Column field="propertyAddress" header="Property" sortable={true} filter filterPlaceholder="Search by Address" body={formatAddress} />
          {/* <Column field="payofF_TOTAL" header="Total Due" sortable={true} /> */}
        </DataTable>
      ) : (
        <div>
          <ProgressSpinner />
        </div>
      )}
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.parcel, ParcelStore.actionCreators)(ParcelList as any);
