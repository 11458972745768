// import * as React from "react";
// import { connect } from "react-redux";

// const Home = () => (
//   <div>
//     <h1>Welcome!</h1>
//     <p>This is the dashboard page for the US Assets Web Site. Eventually this will direct a user where they might have work to do.</p>
//   </div>
// );

// export default connect()(Home);

import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { useEffect } from "react";
import { ApplicationState } from "../store";
import * as UserStore from "../store/user";
import jwt_decode from "jwt-decode";
import { AccountType } from "../models/enums/accountType";

type HomeProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

function Home(props: HomeProps) {
  // const [selectedLegal, setSelectedLegal] = useState(props.selectedLegal);

  // useEffect(() => {
  //   console.log("firing the user set");
  //   setSelectedLegal(props.selectedLegal);
  // }, [props.selectedLegal]);

  useEffect(() => {
    //const selectedLegalId = (props.match.params as any).LegalId;
    //props.getLegal(selectedLegalId);
    const accountType = (jwt_decode(localStorage.getItem("token") as string) as any).AccountType as number;

    const countyId = (jwt_decode(localStorage.getItem("token") as string) as any).CountyId as number;
    switch (accountType.toString()) {
      case AccountType.Admin.toString():
        props.history.push("/admin/landing");
        break;
      case AccountType.County.toString():
        props.history.push(`/county/${countyId}/landing`);
        break;
      case AccountType.Legal.toString():
        props.history.push(`/legal/parcels?countyID=${countyId}`);
        break;
      case AccountType.TaxPayer.toString():
        props.history.push("/parcels/list");
        break;
    }
  }, []);

  return (
    <React.Fragment>
      <h1>Landing page here.</h1>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(Home as any);
