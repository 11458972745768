
import React, {FC} from 'react';
import {Route, Switch} from 'react-router-dom';
import {AppRouting} from './app/app.routing';
import {APP_ROUTES} from './app/app.routing.constants';
import {AuthRouting} from './auth/auth.routing';
import {AUTH_ROUTES} from './auth/auth.routing.constants';
import {ProtectedRouting} from './protected.routing';

const authRoutes = Object.values(AUTH_ROUTES);
const appRoutes = Object.values(APP_ROUTES);

export const Routing: FC = () => (
    <Switch>
        <Route path={authRoutes} component={AuthRouting} />
        <ProtectedRouting exact path={appRoutes} component={AppRouting} />
    </Switch>
);
