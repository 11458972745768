import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../../store";
import * as ParcelStore from "../../../../store/parcel";
import { useEffect, useRef, useState } from "react";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { DataTable, DataTableExpandedRows } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { render } from "react-dom";
import { Card } from "primereact/card";
import { Menu } from "primereact/menu";
import { Toolbar } from "primereact/toolbar";
import { Messages } from "primereact/messages";
import { Message } from "primereact/message";
import { devNull } from "os";
import { Carousel } from "primereact/carousel";
import { IPayoffRequest } from "../../../../models/IPayoffRequest";

type CountyParcelDetailsProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & RouteComponentProps<{}>;

function CountyParcelDetails(props: CountyParcelDetailsProps) {
  const [selectedParcel, setSelectedParcel] = useState(props.selectedParcel);
  const [selectedParcelImages, setSelectedParcelImages] = useState(props.selectedParcelImages);
  const [selectedParcelDocuments, setSelectedParcelDocuments] = useState(props.selectedParcelDocuments);
  const [selectedParcelId, setSelectedParcelId] = useState(props.selectedParcel?.parcelID);
  const [relatedTaxCertificateMenuItems, setRelatedTaxCertificateMenuItems] = useState();
  const [selectedParcelCorrespondenceList, setSelectedParcelCorrespondenceList] = useState(props.selectedCountyParcelCorrespondenceList);
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedCountyId, setSelectedCountyId] = useState(0);
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | undefined>(undefined);
  const [payoffExpandedRows, setPayoffExpandedRows] = useState<DataTableExpandedRows | undefined>(undefined);
  const [paymentPlanExpandedRows, setPaymentPlanExpandedRows] = useState<DataTableExpandedRows | undefined>(undefined);
  const [isFromAdmin, setIsFromAdmin] = useState(false);
  const [isFromLegal, setIsFromLegal] = useState(false);
  const [photos, setPhotos] = useState([{}]);
  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: "600px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "480px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  let items: any;
  const menu: any = useRef(null);
  const dt = useRef<DataTable<any[]>>(null);
  const msgs1: any = useRef(null);

  useEffect(() => {
    setSelectedParcel(props.selectedParcel);
    items = props.selectedParcel?.relatedTaxCertificates.map(function (object, i) {
      return {
        label: object.taxSaleNo,
        icon: "pi pi-reply",
        command: () => {
          setSelectedParcelId(object.parcelID);
          props.getCountyParcel(object.parcelID);
          props.getCountyParcelCorrespondence(object.parcelID);
          props.getPaymentPlans(object.parcelID);
          props.getPayoffRequests(object.parcelID);
        },
      };
    });
    setRelatedTaxCertificateMenuItems(items);
  }, [props.selectedParcel]);

  useEffect(() => {
    setSelectedParcelCorrespondenceList(props.selectedCountyParcelCorrespondenceList);
  }, [props.selectedParcel]);

  useEffect(() => {
    setSelectedParcelImages(props.selectedParcelImages);
  }, [props.selectedParcelImages]);

  useEffect(() => {
    setSelectedParcelDocuments(props.selectedParcelDocuments);
  }, [props.selectedParcelDocuments]);

  useEffect(() => {
    const selectedParcelId = (props.match.params as any).parcelId;
    const selectedCountyId = (props.match.params as any).countyId;
    const query = new URLSearchParams(props.location.search);
    const isFromAdminParam = query.get("fromAdmin") == "true";
    setIsFromAdmin(isFromAdminParam);
    const isFromLegalParam = query.get("fromLegal") == "true";
    setIsFromLegal(isFromLegalParam);
    setSelectedCountyId(selectedCountyId);
    props.getCountyParcel(selectedParcelId);
    //props.getParcelImages(selectedParcelId);
    //props.getParcelDocuments(selectedParcelId);
    props.getCountyParcelCorrespondence(selectedParcelId);
    props.getPaymentPlans(selectedParcelId);
    props.getPayoffRequests(selectedParcelId);
    window.scrollTo(0, 0);
  }, []);

  const paginatorLeft = <Button icon="pi pi-refresh" />;

  const header = (
    <div style={{ textAlign: "left" }}>
      <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
      <InputText type="search" onInput={(e) => setGlobalFilter((e.target as unknown as HTMLTextAreaElement).value)} placeholder="Filter List" size={50} />
    </div>
  );

  const contactNameTemplate: any = (rowData: any) => {
    return `${rowData.contactLastName}, ${rowData.contactFirstName}`;
  };

  const intContactNameTemplate: any = (rowData: any) => {
    return `${rowData.intContactLastName}, ${rowData.intContactFirstName}`;
  };

  const requestedByNameTemplate: any = (rowData: any) => {
    return `${rowData.requestByLastName}, ${rowData.requestByFirstName}`;
  };

  function combineDateWithTime(d: Date, t: Date) {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), t.getHours(), t.getMinutes(), t.getSeconds(), t.getMilliseconds());
  }

  const formatLogDateTime: any = (rowData: any) => {
    let dvalue = new Date(rowData.logDate);
    let tvalue = new Date(rowData.logTime);
    let value = combineDateWithTime(dvalue, tvalue);

    return value.toLocaleString();
  };

  const formatLogDate: any = (rowData: any) => {
    let value = new Date(rowData.logDate);

    return value.toLocaleString().split(",")[0];
  };

  const formatCompleteDate: any = (rowData: any) => {
    let value = new Date(rowData.completeDate);

    return value.toLocaleString();
  };

  const formatActionDueDate: any = (rowData: any) => {
    let value = new Date(rowData.actionDueDate);

    return value.toLocaleString();
  };

  const formatDateRequested: any = (rowData: any) => {
    let value = new Date(rowData.requestDate);

    return value.toLocaleString().split(",")[0];
  };

  const formatDateOffered: any = (rowData: any) => {
    let value = new Date(rowData.offerDate);

    return value.toLocaleString().split(",")[0];
  };

  const formatDateOfferExpires: any = (rowData: any) => {
    let value = new Date(rowData.offerExpireDate);

    return value.toLocaleString().split(",")[0];
  };

  const incomingBodyTemplate = (rowData: any) => {
    return rowData.incoming === "True" ? "Incoming" : "Outgoing";
  };

  const formatCurrency = (value: number) => {
    if (value != null) {
      return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
    } else {
      return "$0.00";
    }
  };

  const offerAmountBodyTemplate = (rowData: any) => {
    return formatCurrency(rowData.offerAmount as number);
  };

  const rowExpansionTemplate: any = (data: any) => {
    return (
      <div>
        <h4>Notes:</h4>
        <h5 style={{ margin: "20px" }}>{data.notes}</h5>
      </div>
    );
  };

  const paymentPlanRowExpansionTemplate: any = (data: any) => {
    return (
      <div>
        <h4>Memo:</h4>
        <h5 style={{ margin: "20px" }}>{data.paymentPlanMemo}</h5>
      </div>
    );
  };

  const payoffRequestRowExpansionTemplate: any = (data: any) => {
    return (
      <div>
        <h4>Memo:</h4>
        <h5 style={{ margin: "20px" }}>{data.payoffMemo}</h5>
      </div>
    );
  };

  const leftContents = (
    <React.Fragment>
      <Button
        label="Back to List"
        icon="pi pi-arrow-left"
        className="p-mr-2"
        onClick={() => {
          if (isFromAdmin) {
            props.history.push(`/admin/landing`);
          } else if (isFromLegal) {
            props.history.push(`/legal/parcels?countyID=${selectedParcel?.countyID}`);
          } else {
            props.history.push(`/county/${selectedParcel?.countyID}/parcels`);
          }
        }}
      />
      {/* {(selectedParcel?.relatedTaxCertificates.length ?? 0) > 0 && (
        <div>
          <Menu model={relatedTaxCertificateMenuItems} popup ref={menu} />
          <Button
            label="View Related Tax Certificates"
            className="p-button-secondary"
            icon="pi pi-chevron-down"
            onClick={(event) => menu.current.toggle(event)}
          />
        </div>
      )} */}
    </React.Fragment>
  );

  const rightContents = (
    <React.Fragment>
      {(!isFromLegal) && (<Button
        className="p-mr-2  p-button-outlined"
        label="View Legal Details"
        icon="pi pi-briefcase"
        onClick={() => {
          props.history.push(`/legal/parcels/${selectedParcel?.parcelID}?fromLegal=${isFromLegal}&fromAdmin=${isFromAdmin}`);
        }}
      />)}
      {(selectedParcel?.rtReceivedDate == null || selectedParcel.rtReceivedDate == undefined) && (
        <Button
          className="p-mr-2  p-button-outlined"
          label="View Payoff Amount"
          icon="pi pi-wallet"
          onClick={() => {
            props.history.push(`/county/${selectedParcel?.countyID}/parcels/${selectedParcel?.parcelID}/payoff?fromAdmin=${isFromAdmin}`);
          }}
        />
      )}
    </React.Fragment>
  );

  const imageTemplate = (image: any) => {
    return (
      <div className="p-mb-3" style={{ marginLeft: "50px", marginRight: "50px", marginTop: "20px" }}>
        <img
          style={{ width: "300px" }}
          src={`${image.src}`}
          //onError={(e) => (e.target.src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")}
          alt={""}
          className="parcel-image"
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <div>
        <Toolbar left={leftContents} right={rightContents} />
      </div>
      {(selectedParcel?.relatedTaxCertificates.length ?? 0) > 0 && (
        <div className="p-grid">
          <div className="p-col">
            <br />
            <Card className="related-parcels" title={`Related Tax Certificates`}>
              {props.selectedParcel?.relatedTaxCertificates.map((item: any) => {
                return (
                  <React.Fragment>
                    <Button
                      className={`p-mr-2  ${item.status == "Redeemed" ? "p-button-success" : "p-button-outlined"}`}
                      label={`${item.taxSaleNo} - ${item.status}`}
                      icon="pi pi-reply"
                      onClick={() => {
                        setSelectedParcelId(item.parcelID);
                        props.getParcel(item.parcelID);
                      }}
                    />
                  </React.Fragment>
                );
              })}
            </Card>
          </div>
        </div>
      )}
      {selectedParcel?.rtReceivedDate != null && selectedParcel.rtReceivedDate && (
        <div>
          <br />
          <div className="p-message p-component p-message-success p-message-enter-done">
            <div className="p-ml-2">
              <br />
              <h4>Redemption Funds Received</h4>

              <div className="p-grid" style={{ marginTop: "4px" }}>
                <div className="p-col-3">
                  <label className="field-label">
                    <i className="pi pi-calendar"></i>&nbsp; Received Date:
                  </label>
                  <br /> {new Date(selectedParcel?.rtReceivedDate ?? "1/1/1970").toLocaleString().split(",")[0]}
                  <br />
                  <br />
                </div>
                {selectedParcel?.rtAmountReceived && (
                  <div className="p-col-3">
                    <label className="field-label">
                      <i className="pi pi-dollar"></i>&nbsp; Amount Received:
                    </label>
                    <br /> {formatCurrency(selectedParcel?.rtAmountReceived ?? (0 as number))}
                    <br />
                    <br />
                  </div>
                )}
                {selectedParcel?.rtPaymentType && (
                  <div className="p-col-3">
                    <label className="field-label">
                      <i className="pi pi-info-circle"></i>&nbsp; Payment Type:
                    </label>
                    <br /> {selectedParcel?.rtPaymentType}
                    <br />
                    <br />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <br />
      <div className="p-grid">
        <div className="p-col">
          <Card title={`Tax Certificate ${selectedParcel?.taxSaleNo}`} subTitle={`Parcel Number ${selectedParcel?.parcelNo}`} style={{ height: "528px" }}>
            <label className="field-label">
              <i className="pi pi-user"></i>&nbsp; Owner:
            </label>
            <br /> {selectedParcel?.ownerName}
            <br />
            <br />
            <label className="field-label">
              <i className="pi pi-map"></i>&nbsp; Property Address:
            </label>
            <br />
            {selectedParcel?.propertyAddress}
            <br />
            {selectedParcel?.propertyCity}, {selectedParcel?.stateName}, {selectedParcel?.propertyZip}
            <br />
            <br />
            <label className="field-label">
              <i className="pi pi-calendar"></i>&nbsp; Purchase Date:
            </label>
            <br />
            {new Date(selectedParcel?.purchaseDate ?? "1/1/1970").toLocaleString().split(",")[0]}
            <br />
            <br />
            {/* <label className="field-label">Status:</label>
            <br /> {selectedParcel?.status}
            <br />
            <br /> */}
            {selectedParcel?.caseNum != undefined && (
              <div>
                <label className="field-label">
                  <i className="pi pi-briefcase"></i>&nbsp; Case Number:{" "}
                </label>
                <br />
                <span>{selectedParcel?.caseNum}</span>
                <br />
                <br />
              </div>
            )}
            {selectedParcel?.claimNum != undefined && (
              <div>
                <label className="field-label">
                  <i className="pi pi-briefcase"></i>&nbsp;Bankruptcy Case Number:{" "}
                </label>
                <br />
                <span>{selectedParcel?.claimNum}</span>
                <br />
                <br />
              </div>
            )}
          </Card>
        </div>
        <div className="p-col">
          <Card
            title={`${selectedParcel?.propertyAddress}`}
            subTitle={`${selectedParcel?.propertyCity}, ${selectedParcel?.stateName}, ${selectedParcel?.propertyZip}`}
          >
            <div style={{ width: "100%", overflow: "hidden", height: "388px" }}>
              <iframe
                width="100%"
                height="700"
                loading="lazy"
                style={{ marginTop: "-150px" }}
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC2-toCyYhXyGwn4PBxifmdDT9ZOAjQ2ec&q=${selectedParcel?.propertyAddress ?? ""},${selectedParcel?.propertyCity
                  }+${selectedParcel?.stateName}`}
              ></iframe>
            </div>
          </Card>
        </div>
      </div>
      {/* <br />
      <Card className="card-full-width" title="Photos" subTitle="">
        <Carousel
          value={selectedParcelImages?.map((image) => {
            return { src: image };
          })}
          numVisible={3}
          numScroll={1}
          responsiveOptions={responsiveOptions}
          className="custom-carousel"
          circular
          //autoplayInterval={3000}
          itemTemplate={imageTemplate}
          header={""}
        />
      </Card> */}
      {/* <br />
      <Card className="card-full-width" title="Documents" subTitle="">
        <div style={{ textAlign: "center" }}>
          {selectedParcelDocuments?.map((item) => {
            return (
              <React.Fragment>
                

                <Button
                  className="p-mr-2  p-button-outlined"
                  label={item.split("County Access/")[1]}
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  style={{ width: "50%" }}
                  onClick={() => {
                    window.open(item, "_blank");
                  }}
                />
                <br />
              </React.Fragment>
            );
          })}
        </div>
      </Card> */}

      <br />

      <Card className="card-full-width" title="Payoff Requests" subTitle="">
        <DataTable
          filterDisplay="row"
          ref={dt}
          value={props.selectedCountyParcelPayoffRequestList}
          // onRowClick={($event) => handleRowClick($event)}
          selectionMode="single"
          stripedRows
          expandedRows={payoffExpandedRows}
          onRowToggle={(e) => setPayoffExpandedRows(e.data as unknown as DataTableExpandedRows)}
          rowExpansionTemplate={payoffRequestRowExpansionTemplate}
          dataKey="payoffID"
          // header={header}
          // globalFilter={globalFilter}
          paginator={true}
          // paginatorLeft={paginatorLeft}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rows={10}
          rowsPerPageOptions={[5, 10, 20, 50]}
        >
          <Column expander style={{ width: "3em" }} />

          {/* <Column field="payoffID" header="payoffID" sortable={true} />
            <Column field="parcelID" header="parcelID" sortable={true} /> */}
          <Column field="requestDate" header="Date Requested" sortable={true} body={formatDateRequested} />
          <Column field="offerDate" header="Date Offered" sortable={true} body={formatDateOffered} />
          <Column field="offerExpireDate" header="Date Offer Expires" sortable={true} body={formatDateOfferExpires} />
          <Column field="offerAmount" header="Offer Amount" sortable={true} body={offerAmountBodyTemplate} />
          {/* <Column field="payoffMemo" header="Memo" sortable={true} /> */}
          <Column field="requestByFirstName" header="Requested By" sortable={true} body={requestedByNameTemplate} />
          {/* <Column field="requestByLastName" header="requestByLastName" sortable={true} style={{ display: "none" }} /> */}
        </DataTable>
      </Card>
      <br />
      <Card className="card-full-width" title="Payment Plans" subTitle="">
        <DataTable
          filterDisplay="row"
          ref={dt}
          value={props.selectedCountyParcelPaymentPlanList}
          // onRowClick={($event) => handleRowClick($event)}
          selectionMode="single"
          stripedRows
          expandedRows={paymentPlanExpandedRows}
          onRowToggle={(e) => setPaymentPlanExpandedRows(e.data as unknown as DataTableExpandedRows)}
          rowExpansionTemplate={paymentPlanRowExpansionTemplate}
          dataKey="paymentPlanID"
          // header={header}
          // globalFilter={globalFilter}
          paginator={true}
          // paginatorLeft={paginatorLeft}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rows={10}
          rowsPerPageOptions={[5, 10, 20, 50]}
        >
          <Column expander style={{ width: "3em" }} />
          {/* <Column field="paymentPlanID" header="paymentPlanID" sortable={true} />
            <Column field="parcelID" header="parcelID" sortable={true} /> */}
          <Column field="requestDate" header="Requested" sortable={true} body={formatDateRequested} />
          <Column field="offerDate" header="Offered" sortable={true} body={formatDateOffered} />
          <Column field="offerExpireDate" header="Offer Expires" sortable={true} body={formatDateOfferExpires} />
          {/* <Column field="acceptDate" header="acceptDate" sortable={true} /> */}
          {/* <Column field="defaultDate" header="defaultDate" sortable={true} /> */}
          <Column field="term" header="Term (Months)" sortable={true} />
          <Column field="payPlanStatus" header="Status" sortable={true} />
          {/* <Column field="paymentPlanMemo" header="Memo" sortable={true} /> */}
          <Column field="requestByFirstName" header="Requested By" sortable={true} body={requestedByNameTemplate} />
          {/* <Column field="requestByLastName" header="requestByLastName" sortable={true} style={{ display: "none" }} /> */}
        </DataTable>
      </Card>
      <br />
      <Card className="card-full-width" title="Correspondence History">
        <DataTable
          filterDisplay="row"
          ref={dt}
          value={props.selectedCountyParcelCorrespondenceList}
          // onRowClick={($event) => handleRowClick($event)}
          selectionMode="single"
          stripedRows
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data as unknown as DataTableExpandedRows)}
          rowExpansionTemplate={rowExpansionTemplate}
          dataKey="logID"
          header={header}
          globalFilter={globalFilter}
          paginator={true}
          // paginatorLeft={paginatorLeft}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rows={10}
          rowsPerPageOptions={[5, 10, 20, 50]}
        >
          <Column expander style={{ width: "3em" }} />
          {/* <Column field="logID" header="logID" sortable={true} /> */}
          {/* <Column field="parcelID" header="parcelID" sortable={true} />
          <Column field="contactID" header="contactID" sortable={true} /> */}
          <Column field="contactLastName" header="Contact" body={contactNameTemplate} sortable={true} />
          {/* <Column field="contactFirstName" header="contactFirstName" sortable={true} style={{ display: "none" }} /> */}
          {/* <Column field="logTypeID" header="logTypeID" sortable={true} /> */}
          <Column field="incoming" header="Source" sortable={true} body={incomingBodyTemplate} />
          <Column field="logType" header="Method" sortable={true} />
          <Column field="intContactLastName" header="Internal Rep" body={intContactNameTemplate} sortable={true} />
          <Column field="logDate" header="Logged" sortable={true} body={formatLogDate} />
          {/* <Column field="logTime" header="logTime" sortable={true} style={{ display: "none" }} /> */}
          {/* <Column field="notes" header="Notes" sortable={true} /> */}
          {/* <Column field="internalContactID" header="internalContactID" sortable={true} /> */}

          {/* <Column field="intContactFirstName" header="intContactFirstName" sortable={true} style={{ display: "none" }} /> */}

          {/* <Column field="corrTypeID" header="corrTypeID" sortable={true} /> */}
          <Column field="corrType" header="Reason" sortable={true} />
          {/* <Column field="completeDate" header="Complete Date" sortable={true} body={formatCompleteDate} />
          <Column field="actionDueDate" header="Action Due" sortable={true} body={formatActionDueDate} />
          <Column field="action" header="Has Action" sortable={true} /> */}
          {/* <Column field="completeByID" header="completeByID" sortable={true} />
          <Column field="internalNotes" header="internalNotes" sortable={true} /> */}
        </DataTable>
      </Card>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.parcel, ParcelStore.actionCreators)(CountyParcelDetails as any);
