import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../store";
import * as UserStore from "../../store/user";
import { ICreateUser } from "../../models/ICreateUser";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { AccountType } from "../../models/enums/accountType";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

type ForgotPasswordConfirmationProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

class ForgotPasswordConfirmation extends React.PureComponent<ForgotPasswordConfirmationProps, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      username: "",
    };
  }

  public render() {
    return (
      <React.Fragment>
        <Card title="Recover your password" subTitle="">
          <h2>Check your email for a link to create a new password.</h2>
        </Card>
      </React.Fragment>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.user,
  UserStore.actionCreators
)(ForgotPasswordConfirmation as any);
