import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as ParcelStore from "../../../store/parcel";
import * as UserStore from "../../../store/user";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { IParcelSearch } from "../../../models/IParcelSearch";
import { Divider } from "primereact/divider";
import "../../../../node_modules/primeflex/primeflex.scss";
import { ProgressSpinner } from "primereact/progressspinner";
import { IWebPaymentLog } from "../../../models/IWebPaymentLog";

type WebPaymentLogListProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & RouteComponentProps<{}>;

function WebPaymentLogList(props: WebPaymentLogListProps) {
  const [selectedState, setSelectedState] = useState("OH");
  const [states, setStates] = useState([{ label: "OH", value: "OH" }]);
  const [selectedCounty, setSelectedCounty] = useState(855);
  const [counties, setCounties] = useState([
    {
      "label": "Summit",
      "value": 855,
      "state": "OH"
    },
    {
      "label": "Hamilton",
      "value": 854,
      "state": "OH"
    },
    {
      "label": "Franklin",
      "value": 856,
      "state": "OH"
    },
    {
      "label": "Pickaway",
      "value": 1032,
      "state": "OH"
    },
    {
      "label": "Montgomery",
      "value": 1033,
      "state": "OH"
    }
  ]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [webPaymentLogList, setWebPaymentLogList] = useState([] as Array<IWebPaymentLog>);

  const dt = useRef<DataTable<IWebPaymentLog[]>>(null);

  useEffect(() => {
    props.getWebPaymentLogs();
  }, []);

  useEffect(() => {
    setWebPaymentLogList(props.webPaymentLogs);
  }, [props.webPaymentLogs]);

  const nameTemplate: any = (rowData: any) => {
    return `${rowData.lastName}, ${rowData.firstName}`;
  };

  const formatPurchaseDate: any = (rowData: any) => {
    let value = new Date(rowData.purchaseDate);

    return value.toLocaleString().split(",")[0];
  };

  const formatStatus: any = (rowData: any) => {
    if (rowData?.rtReceivedDate != null && rowData.rtReceivedDate) {
      return <span>Redeemed</span>;
    } else {
      return <span>Active</span>;
    }
  };

  const formatAddress: any = (rowData: any) => {
    return (
      <div>
        {rowData.propertyAddress}
        <br />
        {rowData.propertyCity}, {rowData.stateName}, {rowData.propertyZip}
      </div>
    );
  };


  const formatUserInfo: any = (rowData: any) => {
    if (rowData.username) {
      return (
        <div>
          {rowData.lastName}, {rowData.firstName}
          <br />
          {rowData.username}, {rowData.email}, {rowData.phone}
        </div>
      );
    }
    else {
      return "None Logged";
    }
  };



  const onExport = () => {
    if (dt && dt.current != null) dt.current.exportCSV({ selectionOnly: false });
  };

  const handleRowClick: any = (e: any) => {
    //props.history.push(`/parcels/${e.data.parcelID}`);
  };

  const formatCurrency = (value: number | null) => {
    if (value == null) {
      return "$0";
    }
    return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
  };

  const priceBodyTemplate = (rowData: any) => {
    var totalPaymentAmount = rowData.paymentAmount as number;
    var certPaymentAmount = 0;
    if (rowData.paymentMethod == "CC") {
      certPaymentAmount = (totalPaymentAmount / 1.0325);
    }
    else {
      certPaymentAmount = (totalPaymentAmount / 1.01);
    }
    var processing = (totalPaymentAmount - certPaymentAmount);



    return <div>Cert: {formatCurrency(certPaymentAmount)}<br />
      Fee: {formatCurrency(processing)}<br />
      Total: {formatCurrency(totalPaymentAmount)}<br />
    </div>
  };

  const isPendingTemplate: any = (rowData: any) => {
    let value = rowData.isPaymentPending as boolean;

    if (value) {
      return <span>Yes</span>;
    } else {
      return <span>No</span>;
    }
  };

  const header = (
    <div style={{ textAlign: "left" }}>
      <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
      <InputText
        type="search"
        onInput={(e) => setGlobalFilter((e.target as unknown as HTMLTextAreaElement).value)}
        placeholder="Filter List"
        size={50}
        style={{ width: "300px", marginLeft: "8px" }}
      />
      <Button className="float-right" type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={onExport}></Button>
    </div>
  );

  const paginatorLeft = <Button icon="pi pi-refresh" />;

  return (
    <React.Fragment>
      <Card title="Lien Payment Data" subTitle="This is the raw data that is logged when a taxpayer makes a payment on the web site."></Card>
      <br />
      <DataTable
        filterDisplay="row"
        ref={dt}
        value={webPaymentLogList}
        onRowClick={($event) => handleRowClick($event)}
        selectionMode="single"
        header={header}
        globalFilter={globalFilter}
        paginator={true}
        paginatorLeft={paginatorLeft}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rows={10}
        rowsPerPageOptions={[5, 10, 20, 50]}
      >
        <Column field="taxSaleNo" header="Certificate Number" sortable={true} />
        <Column field="ownerName" header="Owner" sortable={true} />
        <Column field="propertyAddress" header="Property Address" sortable={true} body={formatAddress} />
        <Column field="username" header="User Info" sortable={true} body={formatUserInfo} />
        <Column field="paymentAmount" header="Payment" sortable={true} style={{ width: "160px" }} body={priceBodyTemplate} />
        <Column field="paymentMethod" header="Method" sortable={true} />
        <Column field="isPaymentPending" header="Pending Payment" sortable={true} body={isPendingTemplate} />
        <Column field="created" header="Payment Date" sortable={true} />
      </DataTable>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.parcel, ParcelStore.actionCreators)(WebPaymentLogList as any);
