import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../../store";
import * as ParcelStore from "../../../../store/parcel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useRef, useState } from "react";

type MyPaymentsProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & RouteComponentProps<{}>;

function MyPayments(props: MyPaymentsProps) {
  const [globalFilter, setGlobalFilter] = useState("");
  const [paymentList, setPaymentList] = useState(props.paymentList);
  const dt = useRef<DataTable<any[]>>(null);

  useEffect(() => {
    props.getMyPayments();
  }, []);

  useEffect(() => {
    console.log(props.paymentList);
    setPaymentList(props.paymentList);
  }, [props.paymentList]);

  useEffect(() => {
    return () => {
      props.clearCurrentPayments();
    };
  }, []);

  const handleRowClick: any = (e: any) => {
    props.history.push(`/parcels/${e.data.parcelID}?fromMyPayments=true`);
  };

  const header = (
    <div style={{ textAlign: "left" }}>
      <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
      <InputText type="search" onInput={(e) => setGlobalFilter((e.target as unknown as HTMLTextAreaElement).value)} placeholder="Filter List" size={50} />
    </div>
  );

  const formatAddress: any = (rowData: any) => {
    return (
      <div>
        {rowData.propertyAddress}
        <br />
        {rowData.propertyCity}, {rowData.stateID}, {rowData.propertyZip}
      </div>
    );
  };

  const formatDate: any = (rowData: any) => {
    let value = new Date(rowData.requestDate);

    return value.toLocaleDateString();
  };

  const formatPaymentAmount = (rowData: any) => {
    return formatCurrency(rowData.payofF_TOTAL as number);
  };

  const formatCurrency = (value: number | null) => {
    if (value == null) {
      return "$0";
    }
    return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
  };

  return (
    <React.Fragment>
      <Card title={`Online Payments`} subTitle={``} style={{}}></Card>

      <br />
      {paymentList.length > 0 ? (
        <DataTable
          filterDisplay="row"
          ref={dt}
          className={"p-datatable-sm"}
          value={paymentList}
          onRowClick={($event) => handleRowClick($event)}
          selectionMode="single"
          stripedRows
          //header={header}
          globalFilter={globalFilter}
          paginator={true}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rows={10}
          rowsPerPageOptions={[5, 10, 20, 50]}
        >
          <Column field="parcelNo" header="Parcel Number" sortable={true} />
          <Column field="taxSaleNo" header="Tax Certificate Number" sortable={true} />
          <Column field="ownerName" header="Owner" sortable={true} />
          <Column field="propertyAddress" header="Property" sortable={true} body={formatAddress} />
          <Column field="requestDate" header="Payment Date" sortable={true} body={formatDate} />
          <Column field="pAYOFF_TOTAL" header="Amount Paid" sortable={true} body={formatPaymentAmount} />
          {/* <Column field="propertyAddress" header="Method" sortable={true} body={formatAddress} /> */}
        </DataTable>
      ) : (
        <div>
          <ProgressSpinner />
        </div>
      )}
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.parcel, ParcelStore.actionCreators)(MyPayments as any);
