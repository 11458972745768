import react, * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../store";
import * as ParcelStore from "../store/parcel";
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import "./footer.scss";
import { AccountType } from "../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import ServiceTrackingItem from "./ServiceTrackingItem";
import { Button } from "primereact/button";
import { IServiceTracking } from "../models/IServiceTracking";

type ServiceTrackingProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & RouteComponentProps<{}>;

function ServiceTracking(props: ServiceTrackingProps) {
  const [trackingItems, setTrackingItems] = react.useState([{} as IServiceTracking]);
  const [selectedParcelId, setSelectedParcelId] = react.useState(props.selectedParcel?.parcelID);

  react.useEffect(() => {
    setTrackingItems(props.legalServiceTracking);
    console.log("Updated");
  }, [props.legalServiceTracking]);

  react.useEffect(() => {
    setTrackingItems(trackingItems);
  }, [trackingItems]);

  react.useEffect(() => {
    if (props.legalServiceTrackingUpdated) {
      props.getServiceTracking(selectedParcelId);
      props.clearLegalServiceTracking();
    }
  }, [props.legalServiceTrackingUpdated]);

  react.useEffect(() => {
    props.getServiceTracking(props.selectedParcelId);
    setSelectedParcelId(props.selectedParcelId);
  }, []);

  return (
    <React.Fragment>
      <label className="field-label">Service Tracking:</label>
      <br />
      <div className="p-grid">
        <div className="p-col-4">Name</div>
        <div className="p-col-4">Svc Type &amp; Date</div>
        {/* <div className="p-col-4">Svc Date</div> */}
      </div>
      {trackingItems.map((item: IServiceTracking) => {
        return <ServiceTrackingItem key={item.defendantID} serviceTracking={item} {...props} />;
      })}
      <Button
        label={`Add`}
        icon="pi pi-plus"
        className="p-mr-2"
        onClick={() => {
          var serviceTracking = new IServiceTracking();
          serviceTracking.parcelID = selectedParcelId ?? 0;
          props.createServiceTracking(serviceTracking);
        }}
      />
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.user, ParcelStore.actionCreators)(ServiceTracking as any);
