import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../../store";
import * as ParcelStore from "../../../../store/parcel";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { IParcel } from "../../../../models/IParcel";
import { InputSwitch } from "primereact/inputswitch";
import { AccountType } from "../../../../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { Menu } from "primereact/menu";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";

type ParcelDetailsPayoffRequestPrintProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & RouteComponentProps<{}>;

function ParcelDetailsPayoffRequestPrint(props: ParcelDetailsPayoffRequestPrintProps) {
  const [selectedParcel, setSelectedParcel] = useState(props.selectedParcel);
  const [selectedParcelId, setSelectedParcelId] = useState(props.selectedParcel?.parcelID);
  const [relatedTaxCertificateMenuItems, setRelatedTaxCertificateMenuItems] = useState();
  const [payoffData, setPayoffData] = useState([{ type: "Principal", amount: 0 }]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [expandedRows, setExpandedRows] = useState({});
  const [displayPaymentMethods, setDisplayPaymentMethods] = useState(false);
  const dt = useRef<DataTable<any[]>>(null);
  let items: any;
  const menu: any = useRef(null);

  useEffect(() => {
    setSelectedParcel(props.selectedParcel);
    setPayoffData([]);
    setPayoffData([
      { type: "Principal", amount: selectedParcel?.payofF_PRINCIPLE ?? 0 },
      { type: "Interest", amount: selectedParcel?.payofF_INTEREST ?? 0 },
      { type: "Expenses", amount: selectedParcel?.payofF_EXPENSE ?? 0 },
      { type: "Total Payoff", amount: selectedParcel?.payofF_TOTAL ?? 0 },
    ]);
    items = props.selectedParcel?.relatedTaxCertificates.map(function (object, i) {
      return {
        label: object.taxSaleNo,
        icon: "pi pi-reply",
        command: () => {
          setSelectedParcelId(object.parcelID);
          props.getParcel(object.parcelID);
        },
      };
    });
    setRelatedTaxCertificateMenuItems(items);
  }, [props.selectedParcel]);

  useEffect(() => {
    setPayoffData([]);
    setPayoffData([
      { type: "Principal", amount: selectedParcel?.payofF_PRINCIPLE ?? 0 },
      { type: "Interest", amount: selectedParcel?.payofF_INTEREST ?? 0 },
      { type: "Expenses", amount: selectedParcel?.payofF_EXPENSE ?? 0 },
      { type: "Total Payoff", amount: selectedParcel?.payofF_TOTAL ?? 0 },
    ]);
  }, [selectedParcel]);

  useEffect(() => {
    const selectedParcelId = (props.match.params as any).parcelId;
    setSelectedParcelId(selectedParcelId);
    props.getParcel(selectedParcelId);
    props.logPayoffView(selectedParcelId);
  }, []);

  const notify = () => {
    toast.success("Settings Saved!");
  };

  const formatCurrency = (value: number | null) => {
    if (value == null) {
      return "$0";
    }
    return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
  };

  const formatDate: any = (date: Date) => {
    let value = new Date(date);

    return value.toLocaleString().split(",")[0];
  };

  const leftContents = (
    <React.Fragment>
      <Button
        icon="pi pi-arrow-left"
        className="p-mr-2"
        label={`Back to Certificate ${selectedParcel?.taxSaleNo}`}
        onClick={() => {
          props.history.push(`/parcels/${selectedParcel?.parcelID}`);
        }}
      />
      {/* {(selectedParcel?.relatedTaxCertificates.length ?? 0) > 0 && (
        <div>
          <Menu model={relatedTaxCertificateMenuItems} popup ref={menu} />
          <Button
            label="View Related Tax Certificates"

            className="p-button-secondary"
            icon="pi pi-chevron-down"
            onClick={(event) => menu.current.toggle(event)}
          />
        </div>
      )} */}
    </React.Fragment>
  );

  const rightContents = (
    <React.Fragment>
      {(selectedParcel?.payofF_TOTAL ?? 0) > 0 && (
        <Button label="Make a Payment" icon="pi pi-dollar" className="p-mr-2  p-button-success" onClick={() => setDisplayPaymentMethods(true)} />
      )}
    </React.Fragment>
  );

  const rowExpansionTemplate: any = (data: any) => {
    return (
      <div style={{ marginLeft: "100px" }}>
        <div className="p-grid">
          <div className="p-col-4">
            <label style={{ fontWeight: "bold", fontSize: "large", marginBottom: "4px" }}>Description</label>
            <br />
            {selectedParcel?.subsequentData.map((item) => {
              return (
                <div className="truncate" title={item.subsequentName}>
                  {item.subsequentName}
                  <br />
                </div>
              );
            })}
            <label style={{ fontWeight: "bold", marginTop: "4px" }}>Total</label>
          </div>
          <div className="p-col-2" style={{ textAlign: "right" }}>
            <label style={{ fontWeight: "bold", fontSize: "large", marginBottom: "4px" }}>Amount</label>
            <br />
            {selectedParcel?.subsequentData.map((item) => {
              return (
                <div>
                  {formatCurrency(item.subsequentAmt as number)}
                  <br />
                </div>
              );
            })}
            <label style={{ fontWeight: "bold", marginTop: "4px" }}>{formatCurrency(selectedParcel?.payofF_PRINCIPLE as number)}</label>
          </div>
        </div>
      </div>
    );
  };

  const paginatorLeft = <Button icon="pi pi-refresh" />;

  const header = (
    <div style={{ textAlign: "left" }}>
      <h4>Payoff Breakdown</h4>
      {(selectedParcel?.noiFiledDate === null && selectedParcel?.reportOfficeOhio === true) && (<span>Good Until: {formatDate(new Date())}</span>)}
      {(selectedParcel?.noiFiledDate !== null || selectedParcel?.reportOfficeOhio !== true) && (<span>Good Until: {formatDate(selectedParcel?.payofF_GOOD_THRU)}</span>)}
      <br /><span>{selectedParcel?.noiFiledDate === null ? "NULL" : "NOT NULL"}</span>
      <br /><span>{selectedParcel?.reportOfficeOhio === true ? "TRUE" : "FALSE"}</span>
      <br /><span>{selectedParcel?.payofF_GOOD_THRU}</span>
      <Button label="Print payoff" icon="pi pi-print" className="p-mr-2  p-button-success" onClick={() => setDisplayPaymentMethods(true)} />
    </div>
  );

  const handleRowClick: any = (e: any) => {
    if (e.data.type === "Principal") {
      const data = {
        Principal: true,
      };
      setExpandedRows(data);
    }
  };

  const rowClass: any = (rowData: any) => {
    return {
      noExpander: rowData.type !== "Principal",
    };
  };

  const priceBodyTemplate = (rowData: any) => {
    return formatCurrency(rowData.amount as number);
  };

  return (
    <React.Fragment>
      <Button
        id="print-button"
        label="Print"
        icon="pi pi-print"
        style={{ marginBottom: "12px" }}
        onClick={() => {
          window.print();
        }}
      />
      <div style={{ background: "white" }}>
        <img style={{ width: "100%" }} src="/images/AdairAssetMgt_colored_noLLC.jpg" />
        <div style={{ textAlign: "center", fontSize: "40px" }}>PAYOFF INVOICE</div>
        <div className="p-grid">
          <div className="p-col-5">
            <div style={{ color: "#E5964C", fontWeight: "bold", fontSize: "20px" }}>PROPERTY INFORMATION</div>
            <div>Name: {selectedParcel?.ownerName}</div>
            <div>State: {selectedParcel?.stateName}</div>
            <div>County: {selectedParcel?.countyName}</div>
            <div>Parcel Number: {selectedParcel?.parcelNo}</div>
            <div>Property Address: {selectedParcel?.propertyAddress}</div>
            <div>
              City State Zip: {selectedParcel?.propertyCity}, {selectedParcel?.stateID} {selectedParcel?.propertyZip}
            </div>
            <div> Certificate Number: {selectedParcel?.taxSaleNo}</div>
          </div>
          <div className="p-col-3"></div>
          <div className="p-col-3">
            <div style={{ color: "#E5964C", fontWeight: "bold", fontSize: "20px" }}>PAYOFF GOOD THROUGH</div>

            {(selectedParcel?.noiFiledDate === null && selectedParcel?.reportOfficeOhio === true) && (<span>{formatDate(new Date())}</span>)}
            {(selectedParcel?.noiFiledDate !== null || selectedParcel?.reportOfficeOhio !== true) && (<span>{formatDate(selectedParcel?.payofF_GOOD_THRU)}</span>)}

          </div>
        </div>
        <div >

          <table style={{ marginLeft: "auto", width: "50%", marginRight: "auto" }}>
            <tr>
              <td style={{ color: "#E5964C", fontWeight: "bold", fontSize: "20px" }}>DESCRIPTION</td>
              <td style={{ color: "#E5964C", fontWeight: "bold", fontSize: "20px", textAlign: "right" }}>AMOUNT</td>
            </tr>
            <tr>
              <td style={{ fontSize: "20px" }}>Principal</td>
              <td style={{ textAlign: "right", fontSize: "20px" }}>{formatCurrency(selectedParcel?.payofF_PRINCIPLE ?? 0)}</td>
            </tr>
            <tr>
              <td style={{ fontSize: "20px" }} >Interest</td>
              <td style={{ textAlign: "right", fontSize: "20px" }}>{formatCurrency(selectedParcel?.payofF_INTEREST ?? 0)}</td>
            </tr>
            <tr>
              <td style={{ fontSize: "20px" }} >Expenses</td>
              <td style={{ textAlign: "right", fontSize: "20px" }}>{formatCurrency(selectedParcel?.payofF_EXPENSE ?? 0)}</td>
            </tr>
            <tr>
              <td style={{ borderTop: "2px solid black", fontSize: "20px" }}>Total Due</td>
              <td style={{ textAlign: "right", fontWeight: "bold", borderTop: "2px solid black", fontSize: "20px" }}>{formatCurrency(selectedParcel?.payofF_TOTAL ?? 0)}</td>
            </tr>
          </table>


        </div>

        <div style={{ fontSize: "12px" }}>
          <div className="p-grid">
            <div className="p-col">
              <div style={{ color: "#E5964C", fontWeight: "bold", fontSize: "20px", marginBottom: "4px" }}>Payment Options</div>
              <div>
                <span style={{ fontWeight: "bold" }}>PAYMENT BY MAIL:</span> To pay by mail, please send payments payable to Adair Asset Management.  The only forms of payment accepted are CASHIERS CHECK or MONEY ORDER.  Include reference {selectedParcel?.taxSaleNo} in the subject line.  Above amount must be RECEIVED on or before {formatDate(selectedParcel?.payofF_GOOD_THRU)}. Payments should be directed to the address below to Servicing's attention. <span style={{ fontStyle: "bold" }}>*Personal and Business checks will not be accepted and will be returned.</span><br /><br />
                <span style={{ fontWeight: "bold" }}>PAYMENT BY CREDIT CARD, DEBIT CARD OR ACH:</span> To pay by Credit Card, Debit Card or ACH, you will go to our website at <a href="http://www.usassets.net/">www.usassets.net</a>, choose Ohio Servicing, then search by your Parcel Number {selectedParcel?.parcelNo}. Each transaction is subject to a processing fee clearly listed on the website.<br /><br />
                <span style={{ fontWeight: "bold" }}>PAYMENT BY WIRE TRANSFER:</span> To pay by wire transfer, please e-mail <a href="mailto:servicing@usassets.net">servicing@usassets.net</a> for wire instructions. To ensure your account is credited, send a confirmation to servicing@usassets.net after the wire has been initiated including reference {selectedParcel?.countyName} {selectedParcel?.taxSaleNo}. Each wire transfer requires an additional wire fee of $15<br />
              </div>
            </div>
          </div>

          <div className="p-grid">
            <div className="p-col">
              <div style={{ textAlign: "center" }}>
                Adair Asset Management, LLC
                <br />
                405 N 115th St Ste 100
                <br /> Omaha, NE 68154
                <br /> Phone 1-844-216-4341 | Fax 402-399-5350
                <br /> servicing@usassets.net | www.usassets.net
                <br /> Today's Date: {new Date().toLocaleString()} via www.usassets.net
                <br />
              </div>
            </div>
          </div>

          <div className="p-grid">
            <div className="p-col">
              Redemption of your Tax Certificate is contingent upon full payment of the redemption amount. While every effort has been made to include up to date
              information, the payoff statement set forth above may not accurately reflect some not-yet- charged costs and fees which will be required to be paid
              prior to the lien being released.
            </div>
          </div>
        </div>
        <div className="bottom-bar" style={{ width: "100%", height: "12px", backgroundColor: "#E5964C", marginTop: "40px" }}></div>
      </div>
    </React.Fragment >
  );
}

export default connect((state: ApplicationState) => state.parcel, ParcelStore.actionCreators)(ParcelDetailsPayoffRequestPrint as any);
