import react, * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../store";
import * as ParcelStore from "../store/parcel";
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import "./footer.scss";
import { AccountType } from "../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { IExpenseTracking } from "../models/IExpenseTracking";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";

interface ComponentProps {
  expenseTracking: IExpenseTracking;
}

type ExpenseTrackingItemProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & ComponentProps & RouteComponentProps<{}>;

const ExpenseTrackingItem: React.FC<ExpenseTrackingItemProps> = (props) => {
  const [selectedExpenseTracking, setSelectedExpenseTracking] = react.useState(props.expenseTracking);

  const toast: any = useRef(null);

  const notify: any = () => {
    toast.current.show({ severity: "success", summary: "Data Saved", detail: "Expense Data Saved Successfully!" });
  };

  return (
    <React.Fragment>
      <Toast ref={toast} position="top-right" />
      <div className="p-grid">
        <div className="p-col-2">
          <InputNumber
            style={{ textAlign: "right" }}
            inputId="currency-us"
            value={selectedExpenseTracking?.expenseAmt ?? 0}
            onChange={(e) => {
              setSelectedExpenseTracking({
                ...selectedExpenseTracking,
                expenseAmt: e.value,
              } as unknown as IExpenseTracking);
            }}
            onBlur={(e) => {
              props.updateExpenseTracking(selectedExpenseTracking as IExpenseTracking);
              notify();
            }}
            mode="currency"
            currency="USD"
            locale="en-US"
          />
        </div>
        <div className="p-col-2">
          <Calendar
            id="date"
            value={new Date(selectedExpenseTracking.expenseDate ?? new Date())}
            showIcon
            onSelect={(e) => {
              setSelectedExpenseTracking({ ...selectedExpenseTracking, expenseDate: e.value } as IExpenseTracking);
              props.updateExpenseTracking({ ...selectedExpenseTracking, expenseDate: e.value } as IExpenseTracking);
              notify();
            }}
          />
        </div>
        <div className="p-col-2">
          <InputText
            name="type"
            style={{ width: "100%" }}
            value={selectedExpenseTracking.expenseDescr}
            onChange={(e) => {
              setSelectedExpenseTracking({ ...selectedExpenseTracking, expenseDescr: (e.target as unknown as HTMLTextAreaElement).value } as IExpenseTracking);
            }}
            onBlur={(e) => {
              props.updateExpenseTracking(selectedExpenseTracking as IExpenseTracking);
              notify();
            }}
          />
        </div>
        <div className="p-col-2">
          <Checkbox
            inputId="binary"
            checked={selectedExpenseTracking?.accrueInterest ?? false}
            onChange={(e) => {
              setSelectedExpenseTracking({
                ...selectedExpenseTracking,
                accrueInterest: e.checked,
              } as unknown as IExpenseTracking);
              setTimeout(() => {
                props.updateExpenseTracking(selectedExpenseTracking as IExpenseTracking);
              }, 2500);

              notify();
            }}
          />
        </div>
        <div className="p-col-2">
          <Checkbox
            inputId="binary"
            checked={selectedExpenseTracking?.expenseRef ?? false}
            onChange={(e) => {
              setSelectedExpenseTracking({
                ...selectedExpenseTracking,
                expenseRef: e.checked,
              } as unknown as IExpenseTracking);
              props.updateExpenseTracking(selectedExpenseTracking as IExpenseTracking);
              notify();
            }}
          />
        </div>

        <div className="p-col-1">
          <Button
            //label={`Add`}
            icon="pi pi-trash"
            className="p-mr-2 p-button-outlined p-button-danger"
            onClick={() => {
              props.deleteExpenseTracking(selectedExpenseTracking.expenseID);
              props.clearLegalExpenseTracking();
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExpenseTrackingItem;
//export default connect((state: ApplicationState) => state.user, ParcelStore.actionCreators)(ExpenseTrackingItem as any);
