import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../../store";
import * as ParcelStore from "../../../../store/parcel";
import * as UserStore from "../../../../store/user";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { IParcel } from "../../../../models/IParcel";
import { InputSwitch } from "primereact/inputswitch";
import { AccountType } from "../../../../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { Toolbar } from "primereact/toolbar";
import { SplitButton } from "primereact/splitbutton";
import { Menu } from "primereact/menu";
import { Dialog } from "primereact/dialog";
import { IUser } from "../../../../models/IUser";

type ParcelDetailsProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & RouteComponentProps<{}>;

function ParcelDetails(props: ParcelDetailsProps) {
  const [selectedParcel, setSelectedParcel] = useState(props.selectedParcel);
  const [selectedParcelId, setSelectedParcelId] = useState(props.selectedParcel?.parcelID);
  const [relatedTaxCertificateMenuItems, setRelatedTaxCertificateMenuItems] = useState([]);
  const [isFromMyParcels, setIsFromMyParcels] = useState(false);
  const [isFromMyPayments, setIsFromMyPayments] = useState(false);
  const [displayLoginMessage, setDisplayLoginMessage] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(0);
  const [currentUser, setCurrentUser] = useState({} as IUser);
  let items: any;
  const menu: any = useRef(null);

  useEffect(() => {
    setSelectedParcel(props.selectedParcel);
    items = props.selectedParcel?.relatedTaxCertificates.map(function (object, i) {
      return {
        label: object.taxSaleNo,
        icon: "pi pi-reply",
        command: () => {
          setSelectedParcelId(object.parcelID);
          props.getParcel(object.parcelID);
        },
      };
    });
    setRelatedTaxCertificateMenuItems(items);
  }, [props.selectedParcel]);

  useEffect(() => {
    const selectedParcelId = (props.match.params as any).parcelId;
    const query = new URLSearchParams(props.location.search);
    const isFromMyParcelsParam = query.get("fromMyParcels") == "true";
    const isFromMyPaymentsParam = query.get("fromMyPayments") == "true";
    setIsFromMyParcels(isFromMyParcelsParam);
    setIsFromMyPayments(isFromMyPaymentsParam);
    setSelectedParcelId(selectedParcelId);
    props.getParcel(selectedParcelId);
    if (localStorage.getItem("token")) {
      setCurrentUserId((jwt_decode(localStorage.getItem("token") as string) as any).UserId);
      props.getCurrentUser((jwt_decode(localStorage.getItem("token") as string) as any).UserId);
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setCurrentUser(props.currentUser);
  }, [props.currentUser]);

  const notify = () => {
    toast.success("Settings Saved!");
  };

  const leftContents = (
    <React.Fragment>
      {!isFromMyParcels && !isFromMyPayments && (
        <Button
          label="Back"
          icon="pi pi-arrow-left"
          className="p-mr-2"
          onClick={() => {
            props.history.push(`/parcels/list`);
          }}
        />
      )}
      {isFromMyParcels && (
        <Button
          label="Back"
          icon="pi pi-arrow-left"
          className="p-mr-2"
          onClick={() => {
            props.history.push(`/taxpayer/parcels/mine`);
          }}
        />
      )}
      {isFromMyPayments && (
        <Button
          label="Back"
          icon="pi pi-arrow-left"
          className="p-mr-2"
          onClick={() => {
            props.history.push(`/taxpayer/payments/mine`);
          }}
        />
      )}
      {/* {(selectedParcel?.relatedTaxCertificates.length ?? 0) > 0 && (
        <div>
          <Menu model={relatedTaxCertificateMenuItems} popup ref={menu} />
          <Button
            label={`View Related Tax Certificates - (${props.selectedParcel?.relatedTaxCertificates.length})`}
            className="p-button-secondary"
            icon="pi pi-chevron-down"
            onClick={(event) => menu.current.toggle(event)}
          />
        </div>
      )} */}
    </React.Fragment>
  );

  const rightContents = (
    <React.Fragment>
      <Button
        className="p-mr-2  p-button-outlined"
        label="View Payoff Info"
        icon="pi pi-wallet"
        onClick={() => {
          props.history.push(`/parcels/${selectedParcelId}/payoffrequest`);
        }}
      />
      {(selectedParcel?.rtReceivedDate == null || selectedParcel?.rtReceivedDate == undefined) && (
        (selectedParcel?.hasPPS ?? 0) == 0 && (
          <Button
            className="p-mr-2  p-button-outlined"
            label="Request Payment Plan"
            icon="pi pi-comment"
            onClick={() => {
              if (props.currentUser.username == "" || props.currentUser.username == undefined) {
                setDisplayLoginMessage(true);
              } else {
                props.history.push(`/parcels/${selectedParcelId}/paymentplanrequest`);
              }
            }}
          />
        )
      )}
      {props.selectedParcel?.isMyParcel && (
        <Button
          className="p-mr-2  p-button-outlined"
          label="Remove From My Parcels"
          icon="pi pi-star-o"
          onClick={() => {
            if (props.currentUser.username == "" || props.currentUser.username == undefined) {
              setDisplayLoginMessage(true);
            } else {
              props.toggleMyParcel(selectedParcelId as number);
            }
          }}
        />
      )}
      {!props.selectedParcel?.isMyParcel && (
        <Button
          className="p-mr-2  p-button-outlined"
          label="Add to My Parcels"
          icon="pi pi-star"
          onClick={() => {
            if (props.currentUser.username == "" || props.currentUser.username == undefined) {
              setDisplayLoginMessage(true);
            } else {
              props.toggleMyParcel(selectedParcelId as number);
            }
          }}
        />
      )}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Toolbar left={leftContents} right={rightContents} />
      {(selectedParcel?.relatedTaxCertificates.length ?? 0) > 0 && (
        <div className="p-grid">
          <div className="p-col">
            <br />
            <Card className="related-parcels" title={`Related Tax Certificates`}>
              {props.selectedParcel?.relatedTaxCertificates.map((item: any) => {
                return (
                  <React.Fragment>
                    <Button
                      className={`p-mr-2  ${item.status == "Redeemed" ? "p-button-success" : "p-button-outlined"}`}
                      label={`${item.taxSaleNo} - ${item.status}`}
                      icon="pi pi-reply"
                      onClick={() => {
                        setSelectedParcelId(item.parcelID);
                        props.getParcel(item.parcelID);
                      }}
                    />
                  </React.Fragment>
                );
              })}
            </Card>
          </div>
        </div>
      )}
      {selectedParcel?.hasPPS && selectedParcel?.payPlanStatus != "DEFAULT" && selectedParcel.activePPS && currentUserId === 0 && (
        <React.Fragment>
          <br />
          <div className="p-grid">
            <div className="p-col">
              <Card title={`ATTENTION`} className={"red-text"}>
                Our records indicate tax certificate {selectedParcel.taxSaleNo} is in an active payment plan. You can click{" "}
                <a href={`/login?parcelId=${selectedParcelId}`}>here</a> to login and make a payment on this plan. If you would like to know the balance due or
                have additional questions regarding your payment plan, please contact us at <a href="mailto:servicing@usassets.net">servicing@usassets.net</a>{" "}
                or 1-844-216-4341.
              </Card>
            </div>
          </div>
        </React.Fragment>
      )}
      {selectedParcel?.hasPPS && selectedParcel?.payPlanStatus != "DEFAULT" && selectedParcel.activePPS && currentUserId !== 0 && (
        <React.Fragment>
          <br />
          <div className="p-grid">
            <div className="p-col">
              <Card title={`ATTENTION`} className={"red-text"}>
                Our records indicate tax certificate {selectedParcel.taxSaleNo} is in an active payment plan. You can click{" "}
                <a href={`/parcels/${selectedParcelId}/payoffrequest`}>here</a> to make a payment on this plan. If you would like to know the balance due or
                have additional questions regarding your payment plan, please contact us at <a href="mailto:servicing@usassets.net">servicing@usassets.net</a>{" "}
                or 1-844-216-4341.
              </Card>
            </div>
          </div>
        </React.Fragment>
      )}
      {selectedParcel?.hasPPS && !selectedParcel.activePPS && (
        <React.Fragment>
          <br />
          <div className="p-grid">
            <div className="p-col">
              <Card title={`ATTENTION`} className={"red-text"}>
                Our records indicate tax certificate {selectedParcel.taxSaleNo} is in an inactive payment plan. If you would like to know the balance due or
                have additional questions regarding your payment plan, please contact us at <a href="mailto:servicing@usassets.net">servicing@usassets.net</a>{" "}
                or 1-844-216-4341.
              </Card>
            </div>
          </div>
        </React.Fragment>
      )}
      {((selectedParcel?.paymentPending != null && selectedParcel.paymentPending) || (selectedParcel?.redemptionInProcDate != null)) && selectedParcel.rtReceivedDate == null && (
        <div>
          <br />
          <div className="p-message p-component p-message-success p-message-enter-done">
            <div className="p-m-2">
              <br />
              <h4>Payment has been submitted and is awaiting processing. </h4>
              <br />
            </div>
          </div>
        </div>
      )}
      {selectedParcel?.rtReceivedDate != null && selectedParcel.rtReceivedDate && (
        <div>
          <br />
          <div className="p-message p-component p-message-success p-message-enter-done">
            <div className="p-m-2">
              <br />
              <h4>Paid in Full {new Date(selectedParcel?.rtReceivedDate ?? "").toLocaleString().split(",")[0]} - $0 due</h4>
              <br />
            </div>
          </div>
        </div>
      )}
      <br />
      <div className="p-grid">
        <div className="p-col-12 p-md-6">
          <Card title={`Tax Certificate ${selectedParcel?.taxSaleNo}`} subTitle={`Parcel Number ${selectedParcel?.parcelNo}`} style={{ height: "524px" }}>
            <label className="field-label">
              <i className="pi pi-user"></i>&nbsp; Owner:
            </label>
            <br /> {selectedParcel?.ownerName}
            <br />
            <br />
            <label className="field-label">
              <i className="pi pi-map"></i>&nbsp; Property Address:
            </label>
            <br />
            {selectedParcel?.propertyAddress}
            <br />
            {selectedParcel?.propertyCity}, {selectedParcel?.stateName}, {selectedParcel?.propertyZip}
            <br />
            <br />
            <label className="field-label">
              <i className="pi pi-calendar"></i>&nbsp; Purchase Date:
            </label>
            <br />
            {new Date(selectedParcel?.purchaseDate ?? "1/1/1970").toLocaleString().split(",")[0]}
            <br />
            <br />
            {selectedParcel?.caseNum != undefined && (
              <div>
                <label className="field-label">
                  <i className="pi pi-briefcase"></i>&nbsp; Case Number:{" "}
                </label>
                <br />
                <span>{selectedParcel?.caseNum}</span>
                <br />
                <br />
              </div>
            )}
            {selectedParcel?.claimNum != undefined && (
              <div>
                <label className="field-label">
                  <i className="pi pi-briefcase"></i>&nbsp;Bankruptcy Case Number:{" "}
                </label>
                <br />
                <span>{selectedParcel?.claimNum}</span>
                <br />
                <br />
                <br />
                <br />
              </div>
            )}
          </Card>
        </div>
        <div className="p-col-12 p-md-6">
          <Card
            title={`${selectedParcel?.propertyAddress}`}
            subTitle={`${selectedParcel?.propertyCity}, ${selectedParcel?.stateName}, ${selectedParcel?.propertyZip}`}
            style={{ height: "524px" }}
          >
            <div style={{ width: "100%", overflow: "hidden", height: "398px" }}>
              <iframe
                width="100%"
                height="700"
                loading="lazy"
                style={{ marginTop: "-150px" }}
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC2-toCyYhXyGwn4PBxifmdDT9ZOAjQ2ec&q=${selectedParcel?.propertyAddress ?? ""},${selectedParcel?.propertyCity
                  }+${selectedParcel?.stateName}`}
              ></iframe>
            </div>
          </Card>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Dialog header="Please Log In" visible={displayLoginMessage} style={{ width: "50vw" }} onHide={() => setDisplayLoginMessage(false)}>
        <p>You must be logged in to perform this action.</p>
        <p>
          <Button
            className="p-mr-2  p-button-outlined"
            label="Log in"
            icon="pi pi-user"
            onClick={() => {
              props.history.push(`/login`);
            }}
          />
          <Button
            className="p-mr-2  p-button-outlined"
            label="Create an account"
            icon="pi pi-user-plus"
            onClick={() => {
              props.history.push(`/signup`);
            }}
          />
        </p>
      </Dialog>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.parcel, ParcelStore.actionCreators)(ParcelDetails as any);
