import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as UserStore from "../../../store/user";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { IUser } from "../../../models/IUser";
import { InputSwitch } from "primereact/inputswitch";
import { AccountType } from "../../../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { ICounty } from "../../../models/ICounty";
import { InputMask } from "primereact/inputmask";

type UserEditProps = UserStore.UserState & typeof UserStore.actionCreators & RouteComponentProps<{}>;

function UserEdit(props: UserEditProps) {
  const [selectedUser, setSelectedUser] = useState({ ...props.selectedUser } as IUser);
  const [selectedUserCounty, setSelectedUserCounty] = useState(0);
  const [selectedUserAttorney, setSelectedUserAttorney] = useState(0);
  const [counties, setCounties] = useState([{ label: "Test", value: 0 }]);
  const [attorneys, setAttorneys] = useState([{ label: "Test", value: 0 }]);

  useEffect(() => {
    setSelectedUser(props.selectedUser as IUser);
  }, [props.selectedUser]);

  useEffect(() => {
    setCounties(
      props.counties.map(function (item) {
        return { label: `${item.countyName}, ${item.stateID}`, value: item.countyID };
      })
    );
  }, [props.counties]);

  useEffect(() => {
    setAttorneys(
      props.attorneys.map(function (item) {
        return { label: `${item.lastName}, ${item.firstName}`, value: item.attorneyID };
      })
    );
  }, [props.attorneys]);

  useEffect(() => {
    const selectedUserId = (props.match.params as any).userId;
    props.getUser(selectedUserId);
    props.getCounties();
    props.getAttorneys();
  }, []);

  const notify = () => {
    toast.success("Profile Saved!");
  };

  return (
    <React.Fragment>
      <div className="p-grid">
        <div className="p-col-4"></div>
        <div className="p-col-3">
          <Card title="Edit User" subTitle="" style={{ width: "500px" }}>
            {(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.Admin ? (
              <div>
                <label>User Type:</label>
                <br />
                <Dropdown
                  value={(selectedUser as IUser).accountType}
                  options={[
                    { label: "TaxPayer", value: AccountType.TaxPayer },
                    { label: "County", value: AccountType.County },
                    { label: "Legal", value: AccountType.Legal },
                    { label: "Admin", value: AccountType.Admin },
                  ]}
                  onChange={(e) => {
                    setSelectedUser({ ...(selectedUser as IUser), accountType: e.target.value });
                  }}
                  placeholder="Select an Account Type"
                />
                <br />
                <br />
              </div>
            ) : (
              ""
            )}
            <label>Username:</label>
            <br />
            <InputText
              name="username"
              value={(selectedUser as IUser).username}
              onChange={(e) => setSelectedUser({ ...(selectedUser as IUser), username: (e.target as unknown as HTMLTextAreaElement).value })}
            />
            <br />
            <br />
            <label>First Name:</label>
            <br />
            <InputText
              name="first"
              value={(selectedUser as IUser).firstName}
              onChange={(e) => setSelectedUser({ ...(selectedUser as IUser), firstName: (e.target as unknown as HTMLTextAreaElement).value })}
            />
            <br />
            <br />
            <label>Last Name:</label>
            <br />
            <InputText
              name="last"
              value={(selectedUser as IUser).lastName}
              onChange={(e) => setSelectedUser({ ...(selectedUser as IUser), lastName: (e.target as unknown as HTMLTextAreaElement).value })}
            />
            <br />
            <br />
            <label>Email:</label>
            <br />
            <InputText
              name="email"
              keyfilter="email"
              //validateOnly={true}
              value={(selectedUser as IUser).email}
              onChange={(e) => setSelectedUser({ ...(selectedUser as IUser), email: (e.target as unknown as HTMLTextAreaElement).value })}
            />
            {/* <InputMask
              id="email"
              mask=""
              placeholder="taxpayer@usassets.net"
              value={(selectedUser as IUser).email}
              onChange={(e) => setSelectedUser({ ...(selectedUser as IUser), email: (e.target as unknown as HTMLTextAreaElement).value })}
            ></InputMask> */}
            <br />
            <br />
            <label>Phone:</label>
            <br />
            {/* <InputText
              name="phone"
              value={(selectedUser as IUser).phone}
              onChange={(e) => setSelectedUser({ ...(selectedUser as IUser), phone: (e.target as unknown as HTMLTextAreaElement).value })}
            /> */}
            <InputMask
              id="phone"
              mask="(999) 999-9999"
              value={(selectedUser as IUser).phone}
              placeholder="(999) 999-9999"
              onChange={(e) => setSelectedUser({ ...(selectedUser as IUser), phone: (e.target as unknown as HTMLTextAreaElement).value })}
            ></InputMask>
            <br />
            <br />
            <label>Address 1:</label>
            <br />
            <InputText
              name="address1"
              value={(selectedUser as IUser).address1}
              onChange={(e) => setSelectedUser({ ...(selectedUser as IUser), address1: (e.target as unknown as HTMLTextAreaElement).value })}
            />
            <br />
            <br />
            <label>Address 2:</label>
            <br />
            <InputText
              name="address2"
              value={(selectedUser as IUser).address2}
              onChange={(e) => setSelectedUser({ ...(selectedUser as IUser), address2: (e.target as unknown as HTMLTextAreaElement).value })}
            />
            <br />
            <br />
            <label>City:</label>
            <br />
            <InputText
              name="city"
              value={(selectedUser as IUser).city}
              onChange={(e) => setSelectedUser({ ...(selectedUser as IUser), city: (e.target as unknown as HTMLTextAreaElement).value })}
            />
            <br />
            <br />
            <label>State:</label>
            <br />
            <InputText
              name="state"
              value={(selectedUser as IUser).state}
              onChange={(e) => setSelectedUser({ ...(selectedUser as IUser), state: (e.target as unknown as HTMLTextAreaElement).value })}
            />
            <br />
            <br />
            <label>Zip:</label>
            <br />
            <InputText
              name="zip"
              value={(selectedUser as IUser).zip}
              onChange={(e) => setSelectedUser({ ...(selectedUser as IUser), zip: (e.target as unknown as HTMLTextAreaElement).value })}
            />
            <br />
            <br />
            {(selectedUser as IUser).accountType == AccountType.County &&
              (jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.Admin ? (
              <div>
                <label>County:</label>
                <br />
                <Dropdown
                  value={selectedUser?.countyId ?? 0}
                  options={counties}
                  onChange={(e) => {
                    console.log(e.value);
                    setSelectedUserCounty(e.value);
                    setSelectedUser({ ...(selectedUser as IUser), countyId: e.value });
                  }}
                  placeholder="Select A County"
                />
                <br />
                <br />
              </div>
            ) : (
              ""
            )}
            {(selectedUser as IUser).accountType == AccountType.Legal && (jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.Admin ? (
              <div>
                <label>Attorney Contact:</label>
                <br />
                <Dropdown
                  value={selectedUser?.attorneyID ?? 0}
                  options={attorneys}
                  onChange={(e) => {
                    console.log(e.value);
                    setSelectedUserAttorney(e.value);
                    setSelectedUser({ ...(selectedUser as IUser), attorneyID: e.value });
                  }}
                  placeholder="Select A Contact"
                />
                <br />
                <br />
                <label>Show Ref Number on Grid:</label>
                <br />
                <InputSwitch
                  checked={(selectedUser as IUser).showRefNumLegal}
                  onChange={(e) => setSelectedUser({ ...(selectedUser as IUser), showRefNumLegal: e.value as boolean })}
                />
                <br />
                <br />
              </div>
            ) : (
              ""
            )}

            {/* {(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.Admin ? (
              <div>
                <label>ACH Last 4:</label>
                <br />
                <span>{(selectedUser as IUser).achLast4}</span>
                <br />
                <br />
              </div>
            ) : (
              ""
            )}

            {(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.Admin ? (
              <div>
                <label>ACH Institution:</label>
                <br />
                <span>{(selectedUser as IUser).institution}</span>
                <br />
                <br />
              </div>
            ) : (
              ""
            )} */}

            {(jwt_decode(localStorage.getItem("token") as string) as any).AccountType == AccountType.Admin ? (
              <div>
                <label>Approved:</label>
                <br />
                <InputSwitch checked={(selectedUser as IUser).approved} onChange={(e) => setSelectedUser({ ...(selectedUser as IUser), approved: e.value as boolean })} />
                <br />
                <br />
              </div>
            ) : (
              ""
            )}
            <Button
              label="Save"
              onClick={() => {
                if (
                  (selectedUser as IUser).email === "" ||
                  (selectedUser as IUser).firstName === "" ||
                  (selectedUser as IUser).lastName === "" ||
                  (selectedUser as IUser).phone === "" ||
                  (selectedUser as IUser).username === ""
                ) {
                  alert("All fields are required!");
                } else {
                  props.updateUser({ ...selectedUser } as IUser);
                  notify();
                }
              }}
            />
          </Card>
        </div>
        <div className="p-col-2"></div>
      </div>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(UserEdit as any);
