import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../../store";
import * as ParcelStore from "../../../../store/parcel";
import * as UserStore from "../../../../store/user";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { IParcel } from "../../../../models/IParcel";
import { InputSwitch } from "primereact/inputswitch";
import { AccountType } from "../../../../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { Toolbar } from "primereact/toolbar";
import { SplitButton } from "primereact/splitbutton";
import { Menu } from "primereact/menu";
import { Dialog } from "primereact/dialog";

type PaymentProcessSuccessProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & RouteComponentProps<{}>;

function PaymentProcessSuccess(props: PaymentProcessSuccessProps) {
  const [selectedParcel, setSelectedParcel] = useState(props.selectedParcel);
  const [selectedParcelId, setSelectedParcelId] = useState(props.selectedParcel?.parcelID);
  const [isFromMyParcels, setIsFromMyParcels] = useState(false);
  const [hasUpdatedPaymentFlag, setHasUpdatedPaymentFlag] = useState(false);

  useEffect(() => {
    const selectedParcelId = (props.match.params as any).parcelId;
    const query = new URLSearchParams(props.location.search);
    const isFromMyParcelsParam = query.get("fromMyParcels") == "true";
    setIsFromMyParcels(isFromMyParcelsParam);
    setSelectedParcelId(selectedParcelId);
    props.getParcel(selectedParcelId);
    console.log("herefirst");
    if (!hasUpdatedPaymentFlag && props.selectedParcel) {
      console.log("here");
      props.updateParcel({ ...props.selectedParcel, paymentPending: true } as IParcel);
      setHasUpdatedPaymentFlag(true);
    }
  }, []);

  useEffect(() => {
    if (!hasUpdatedPaymentFlag && props.selectedParcel) {
      props.updateParcel({ ...props.selectedParcel, paymentPending: true } as IParcel);
      setHasUpdatedPaymentFlag(true);
    }
  }, [props.selectedParcel]);

  const leftContents = (
    <React.Fragment>
      <Button
        label="Back"
        icon="pi pi-arrow-left"
        className="p-mr-2"
        onClick={() => {
          props.history.push(`/parcels/${selectedParcelId}`);
        }}
      />
    </React.Fragment>
  );

  const rightContents = <React.Fragment></React.Fragment>;

  return (
    <React.Fragment>
      <div>
        <Toolbar left={leftContents} right={rightContents} />
      </div>
      <div>
        <br />
        <div className="p-message p-component p-message-success p-message-enter-done">
          <div className="p-m-2">
            <br />
            <h4>
              Thank you for your payment! Once Adair Asset Management has received confirmation that the funds have cleared, the payment will be posted to your account. If you have any further questions, please contact us by email at <a href="mailto:servicing@usassets.net">servicing@usassets.net</a> or by calling 1-844-216-4341.
            </h4>
            <br />
          </div>
        </div>
      </div>
      <br />
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.parcel, ParcelStore.actionCreators)(PaymentProcessSuccess as any);
