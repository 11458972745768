import React, { FunctionComponent } from "react";
import { Route, Switch } from "react-router-dom";
import { APP_ROUTES } from "./app.routing.constants";
import Home from "../../components/Home";
import payment from "../../components/Payment";
import adminSettings from "../../pages/admin/settings/adminSettings";
import userList from "../../pages/admin/userList/userList";
import userEdit from "../../pages/admin/userEdit/userEdit";
import adminLanding from "../../pages/admin/adminLanding";
import parcelList from "../../pages/taxpayer/parcel/list/parcelList";
import parcelDetails from "../../pages/taxpayer/parcel/details/parcelDetails";
import countylanding from "../../pages/county/countyLanding";
import countyParcelDetails from "../../pages/county/parcel/details/countyParcelDetails";
import countyParcelDetailsPayoff from "../../pages/county/parcel/details/countyParcelDetailsPayoff";
import countyParcelList from "../../pages/county/parcel/list/countyParcelList";
import countyReports from "../../pages/county/countyReports";
import legalLanding from "../../pages/legal/legalLanding";
import legalParcelList from "../../pages/legal/parcel/list/legalParcelList";
import legalParcelDetails from "../../pages/legal/parcel/details/legalParcelDetails";
import myParcels from "../../pages/taxpayer/parcel/list/myParcels";
import countyPaymentPlansReport from "../../pages/county/reports/countyPaymentPlansReport";
import expensesByCertificateReport from "../../pages/county/reports/expensesByCertificateReport";
import myPayments from "../../pages/taxpayer/parcel/list/myPayments";
import countyList from "../../pages/admin/countyAdmin/countyList";
import countyEdit from "../../pages/admin/countyAdmin/countyEdit";
import paymentList from "../../pages/admin/paymentList/paymentList";
import webPaymentLogList from "../../pages/admin/webPaymentLogList/webPaymentLogList";

export const AppRouting: FunctionComponent = () => (
  <Switch>
    <Route exact path={APP_ROUTES.DASHBOARD} component={Home} />

    <Route exact path={APP_ROUTES.USER_LIST} component={userList} />
    <Route exact path={APP_ROUTES.USER_EDIT} component={userEdit} />
    <Route exact path={APP_ROUTES.COUNTY_LIST} component={countyList} />
    <Route exact path={APP_ROUTES.COUNTY_EDIT} component={countyEdit} />
    <Route exact path={APP_ROUTES.ADMIN_SETTINGS} component={adminSettings} />
    <Route exact path={APP_ROUTES.ADMIN_LANDING} component={adminLanding} />
    <Route exact path={APP_ROUTES.ADMIN_PAYMENTS} component={paymentList} />
    <Route exact path={APP_ROUTES.ADMIN_WEBPAYMENTSLOG} component={webPaymentLogList} />
    <Route exact path={APP_ROUTES.TAXPAYER_LANDING} component={myParcels} />
    <Route exact path={APP_ROUTES.TAXPAYER_MY_PARCELS} component={myParcels} />
    <Route exact path={APP_ROUTES.TAXPAYER_MY_PAYMENTS} component={myPayments} />
    <Route exact path={APP_ROUTES.TAXPAYER_PARCEL_LIST} component={parcelList} />
    <Route exact path={APP_ROUTES.TAXPAYER_PARCEL_DETAILS} component={parcelDetails} />
    <Route exact path={APP_ROUTES.COUNTY_LANDING} component={countylanding} />
    <Route exact path={APP_ROUTES.COUNTY_REPORTS} component={countyReports} />
    <Route exact path={APP_ROUTES.COUNTY_PAYMENT_PLANS_REPORT} component={countyPaymentPlansReport} />
    <Route exact path={APP_ROUTES.COUNTY_EXPENSES_BY_CERTIFICATE_REPORT} component={expensesByCertificateReport} />
    <Route exact path={APP_ROUTES.COUNTY_PARCELS} component={countyParcelList} />
    <Route exact path={APP_ROUTES.COUNTY_PARCEL_DETAILS} component={countyParcelDetails} />
    <Route exact path={APP_ROUTES.COUNTY_PARCEL_DETAILS_PAYOFF} component={countyParcelDetailsPayoff} />
    <Route exact path={APP_ROUTES.LEGAL_LANDING} component={legalLanding} />
    <Route exact path={APP_ROUTES.LEGAL_PARCELS} component={legalParcelList} />
    <Route exact path={APP_ROUTES.LEGAL_PARCEL_DETAILS} component={legalParcelDetails} />
    <Route exact path={APP_ROUTES.PAYMENT} component={payment} />
  </Switch>
);
