import { combineReducers } from "redux";
import * as ParcelState from "./parcel";
import * as UserState from "./user";
import * as UserAdminState from "./userAdmin";
import * as AdminSettingsState from "./adminSettings";

// The top-level state object
export interface ApplicationState {
  user: UserState.UserState | undefined;
  userAdmin: UserAdminState.UserAdminState | undefined;
  adminSettings: AdminSettingsState.AdminSettingsState | undefined;
  parcel: ParcelState.ParcelState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  user: UserState.reducer,
  userAdmin: UserAdminState.reducer,
  adminSettings: AdminSettingsState.reducer,
  parcel: ParcelState.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
