import * as React from 'react';
import Layout from './components/Layout';

import './custom.scss'
import { Routing } from './routing/routing';
import { ToastContainer } from 'react-toastify';

export default () => (
    <Layout>
        <ToastContainer />
        <Routing/>
    </Layout>
);
