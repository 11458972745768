import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as UserStore from "../../../store/user";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useRef, useState } from "react";
import { render } from "react-dom";
import { Card } from "primereact/card";

type UserListProps = UserStore.UserState & UserStore.UserState & typeof UserStore.actionCreators & typeof UserStore.actionCreators & RouteComponentProps<{}>;

function UserList(props: UserListProps) {
  const [userList, setUserList] = useState(props.userList);
  const [selectedApprovedFilterValue, setSelectedApprovedFilterValue] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");
  const [boolText, setBoolText] = useState([
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ]);

  useEffect(() => {
    props.getUsers();
    props.getCounties();
  }, []);

  useEffect(() => {
    setUserList(props.userList);
  }, [props.userList]);

  const dt = useRef<DataTable<any>>(null);

  const onApprovedFilterValueChange: any = (event: any) => {
    if (dt && dt.current != null) {
      dt.current.filter(event.value, "approved", "equals");
    }
  };

  const accountTypeTemplate: any = (rowData: any) => {
    if (rowData.accountType === 0) {
      return "Admin";
    } else if (rowData.accountType === 1) {
      if (rowData.approved) {
        return (
          <div>
            County <br />
            {/* {rowData.county.countyName} */}
          </div>
        );
      } else {
        return "County - Pending";
      }
    } else if (rowData.accountType === 3) {
      if (rowData.approved) {
        return (
          <div>
            Legal <br />
            {/* {rowData.county.countyName} */}
          </div>
        );
      } else {
        return "Legal - Pending";
      }
    } else if (rowData.accountType === 2) {
      return <div>TaxPayer</div>;
    }
  };

  const isApprovedTemplate: any = (rowData: any) => {
    let value = rowData.approved as boolean;

    if (value) {
      return <span>Yes</span>;
    } else {
      return <span>No</span>;
    }
  };

  const formatDate: any = (rowData: any) => {
    let value = new Date(rowData.created);

    return value.toLocaleString();
  };

  const nameTemplate: any = (rowData: any) => {
    return `${rowData.lastName}, ${rowData.firstName}`;
  };

  const onExport = () => {
    if (dt && dt.current != null) dt.current.exportCSV({ selectionOnly: false });
  };

  const handleRowClick: any = (e: any) => {
    props.history.push(`/users/${e.data.id}`);
  };

  const header = (
    <div style={{ textAlign: "left" }}>
      <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
      <InputText type="search" onInput={(e) => setGlobalFilter((e.target as unknown as HTMLTextAreaElement).value)} placeholder="Filter" size={50} />
      {/* <Button className="float-right" type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={onExport}></Button> */}
    </div>
  );

  const approvedFilter = (
    <Dropdown
      style={{ width: "100%" }}
      placeholder="Select a value"
      value={selectedApprovedFilterValue}
      options={boolText}
      onChange={onApprovedFilterValueChange}
      showClear
    />
  );

  const paginatorLeft = <Button icon="pi pi-refresh" />;

  return (
    <React.Fragment>
      <Card title="Users"></Card>
      <br />
      <DataTable
        filterDisplay="row"
        ref={dt}
        value={props.userList}
        onRowClick={($event) => handleRowClick($event)}
        selectionMode="single"
        header={header}
        globalFilter={globalFilter}
        paginator={true}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        rows={20}
        rowsPerPageOptions={[5, 10, 20, 50]}
      //stateStorage="local"
      //stateKey="dt-state-local-users"
      >
        <Column field="username" header="Username" sortable={true} filter />
        <Column field="lastName" header="Name" sortable={true} body={nameTemplate} filter />
        <Column field="firstName" header="Name" sortable={true} style={{ display: "none" }} filter />
        <Column field="email" header="email" sortable={true} style={{ width: "325px" }} filter />
        <Column field="accountType" header="Type" sortable={true} body={accountTypeTemplate} style={{ width: "150px" }} />
        <Column
          field="approved"
          header="Approved"
          sortable={true}
          body={isApprovedTemplate}
          style={{ width: "200px" }}
          filter={true}
          filterElement={approvedFilter}
        />
        <Column field="created" header="Created" sortable={true} body={formatDate} />
        <Column field="county.countyName" header="County Name" sortable={true} style={{ display: "none" }} />
      </DataTable>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.user, UserStore.actionCreators)(UserList as any);
