export const AUTH_ROUTES = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  SIGNUP_TAXPAYER: "/signupTaxPayer",
  SIGNUP_ADMIN: "/signupAdmin",
  SIGNUP_COUNTY: "/signupCounty",
  CONFIRMATION: "/confirmation",
  CONFIRMATION_TAXPAYER: "/confirmationTaxPayer",
  FORGOT_PASSWORD: "/forgotPassword",
  RESET_PASSWORD: "/resetPassword/:token",
  RESET_PASSWORD_CONFIRMATION: "/resetPasswordConfirmation",
  FORGOT_PASSWORD_CONFIRMATION: "/forgotPasswordConfirmation",
  HOME: "/home",
  ABOUT: "/about",
  FAQ: "/faq",
  DISCLAIMER: "/disclaimer",
  HELP: "/help",
  PRIVACY: "/privacy",
  CONTACT: "/contact",

  PARCEL_DETAILS_PAYOFF_REQUEST: "/parcels/:parcelId/payoffrequest",
  PARCEL_DETAILS_PAYMENT_PLAN_REQUEST: "/parcels/:parcelId/paymentplanrequest",
  PARCEL_DETAILS_PAYMENT_PLAN_REQUEST_PRINT: "/parcels/:parcelId/paymentplanrequestprint",
  PARCEL_DETAILS_PAYMENT_PLAN_REQUEST_SUCCESS: "/parcels/:parcelId/paymentplanrequestsuccess",

  PARCEL_DETAILS_PAYMENT_PROCESS_SUCCESS: "/parcels/:parcelId/paymentprocesssuccess",
  PARCEL_DETAILS_PAYMENT_PROCESS_CANCEL: "/parcels/:parcelId/paymentprocesscancel",

  PARCEL_LIST: "/parcels/list",
  PARCEL_SEARCH: "/parcels/search",
  PARCEL_DETAILS: "/parcels/:parcelId",
};
