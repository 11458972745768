import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { IParcel } from '../../models/IParcel';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from "primereact/toast";

export default function editLegalDates(props: any) {
    const [selectedParcel, setSelectedParcel] = useState(props.selectedParcel);

    const toast: any = useRef(null);

    const notify: any = () => {
        toast.current.show({ severity: "success", summary: "Data Saved", detail: "Parcel Data Saved Successfully!" });
    };


    const bankruptcyTypes = [
        { name: "Chapter 7", code: "7" },
        { name: "Chapter 11", code: "11" },
        { name: "Chapter 13", code: "13" },
    ];

    useEffect(() => {
        setSelectedParcel(props.selectedParcel);
    }, [props.selectedParcel]);

    const addDays = (date: Date | null | undefined, days: number) => {
        var dt = new Date(selectedParcel?.noiFiledDate ?? "");
        dt.setDate(dt.getDate() + days);
        return dt;
    };


    const formatCurrency = (value: number) => {
        if (value != null) {
            return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
        } else {
            return "$0.00";
        }
    };


    return (
        <React.Fragment>
            <Toast ref={toast} position="top-right" />
            <div className="p-grid">
                <div className="p-col">
                    <div className="p-grid">
                        <div className="p-col">
                            <label className="field-label">Bankruptcy Type:</label>
                            <br />
                            <Dropdown
                                optionLabel="name"
                                optionValue="code"
                                value={selectedParcel?.bankruptcyType}
                                options={bankruptcyTypes}
                                onChange={(e) => {
                                    setSelectedParcel({ ...selectedParcel, bankruptcyType: e.target.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, bankruptcyType: e.target.value } as IParcel);
                                    notify();
                                }}
                                placeholder="Select A Type"
                            />
                            <br />

                            <label className="field-label">Date Filed:</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.bankruptcyInDate ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, bankruptcyInDate: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, bankruptcyInDate: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, bankruptcyInDate: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, bankruptcyInDate: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />

                            <label className="field-label">Date Discharged/Dismissed:</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.bankruptcyOutDate ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, bankruptcyOutDate: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, bankruptcyOutDate: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, bankruptcyOutDate: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, bankruptcyOutDate: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />

                            <label className="field-label">POC Filed:</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.pocFiled ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, pocFiled: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, pocFiled: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, pocFiled: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, pocFiled: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />

                            <label className="field-label">Bankruptcy Case Number:</label>
                            <br />
                            <InputText
                                name="claimNum"
                                value={selectedParcel?.claimNum as string}
                                onChange={(e) => {
                                    setSelectedParcel({ ...selectedParcel, claimNum: (e.target as unknown as HTMLTextAreaElement).value } as IParcel);
                                }}
                                onBlur={(e) => {
                                    props.updateParcel(selectedParcel as IParcel);
                                    notify();
                                }}
                            />
                        </div>
                    </div>


                    <br />

                    <label className="field-label">Certificate Redeemed Date:</label>
                    <br />
                    <Calendar id="icon" value={new Date(selectedParcel?.rtReceivedDate ?? "")} showIcon disabled />
                    <br />

                    <label className="field-label">Dismissal/Motion to Vacate Filed:</label>
                    <br />
                    <Calendar
                        id="icon"
                        value={new Date(selectedParcel?.dismissalFiled ?? "")}
                        showIcon
                        showButtonBar
                        onSelect={(e) => {
                            setSelectedParcel({ ...selectedParcel, dismissalFiled: e.value } as IParcel);
                            props.updateParcel({ ...selectedParcel, dismissalFiled: e.value } as IParcel);
                            notify();
                        }}
                        onClearButtonClick={(e) => {
                            confirmDialog({
                                message: "Are you sure you want to clear this value?",
                                header: "Are you sure?",
                                icon: "pi pi-exclamation-triangle",
                                accept: () => {
                                    setSelectedParcel({ ...selectedParcel, dismissalFiled: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, dismissalFiled: undefined } as IParcel);
                                    notify();
                                },
                            });
                        }}
                    />

                </div>
                <div className="p-col">

                    <div className="p-grid">
                        <div className="p-col">
                            <label className="field-label">RTF Mailed:</label>
                            <br />
                            <Calendar
                                id="sentLegalFile"
                                value={new Date(selectedParcel?.sentLegalFile ?? "")}
                                showIcon
                                showButtonBar
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, sentLegalFile: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, sentLegalFile: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, sentLegalFile: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, sentLegalFile: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />

                            <label className="field-label">NOI Filed/RTF Rcvd by Pros Office:</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.noiFiledDate ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, noiFiledDate: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, noiFiledDate: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, noiFiledDate: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, noiFiledDate: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />

                            <label className="field-label">Deadline to file complaint:</label>
                            <br />
                            {selectedParcel?.noiFiledDate && <Calendar id="icon" disabled value={addDays(selectedParcel?.noiFiledDate, 90)} showIcon />}
                            <br />

                            <label className="field-label">Title Ordered:</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.lienSearchDate ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, lienSearchDate: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, lienSearchDate: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, lienSearchDate: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, lienSearchDate: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />

                            <label className="field-label">Title Received:</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.lienSearchReceivedDate ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, lienSearchReceivedDate: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, lienSearchReceivedDate: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, lienSearchReceivedDate: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, lienSearchReceivedDate: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />

                            <label className="field-label">Complaint Filed:</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.complaintFiledDate ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, complaintFiledDate: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, complaintFiledDate: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, complaintFiledDate: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, complaintFiledDate: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />

                            <label className="field-label">Publication Requested:</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.publicationDate ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, publicationDate: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, publicationDate: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, publicationDate: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, publicationDate: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />

                            <label className="field-label">Proof of Publications Filed:</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.publicationReceivedDate ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, publicationReceivedDate: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, publicationReceivedDate: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, publicationReceivedDate: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, publicationReceivedDate: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />
                        </div>
                    </div>

                </div>
                <div className="p-col">

                    <div className="p-grid">
                        <div className="p-col">
                            <label className="field-label">All Parties Served:</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.allPartiesServed ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, allPartiesServed: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, allPartiesServed: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, allPartiesServed: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, allPartiesServed: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />

                            <label className="field-label">Requested Judgement:</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.fileMSJPD ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, fileMSJPD: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, fileMSJPD: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, fileMSJPD: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, fileMSJPD: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />

                            <label className="field-label">Decree of Forclosure</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.judgmentEntered ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, judgmentEntered: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, judgmentEntered: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, judgmentEntered: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, judgmentEntered: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />

                            <label className="field-label">Order of Sale Filed:</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.writRequested ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, writRequested: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, writRequested: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, writRequested: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, writRequested: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />

                            <label className="field-label">1st Sale Date:</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.sheriffSaleDate ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, sheriffSaleDate: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, sheriffSaleDate: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, sheriffSaleDate: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, sheriffSaleDate: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />

                            <label className="field-label">2nd Sale Date:</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.secondSheriffsSaleDate ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, secondSheriffsSaleDate: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, secondSheriffsSaleDate: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, secondSheriffsSaleDate: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, secondSheriffsSaleDate: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />

                            <label className="field-label">Result of Sale:</label>
                            <br />
                            <Dropdown
                                value={selectedParcel?.resultOfSale}
                                options={[
                                    { label: "Forfeited", value: "Forfeited" },
                                    { label: "Sold to 3rd Party", value: "Sold to 3rd Party" },
                                ]}
                                onChange={(e) => {
                                    setSelectedParcel({ ...selectedParcel, resultOfSale: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, resultOfSale: e.value } as IParcel);
                                    notify();
                                }}
                                placeholder="Select Result"
                            />
                            <br />
                        </div>
                    </div>

                </div>
                <div className="p-col">

                    <div className="p-grid">
                        <div className="p-col">
                            <label className="field-label">Motion to Confirm Sale Filed:</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.motionConfirmSale ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, motionConfirmSale: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, motionConfirmSale: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, motionConfirmSale: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, motionConfirmSale: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />

                            <label className="field-label">Order to Confirm Sale Signed:</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.orderConfirmSale ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, orderConfirmSale: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, orderConfirmSale: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, orderConfirmSale: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, orderConfirmSale: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />

                            <label className="field-label">Adair Rec'd $ From Sale:</label>
                            <br />
                            <InputText name="webRefNum" value={formatCurrency(selectedParcel?.rtAmountReceived ?? 0)} disabled />
                            <br />

                            <label className="field-label">Motion for Forfeiture Filed:</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.motionForForfeiture ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, motionForForfeiture: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, motionForForfeiture: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, motionForForfeiture: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, motionForForfeiture: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />

                            <label className="field-label">Decree of Forfeiture Signed:</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.decreeOfForfeiture ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, decreeOfForfeiture: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, decreeOfForfeiture: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, decreeOfForfeiture: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, decreeOfForfeiture: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />

                            <label className="field-label">Sheriffs Deed Recorded:</label>
                            <br />
                            <Calendar
                                id="icon"
                                showButtonBar
                                value={new Date(selectedParcel?.taxDeedIssued ?? "")}
                                showIcon
                                onSelect={(e) => {
                                    setSelectedParcel({ ...selectedParcel, taxDeedIssued: e.value } as IParcel);
                                    props.updateParcel({ ...selectedParcel, taxDeedIssued: e.value } as IParcel);
                                    notify();
                                }}
                                onClearButtonClick={(e) => {
                                    setSelectedParcel({ ...selectedParcel, taxDeedIssued: undefined } as IParcel);
                                    props.updateParcel({ ...selectedParcel, taxDeedIssued: undefined } as IParcel);
                                    notify();
                                }}
                            />
                            <br />
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )

}